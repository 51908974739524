@import "~main.scss";

.PageUnavailable {
    padding: 180px 0 180px 150px;
    flex-grow: 1;
    background-color: $backgroundColor;

    .text-container {
        margin-left: 50px;
        margin-top: 17px;

        h1 {
            font-size: 65px;
        }

        p {
            color: $black;
            font-size: 20px;
            font-weight: 300;
            line-height: 30px;
            margin-top: 26px;
        }
    }
}

@media screen and (max-width: $medium)  {
    .PageUnavailable {
        padding: 30px;

        &.horizontal {
            flex-direction: column;
        }

        img {
            height: 70px;
            width: 70px;
        }

        .text-container {
            margin-left: 0;
            margin-top: 21px;

            h1 {
                font-size: 36px;
            }

            p {
                margin-top: 18px;
            }
        }
    }
}
