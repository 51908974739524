@import "~main.scss";

.GalHeader {
  padding: 75px 150px;
  .withMargin {
    margin-bottom: 15px;
  }
  .title-with-buttons {
    .title-with-date {
      .title {
        font-size: 44px;
        color: $blueText;
        margin-bottom: 5px;
      }
      .effective-date {
        font-size: 16px;
        font-weight: 500;
      }
    }
    .buttons {
      .Button {
        height: 45px;
        padding: 0;
        margin: 0 5px;
        width: 145px;
      }
    }
  }
  .profile-btns-block {
      .Button {
        padding: 16px 30px;
        margin: 0px 0px 20px 0px;
      }
  }
  .relocation-info {
    margin-top: 35px;
    min-height: 140px;
    > div {
      max-width: 30%;
      height: auto;
    }
    .countries {
      .flag {
        img {
          height: 50px;
          width: 50px;
          border-radius: 100%;
        }
      }
      .country-info {
        display: flex;
        align-items: center;
        margin-left: 15px;
      }
    }
  }
}

@media screen and (max-width: $breakPoint-lg) {
  .GalHeader {
    padding: 25px;
    .title-with-buttons {
      flex-direction: column;
      .title-with-date {
        .title {
          font-size: 30px;
        }
      }
      .buttons {
        max-width: 500px;
        margin-top: 20px;
        .Button {
          min-width: 150px;
          width: 48%;
          max-width: 250px;
          margin: 0;
        }
      }
    }
    .relocation-info {
      > div {
        max-width: 100%;
      }
      flex-direction: column;
    }
  }
}

@media screen and (max-width: $breakPoint-md){
    .GalHeader {
        .relocation-info {
            flex-direction: column !important;
            .TextWithLabel {
                .text {
                    font-size: $mediumSize;
                }
            }
        }
    }
}
