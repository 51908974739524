.package-selection-container {
    .fifty-percent {
        .CardSection {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            height: 230px;
            margin-top: 50px;
        }
    }
}

@media screen and (max-width: 768px) {
    .package-selection-container {
        .fifty-percent {
            .section-title {
                margin: 20px 0 30px 0;
            }

            .CardSection {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                height: 230px;
                margin-top: 20px;
                margin-bottom: 50px;
                .optionSelectionCard {
                    margin-top: 20px;
                }
            }
        }
    }
}

_:-ms-fullscreen,
:root .optionSelectionCard .icon-block img {
    width: 35px;
}
