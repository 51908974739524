@import "~main.scss";

#popup_root {
  .popup-content {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 100;
    top: 0;
    left: 0;
    background-color: $popupBackground;
    display: flex;
    justify-content: center;
    align-items: center;
    .Popup {
      background-color: $mainBackgroundColor;
      .popup-header {
        margin-bottom: 30px;
        .title {
          font-size: $bigTitleSize;
          color: $blueText;
        }
        .Icon {
          cursor: pointer;
        }
      }
    }
  }
}