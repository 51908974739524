@import "~main.scss";

.Button {
  padding: .7rem 1.5rem;
  background-color: $blueText;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: 400;
  border: none;
  &.disabled {
    background-color: $disabledColor !important;
    pointer-events: none;
    opacity: 0.7;
    > span {
      color: $white;
    }
  }
  &.simple {
    border: 1px solid $buttonsColor;
    border-radius: 0;
    background-color: $white;
    > span {
      color: $buttonsColor;
    }
  }
  &.submit {
    background-color: $buttonsColor;
  }
  > span {
    color: $white;
    font-size: $smallSize;
  }
}
