@import "~main.scss";

.quotes-image {
    display: flex;
    background-color: $blueText;
    padding: 82px 118px;

    &__content {
        margin-left: 60px;
        padding-top: 20px;

    &-title {
        color: $white;
        margin-top: 18px;
        font-size: 20px;
    }

    &-description {
        color: $white;
        margin-top: 30px;
    }
  }
}
