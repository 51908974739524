@import '~main.scss';

.top-container-contacts {
    width: 100%;
    padding-right: 80px;
    @media screen and (max-width: $breakPoint-md) {
        padding: 0 80px;
    }

    .contacts-container {
        flex-direction: row;
        @media screen and (max-width: $breakPoint-sm) {
            flex-direction: column;
        }
    }

    @media screen and (max-width: $breakPoint-sm) {
        padding: 0 30px;

        .contact {
            margin-bottom: 20px;
        }
    }
}
