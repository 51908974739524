@import "~main.scss";

.job-policy-container {
    .job-policy {
        .text-section {
            .title {
                font-weight: 700;
                font-size: 17px;
                margin: 40px 0 40px 0;

                span {
                    color: $subtitleColor;
                }
            }
            .horizontal-section {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                width: 100%;
                margin: 0 0 40px 0;

                .grey-text {
                    font-weight: 100;
                    font-size: 13px;
                    color: rgb(156, 156, 156);
                    margin: 0 0 10px 0;
                }
            }
            .info-section {
                margin: 0 0 40px 0;

                .title {
                    font-weight: 700;
                    font-size: 28px;
                    margin: 0 0 10px 0;
                }
                .communter-assignment-text {
                    margin: 0 0 30px 0;
                    font-weight: 100;
                    line-height: 30px;
                }
            }
        }

        .download-policy-section {
            .title {
                font-weight: 700;
                font-size: 28px;
                margin: 40px 0 40px 0;
            }
            .horizontal-section {
                display: flex;

                &.download {
                    cursor: pointer;
                }

                p {
                    font-size: 15px;
                    margin: 0 0 0 15px;
                    color: blue;
                }
            }
            .line {
                display: block;
                box-sizing: border-box;
                height: 2px;
                width: 100%;
                border: 1px solid #e3e3e3;
                margin: 55px 0 0 0;
            }
            .buttons {
                display: flex;
                justify-content: space-between;

                .white-button {
                    .buttonLabel {
                        height: 20px;
                        width: 144px;
                        color: rgb(197, 51, 51);
                        font-size: 12px;
                        font-weight: bold;
                        line-height: 20px;
                        text-align: center;
                    }
                }

                .red-button {
                    .buttonLabel {
                        height: 20px;
                        width: 144px;
                        color: #ffffff;
                        font-size: 12px;
                        font-weight: bold;
                        line-height: 20px;
                        text-align: center;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1024px) {
    .job-policy-container {
        .job-policy {
            .text-section {
                .title {
                    font-size: 28px;
                }
                .horizontal-section {
                    flex-direction: row;
                }
            }
            .download-policy-section {
                .horizontal-section {
                    width: 240px;
                }
                .buttons {
                    .red-button {
                        height: 43.55px;
                        width: 202px;
                        .buttonLabel {
                            font-size: 16px;
                        }
                    }
                    .white-button {
                        height: 43.55px;
                        width: 202px;
                        .buttonLabel {
                            display: flex;
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
}
