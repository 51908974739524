.DatePicker {
    margin-top: 20px;
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    flex-direction: column;
}
