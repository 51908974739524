@import "~main.scss";

.YourMoves {
    padding: 71px 150px 124px;
    flex-grow: 1;

    > h1 {
        color: $blueText;
        font-size: $pageTitleSize;
        margin-bottom: 25px;
    }

    > p {
        font-size: $subTitleSize;
        line-height: 30px;
        font-weight: 300;
    }

    .countries-block {
        margin-top: 27px;
        .Country {
            height: 102px;
            border-bottom: 1px solid $disabledColor;
            cursor: pointer;

            .image {
                img {
                    min-width: 40px;
                    height: 40px;
                    max-width: 40px;
                    border-radius: 100%;
                    background-size: cover;
                    background-position: 0 50%;
                    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
                }

                h4 {
                    font-weight: 900;
                    font-size: 30px;
                    margin-left: 19px;
                    color: $black;
                }
            }

            .text {
                > div {
                    &:not(:empty) {
                        min-width: 100px;
                    }

                    &.text-end-date:not(:empty) {
                        margin-left: 88px;
                    }

                    > span {
                        font-size: $smallSize;
                        color: $darkGrey;
                        font-weight: 300;

                        > h3 {
                            font-size: $mediumSize;
                            color: #000;
                            font-weight: 300;
                            line-height: 20px;
                        }
                        > b {
                            font-size: $mediumSize;
                        }
                    }

                    .Completed {
                        font-size: $smallSize;
                        color: $placeHolderColor;
                    }
                }

                > span {
                    color: $buttonsColor;
                    margin-left: 170px;
                }

                .arrow-left {
                    > span {
                        transform: rotate(-90deg);
                    }
                }
            }
        }
    }
}

@media screen and (max-width: $breakPoint-lg) {
    .YourMoves {
        padding: 72px 40px;

        .countries-block {
            .Country {
                .text {
                    > div {
                        &.text-end-date:not(:empty) {
                            margin-left: 0px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: $breakPoint-md) {
    .YourMoves {
        .header-text {
            font-size: 36px;
        }

        .countries-block {
            .Country {
                height: 100px;
                position: relative;

                h4 {
                    font-size: 24px;
                }

                .Icon {
                    transform: rotate(-90deg);
                    position: absolute;
                    right: 0;
                    top: 40px;
                }

                .text {
                    flex-direction: column;
                    padding-right: 20%;

                    > div {
                        span {
                            font-size: 12px;
                        }
                        &.text-end-date {
                            margin-top: 10px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: $breakPoint-sm) {
    .YourMoves {
        padding: 42px 20px;

        .header-text {
            font-size: 30px;
        }

        .countries-block {
            .Country {
                h4 {
                    font-size: 22px !important;
                    margin-left: 10px !important;
                    margin-top: -20px;
                }

                .text {
                    flex-direction: row !important;
                    padding-right: 0 !important;
                    position: absolute;
                    width: 100%;
                    box-sizing: border-box;
                    padding-left: 50px;
                    top: 56%;

                    .Icon {
                        top: -10px;
                    }

                    > div {
                        &.text-end-date {
                            margin-top: 0;
                        }
                        &:first-child {
                            display: flex;
                            align-items: center;

                            &:after {
                                content: '-';
                                transform: translateX(9px);
                            }
                        }

                        span {
                            font-size: 0 !important;

                            h3 {
                                font-size: 14px;
                                font-weight: 300;
                            }
                        }
                    }
                }
            }
        }
    }
}
