@import '~main.scss';

.TaxMilestonesTable {
    margin-top: 50px;

    @media screen and (max-width: $breakPoint-sm) {
        margin-top: 30px;
    }

    .stepper-dot {
        width: 9px;
        height: 9px;
        display: block;
        border-radius: 50%;
        background-color: $white;
        border: 4px solid $disabledColor;
        z-index: 5;

        &.dot-active {
            background-color: $blueText;
            border: 2px solid $blueText;
            height: 15px;
            width: 15px;
        }

        &.dot-complete {
            border: 2px solid $disabledColor;
            background-color: $disabledColor;
            height: 15px;
            width: 15px;
        }
    }
}

.TaxMilestonesTableFilters {
    @media screen and (max-width: $breakPoint-sm) {
        flex-direction: column;
        align-items: flex-start;
        @include prefixer(flex-direction, column);
        @include prefixer(flex-align, flex-start);
    }

    .years {
        @media screen and (max-width: $breakPoint-sm) {
            display: none;
        }
    }

    .years-mobile {
        display: none;
        width: 100%;
        margin-top: 40px;

        .years-select {
            width: 100%;

            &:before {
                border-bottom: 1px solid $disabledColor;
            }
        }

        .years-select-text {
            font-size: 16px;
            color: $blueText;
        }

        .years-select-icon {
            right: 5px;

            path {
                fill: $buttonsColor;
            }
        }

        @media screen and (max-width: $breakPoint-sm) {
            display: block;
        }
    }

    .year {
        margin-right: 50px;
        color: $black;
        font-size: 16px;
        font-weight: 900;
        line-height: 20px;
        cursor: pointer;

        &.selected {
            color: $blueText;
            border-bottom: 1px solid $blueText;
            padding-bottom: 5px;
        }
    }

    .button {
        .Button {
            padding: 16px 30px;
        }

        @media screen and (max-width: $breakPoint-sm) {
            width: 100%;

            .Button {
                width: calc(100% - 60px);
            }
        }
    }
}

.TaxMilestonesTableHeader {
    background-color: $mainBackgroundColor;
    margin-top: 33px;
    margin-bottom: 31.5px;
    padding: 25px 0 23px 21px;

    > div {
        color: $black;
        font-size: 14px;
        font-weight: bold;
        line-height: 22px;

        &:first-child {
            width: 26.4%;
        }

        &:nth-child(2) {
            width: 42%;
        }

        &:nth-child(3) {
            width: 15%;
        }

        &:nth-child(4) {
            width: 16.6%;
        }
    }

    @media screen and (max-width: $breakPoint-sm) {
        padding: 23px 27px 24px 28px;
        justify-content: space-between;
        margin-bottom: 0;
        @include prefixer(flex-pack, space-between);


        > div {
            &:first-child {
                width: auto;
            }

            &:nth-child(2) {
                display: none;
            }

            &:nth-child(3) {
                display: none;
            }

            &:nth-child(4) {
                width: auto;
            }
        }
    }
}

.TaxInfoType {
    color: $placeHolderColor;
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    text-transform: capitalize;
}

.TaxInfoCountry {
    color: $black;
    font-size: 24px;
    font-weight: 900;
    letter-spacing: -0.6px;
    line-height: 42px;
}

.TaxMilestonesMobileItems {
    padding-bottom: 20px;
    padding-top: 20px;

    .activity-title {
        color: $blueText;
        font-size: 16px;
        font-weight: bold;
        line-height: 26px;
    }
}

.TaxMilestonesTableItems {
    .TaxCountryInfo {
        width: 26.4%;
    }

    .TaxActivityInfo {
        width: 73.6%;
    }
}

.TaxMilestonesTableSeparator {
    height: 2px;
    background-color: $disabledColor;
    border: none;
    margin: 28px 0;
}

.TaxMilestonesTableStepper {
    position: relative;

    .line {
        position: absolute;
        top: 11px;
        bottom: 0;
        width: 2px;
        left: 8px;
        background-color: $disabledColor;
    }
}

.TaxMilestonesTableRow {
    padding-bottom: 12px;
    margin-left: 20px;
    width: 100%;
    box-sizing: border-box;

    &.has-border {
        border-bottom: 1px solid $borderColor;
    }

    &.has-margin {
        margin-top: 14px;
    }

    .activity-title {
        width: 48%;
        color: $black;
        font-size: 16px;
        font-weight: 300;
        line-height: 26px;
    }

    &.active {
        .activity-title {
            font-weight: bold;
            color: $blueText;
        }
    }
}

.TaxMilestonesTableAccordion {
    .item-header {
        .ListItem {
            padding: 0 27px;
        }
    }

    .item-content.opened {
        padding: 35px 27px !important;

        .TaxMilestonesTableRow {
            flex-wrap: wrap;
        }

        .activity-title {
            width: 100%;
        }
    }
}
