@import "~main.scss";

.int-explore-step {
  .TextWithImage {
    padding-bottom: 25px;
  }

  .side_info_list {
      .question-part__list-container {
          border: none;
      }
  }

  .question-part {
    padding: 0 80px;

    h2 {
      margin-bottom: 0;
    }

    &__list {
      &-container {
        border-left: 1px solid $disabledColor;
        padding-left: 20px;
        strong {
          font-size: $smallSize;
        }
      }
    }
  }
  p.title-text-color{
    color:#ffffff;
    padding-left:40px;
    //padding-bottom: 20px;
  }
  .accordion_item_main_block{
    width: 140% !important;
  }
  .basics {
    .QuestionListCard {
      width: 92%;
    }
    .side_info_list{
        // we need this min-width cause when estimate time text is short this block change his width
        min-width: 290px;
      .QuestionListCard{
        width: 100%;
        li{
          font-size: $smallSize;
          line-height: 30px;
        }
      }
      .question-parts__list{
        margin-top: 10px !important;
      }
    }
  }
  .common-questions{
    width: 96%

  }
  .estimate {
    hr {
      margin: 20px 0;
      width: 40px;
      height: 2px;
      background-color: $borderColor;
      border: none;
    }

    .time-label {
      margin-left: 8px;
      font-weight: bold;
      font-size: $smallSize;
    }

    .time-desc {
      font-size: $smallSize;
    }
  }

  .question-parts {
    &__list {
      margin: 0 !important;
      display: block !important;

      li::before {
        display: initial !important;
      }
    }

    &__link {
      color: $blueText;
    }
  }

  .question-block {
    padding: 0 !important;
  }

  .text-block {
    div {
      font-size: 20px !important;
    }

    .facts {
      margin-top: 35px !important;
      margin-bottom: 20px !important;
      flex-direction: row;
      justify-content: space-between;
    }

    .fact {
      padding: 0 0 8px !important;
    }
  }

  .accordion-header {
    margin: 0;
    padding: 0;
  }

  .Accordion {
    .title {
      font-size: 20px;

      .index {
        min-width: 45px;
        height: 45px;
        border: 2px solid $subtitleColor;
        border-radius: 100%;
        margin-right: 25px;
        font-size: 22px;
        font-weight: 900;
        color: $blueText
      }

      p {
        color: $blueText;
      }
    }

    .item-header {
      .ListItem {
        padding: 30px 0 !important;
      }
    }

    .item-content {
      padding-bottom: 0 !important;
    }

    .content {
      &.flex {
        display: flex;

        > div:first-child {
          width: 150%;
          margin-right: 100px;
        }

        .QuestionListCard {
          margin-top: 0 !important;
        }

        .footer {
          margin-bottom: 40px;
        }
      }

      h1 {
        font-size: 20px;
        font-weight: 100;
        margin-bottom: 30px;
      }

      h2 {
        font-size: $mediumSize;
      }

      h4 {
        margin: 0;
      }

      ul {
        margin: 20px 0 25px 0;
      }

      li {
        font-size: $mediumSize;
      }

      .icon-block {
        display: none;
      }

      .text-block {
        margin: 0 !important;
        padding: 0 !important;
      }

      .separator {
        width: 40px;
        height: 2px;
        background-color: $grey;
        margin-bottom: 30px;
      }

      .footer {
        margin-top: -30px;

        &.margin {
          margin-top: 20px;
        }

        p:first-child {
          margin-bottom: 20px;
          color:#ffffff;
        }
      }

      .question-list-card {
        .QuestionListCard {
          margin-bottom: 0 !important;
          margin-top: 0 !important;
        }
      }
    }
  }

  .questions-accordion {
    display: flex;

    > div:first-child {
      margin-right: 30px;
    }

    .QuestionListCard {
      margin: 0;
    }

    .ListItem {
      border-bottom: 0;
    }

    .item-header {
      border: 0;
    }

    .item-content {
      border-bottom: 1px solid $silver;
      padding-top: 0 !important;
      margin-left: 65px;
    }

    .title {
      p {
        font-size: $mediumSize;
        font-weight: bold;
        margin-left: 18px;
        color: $textColor;
        white-space: nowrap;
      }
    }

    .icon-block {
      display: none;

      p {
        font-size: $mediumSize;
      }
    }

    .text-block {
      margin: 0 !important;
      padding: 0 !important;

      h4 {
        margin-bottom: 45px;
      }

      .text-separator {
        margin: 15px 0;
        display: block;
      }
    }
  }

  .illustration-bg {
    background: url('../../../../../components/common/Images/illustration-99.svg');
    background-color: $blueText;
    background-repeat: no-repeat;
    background-position-x: 50px;
    background-position-y: 35px;

    .mid-section-body {
      margin-left: auto;
    }

    .flexible {
      width: 570px;
      p {
        font-size: $subTitleSize;
      }
    }
  }
}

@media screen and (max-width: $breakPoint-lg) {
  .MidSectionComponent,
  .hr-IntInitMoveStep .ColumnComponent {
    display: none;
  }

  .Profil {
    .int-explore-step .questions-accordion {
      flex-direction: column;
    }

    .int-explore-step .questions-accordion .text-block h4 {
      flex-direction: column;
      span {
        margin-bottom: 20px;
      }
    }

    .int-explore-step .question-part {
      padding: 0 30px;
    }

    .question-card > .text-block  div {
      flex-direction: column;
      padding: 0 !important;
    }

    .question-part .question-block .question-card > .icon-block {
      display: none;
    }

    .QuestionListCard .question-card .text-block h4 {
      flex-direction: column;
      .Icon {
        margin: 0 0 0 14px;
      }
      & > span {
        margin-bottom: 20px;
        border: none;
      }
    }

    .question-part.basics {
      margin: 0;
      padding: 0 30px;
    }

    .accordion-header.question-part {
      padding: 0;
    }

    .question-part .question-block > .flexible:first-child {
      margin: 0;
    }

    .int-explore-step .questions-accordion > div:first-child {
      margin: 0;
    }

    .int-explore-step .question-part h2 {
      margin-bottom: 18px;
      line-height: initial;
    }

    .int-explore-step .questions-accordion .title p {
      white-space: pre-wrap;
    }

    .int-explore-step .Accordion .content.flex {
      flex-direction: column;
    }

    .int-explore-step .question-part__list-container {
      border: none;
      padding: 0;
    }

    .QuestionListCard {
      margin-bottom: 0;
    }

    .int-explore-step .Accordion .content.flex > div:first-child {
      width: 100%;
      margin: 0;
    }

    .int-explore-step .Accordion .content .separator {
      width: 100%;
    }

    .question-part {
      padding: 0 30px;
      margin: 0;
    }

    .Link .Icon {
      font-size: 14px;
    }

    .hr-IntInitMoveStep .dom-expl-first-step .Accordion > .accordion-item .item-content .flexible.pad-left {
      padding: 0;
    }

    .hr-IntInitMoveStep .dom-expl-first-step .Accordion > .accordion-item .item-content .estimate hr {
      display: none;
    }

    .hr-IntInitMoveStep .dom-expl-first-step .Accordion > .accordion-item .item-content .estimate .time-desc,
    .hr-IntInitMoveStep .dom-expl-first-step .Accordion > .accordion-item .item-content .estimate {
      margin-top: 10px;
    }

    .expl_hr_acc_item_buttons {
      flex-direction: column;
      padding: 0 20px;
    }

    .hr-journey-back .manager-tasks-content .questions-tasks-block {
      width: 100%;
      box-sizing: border-box;
    }

    .hr-DomExploreStep .dom-expl-question-part .question-block {
      width: 100%;
    }

    .QuestionListCard .question-card .text-block h3 {
      padding-left: 0;
    }

    .hr-DomExploreStep .dom-expl-question-part {
      margin-top: 20px;
    }

    .hr-DomExploreStep .dom-expl-first-step .Accordion > .accordion-item .item-content.opened {
      padding: 20px 0;
    }

    .hr-DomExploreStep .dom-expl-first-step .Accordion > .accordion-item .item-content .expl_hr_acc_item_buttons {
      padding: 0;
    }

    .hr-DomExploreStep .dom-expl-first-step .Accordion > .accordion-item .item-content div.flexible.pad-left {
      width: 100%;
      padding: 0;
    }

    .hr-DomExploreStep .dom-expl-first-step .Accordion > .accordion-item .item-content .estimate hr {
      width: 100%;
    }

    .move-info__description {
      margin: 0;
      padding: 0 20px;
    }

    .question-card > .text-block > div {
      box-sizing: border-box;
    }

    .question-card > .text-block div {
      width: 100%;
    }

    .question-parts__information {
      padding: 0 !important;
    }

    .question-parts .question-part__list-container {
      margin: 0 !important;
    }

    .QuestionListCard .question-card .text-block {
      width: 100%;
    }

    .move-info__accordion {
      width: 100%;
      padding: 0 20px;
      flex-direction: column;
      box-sizing: border-box;
    }

    .move-info__accordion .Accordion {
      width: 100%;
    }

    .flexible.jCenter {
      box-sizing: border-box;
    }
  }
}
