.talentMobiltyOWMContainer{
  .section-title{
    width: 100%;	
    color: #212121;	
    font-family: "J&J Circular";	
    font-size: 26px;	
    font-weight: 900;	
    letter-spacing: -0.36px;	
    line-height: 42px;
  }
  .label {	
    height: 42px;	
    width: 412px;	
    color: #212121;	
    font-size: 26px;	
    font-weight: 900;	
    letter-spacing: -0.36px;	
    line-height: 42px;
  }
  .sub-header{
    width: 100%;	
    color: #212121;	
    font-family: "J&J Circular";	
    font-size: 20px;	
    font-weight: 300;	
    line-height: 36px;

    a {
      color: #000099;
    }
  }
  .bulletPointsSection{
    margin: 50px 0 40px 0;

    .textFont {
      height: 40px;	
      width: 100%;	
      color: #212121;	
      font-family: "J&J Circular";	
      font-size: 16px;	
      font-weight: 300;	
      line-height: 26px;
      margin: 0;

      &:before {
          content: "•";
          margin-right: 10px;
          color: red;
      }
    }
  }
  .textFont{
    width: 100%;	
    color: #212121;	
    font-family: "J&J Circular";	
    font-size: 20px;	
    font-weight: 300;	
    line-height: 30px;
    margin: 20px 0 0 0;
  }

  .button-section{
    display: flex;
    flex-direction: column;
    align-items: center;

    .line {
      display: block;
      box-sizing: border-box;
      height: 2px;
      width: 100%;
      border: 1px solid #E3E3E3;
      margin: 55px 0 0 0;
    }

    .buttons{
      display: flex;
      justify-content: center;
      width: 100%;

      .red-button{
        height: 44px;
        width: 80%;
        background-color: #CA001B;
        .buttonLabel{
          font-size: 16px;
          line-height: 20px;
          color: #FFFFFF;
        }
      }
    }
  }
}
@media screen and (min-width: 1024px) {
  .talentMobiltyOWMContainer{
    .section-title{
      height: 48px;	
      width: 978px;	
      color: #000099;	
      font-family: "J&J Circular";	
      font-size: 44px;	
      font-weight: 900;	
      letter-spacing: -0.5px;	
      line-height: 48px;
    }
    .sub-header{
      height: 53px;	
      width: 970px;	
      color: #212121;	
      font-family: "J&J Circular";	
      font-size: 20px;	
      font-weight: 300;	
      line-height: 30px;
  
      a {
        color: #000099;
      }
    }
    .bulletPointsSection{
      margin: 15px 0 40px 0;
      
      .textFont {
        height: 40px;	
        width: 819px;	
        color: #212121;	
        font-family: "J&J Circular";	
        font-size: 16px;	
        font-weight: 300;	
        line-height: 26px;
        &:before {
            content: "•";
            margin-right: 10px;
            color: red;
        }
      }
    }
    .textFont{
      height: 80px;	
      width: 819px;	
      color: #212121;	
      font-family: "J&J Circular";	
      font-size: 16px;	
      font-weight: 300;	
      line-height: 26px;
    }
  
    .button-section{
      display: flex;
      flex-direction: column;
      align-items: center;
  
      .line {
        display: block;
        box-sizing: border-box;
        height: 2px;
        width: 100%;
        border: 1px solid #E3E3E3;
        margin: 55px 0 0 0;
      }
  
      .buttons{
        display: flex;
        justify-content: flex-end;
        width: 100%;

  
        .red-button{
          height: 44px;
          width: 175px;
          background-color: #CA001B;
          cursor: pointer;
          
          .buttonLabel{
            font-size: 16px;
            line-height: 20px;
            color: #FFFFFF;
          }
        }
      }
    }
  }
}