@import "~main.scss";

.CTAComponent {
  height: 335px;
  width: 271px;
  background-color: $white;
  cursor: pointer;
  .cta-block {
    max-height: 100%;
  }
  .image-block {
    width: 100%;
    max-height: 170px;
    >img{
      width: 100%;
      height: auto;
    }
  }
  h3 {
    color: $black;
    font-weight: 900;
    font-size: $mediumSize;
    margin: 19px 29px;
  }
  hr {
    box-sizing: border-box;
    height: 2px;
    width: 25px;
    border: 1px solid #D8D8D8;
    margin-bottom: 11px;
  }
  .read-more {
    padding: 0 29px;
  }
  .aside {
    .text {
      span {
        font-size: $smallSize;
      }
    }
  }
}