@import "~main.scss";

.Tasks {
  flex-grow: 1;
  padding: 2rem;
  .TaskCard {
    max-width: 576px;
    box-sizing: border-box;
  }
  .tasks-title {
    color: $blueText;
    font-size: 44px;
    margin-left: 2rem;
    margin-top: 3.5rem;
  }
  > .flexible {
    flex-flow: row wrap;
    iframe {
      margin-left: 2rem;
    }
  }
  .titles {
    margin-left: 2rem;
    margin-bottom: 2rem;
    h4 {
      font-size: $subTitleSize;
      color: $subtitleColor;
    }
    h3 {
      color: $blueText;
      font-size: 44px;
    }
  }

  .bank-info-task {
    padding: 10px 30px;

    .description-bi {
      font-size: $subTitleSize;
      margin-bottom: 50px;
      line-height: 30px;
    }
  }
}