@import '~main.scss';

.cards-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
    padding-right: 80px;

    .card-wrapper {
        width: 265px;
        height: 205px;
    }

    .move-card {
        background: white;
        padding: 0 40px;
        display: flex;
        height: 100%;
        justify-content: center;
    }

    .card-secondary-text {
        font-size: $mediumSize;
        color: $blueText;
        font-weight: 500;
        line-height: 26px;
    }

    .card-primary-text {
        font-size: $subTitleSize;
        color: $blueText;
        font-weight: 900;
        line-height: 26px;
    }
}

@media screen and (max-width: $breakPoint-md) {
    .cards-container {
        padding: 0 80px;
    }
}

@media screen and (max-width: $breakPoint-sm) {
    .cards-container {
        padding: 0 30px;
        flex-direction: column;

        .card-wrapper {
            width: 100%;
            height: auto;
        }

        .move-card {
            padding: 25px;
            margin-bottom: 20px;
        }
    }
}
