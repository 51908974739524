.pre-initiate-move-container {
    .people-leader-container {
        .one-way-move-or-assignment {
            border: 1px solid #D8D8D8;
            &:hover {
                border-color: #D8D8D8;
            }
            h2 {
                margin: 25px 0 10px 0;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .pre-initiate-move-container {
        .people-leader-container {
            .one-way-move-or-assignment {
                border: 1px solid #D8D8D8;
                &:hover {
                    border-color: #D8D8D8;
                }
            }
        }
    }
}
