@import "~main.scss";

.ie {
  #root {
    .GlobalAssignmentLetter {
      .relocation-info {
        .countries {
          .TextWithLabel {
            max-width: 100%;
            width: 100%;
          }
        }
      }
      .AllowancesAndDeductions {
        .left_block {
          max-width: 100%;
          width: 100%;
        }
      }
      .allowance-category {
        .Accordion {
          .accordion-item {
            .allowance-name {
              z-index: 7;
            }
            .allowance-value {
              z-index: 7;
            }
          }
        }
      }
    }
  }
}


@media screen and (max-width: $breakPoint-sm) {
  .GlobalAssignmentLetter {
    .title-with-buttons {
      flex-direction: column;
    }
  }
}
