@import "~main.scss";

.which-options-container {
    .options-container{
        flex-wrap: wrap;
        display: flex;

        .option {
            width: 23%;
            border: 1px solid $placeHolderColor;
            box-sizing: border-box;
            height: 150px;
            cursor: pointer;
            padding: 0 27px;
            margin: 22px 22px 0 0;
            height: 245px;
            width: 303px;

            &:hover {
                border: 1px solid $blueText;

                p {
                    color: $blueText;
                }
            }

            @media screen and (max-width: $large) {
                width: 48%;
                margin-bottom: 20px;
            }

            @media screen and (max-width: $breakPoint-sm) {
                width: 100%;
            }

            h5 {
                color: $blueText;
                font-size: 24px;
                font-weight: 900;
                line-height: 32px;
            }


            p {
                
                height: 48px;
                width: 242px;	
                color: #212121;
                font-size: 14px;	
                font-weight: 300;	
                line-height: 24px;
                margin-top:20px;
            }
        }

    }
}
