@import '~main.scss';

.browser-support {
    .Spinner {
        width: 60px;
        height: 60px;
        margin: 0;
        padding: 0;
    }

    &-info-text {
        font-size: $subTitleSize;
        color: $black;
        line-height: 1.5;
        font-weight: 300;
    }
    &-browsers-list {
        padding: 45px 0;
        justify-content: space-between;
    }
    &-title {
        margin-bottom: 15px;
        font-weight: 900;
    }
    &-browser-section {
        max-width: 90px;
        width: 90px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 10px;

        & > img {
            width: 55px;
        }
    }
    &-browser-name {
        margin-top: 10px;
        font-weight: 300;
    }
    &-supported {
        max-width: 35%;
    }
    &-unsupported {
        max-width: 55%;
    }
    &-button {
        padding: 15px 35px;

        > span {
            font-size: $mediumSize;
        }
    }
}

@media screen and (max-width: $breakPoint-md) {
    .browser-support {
        padding: 25px!important;

        &-browsers-list {
            flex-direction: column;
        }
        &-browser-name {
            font-size: $smallSize;
        }

        &-supported {
            max-width: 100%;
            margin-bottom: 30px;
        }
        &-unsupported {
            max-width: 100%;
        }
    }
}

@media screen and (max-width: $breakPoint-sm) {
    .browser-support {
        padding: 15px!important;

        .popup-header .title {
            font-size: $mediumTitleSize !important;
        }

        &-info-text {
            font-size: $largeSize;
        }

        &-browser-section {
            margin-right: 0;
        }
    }
}
