@import "~main.scss";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.ColumnComponent{

    padding: 80px;
    margin-bottom: 5rem;
    .column-component-header{
        h2 {
            font-size: 30px;
            color: $blueText;
            font-weight: 900;
            letter-spacing: -0.5px;
            line-height: 30px;
            span {
                height: 2px;
                width: 50px;
                background-color: $blueText;
                margin-top: 26px;
            }
        }
    }

    .column-component-body {
        padding-top: 50px;
        display: flex;
        table-layout: fixed;
        width: 100%;
        justify-content: space-around;

        .slick-arrow {
            button {
                background-color: $white;
                svg {
                    path {
                        stroke: $buttonsColor;
                    }
                }
            }
        }

        h3{
            padding-top: 20px;
            padding-bottom: 10px;
        }

        .time-label{
            color: $buttonsColor;
            font-size: 16px;
            font-weight: bold;
            line-height: 20px;
            padding-left: 10px;
            white-space: nowrap;

        }

        .time-desc{
            color: $black;
            font-size: 14px;
            font-weight: 900;
            line-height: 22px;
        }

        p, span, ul, li {
            font-size: 16px;
            font-weight: 100;
            line-height: 26px;
            margin-bottom: 1rem;
        }

        .card {

            width: 25%;
            padding: 30px;
            border: 1px solid $borderColor;
            position: relative;
            height: 370px;
            box-sizing: border-box;
            max-width: 306px;
            a {
                color: $blueText;
              }
        }

        .estimate{
            bottom: 0;
            position: absolute;
            padding-bottom: 40px;
            padding-right: 10px;

            hr {
                width: 30px;
                margin-bottom: 30px;
                border: 0.5px solid $disabledColor;
            }
        }

        span {
            margin-top: 2rem;
            width: 200px;
        }
        .divider{
            box-sizing: border-box;
            border: 1px solid #FFFFFF;
            opacity: 0.5;
        }

        .pad-left{
            padding-left: 40px;
        }

        .pad-right{
            padding-right: 40px;
        }
    }

}


.ie {
    .slick-arrow {
        button {
            position: relative;
            > span {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate3d(-50%, -50%, 0);
            }
        }
    }
}
