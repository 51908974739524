@import "~main.scss";

.contact-mobility-container-layout-three {
    display: flex;
    flex-direction: column;
    //margin: 50px 0 0 0;

    .label {
        height: 42px;
        width: 412px;
        color: #212121;
        font-size: 26px;
        font-weight: 900;
        letter-spacing: -0.36px;
        line-height: 42px;
    }
    .main-text {	
        height: 39px;	
        width: 731.44px;	
        color: #212121;	
        font-size: 16px;	
        font-weight: 300;	
        line-height: 54px;
        margin-bottom:25px;
    }
    .img-information{
        position: absolute;
        left: 79%;
    }
    .details-section{
        display: flex;

        .horizontal{
            display: flex;
            flex-direction: column;
            width: 70%;
            //PHONE
            .contact-by-phone{
                position: relative;
                margin: 0 0 60px 0;

                img {
                    position: absolute;
                    left: 0;
                    top: 54px;
                }
                .boxes {
                    .box-title {
                        height: 20px;	
                        width: 143px;	
                        color: #CC0099;	
                        font-family: "J&J Circular";	
                        font-size: 16px;	
                        font-weight: bold;	
                        line-height: 20px;
                    }
                    .text{
                        height: 20px;	
                        width: 184px;	
                        color: #212121;	
                        font-family: "J&J Circular";	
                        margin: 0 0 0 0;
                        font-size: 14px;	
                        font-style: italic;	
                        font-weight: 300;	
                        line-height: 20px;
                    }
                    .box {
                        margin: 14px 0 10px 30px;
                        line-height: 22px;
                        p {
                            font-weight: bold;
                            font-style: 20px;
                        }
                        span {
                            font-size: 16px;
                            padding-top: 3px;
                            display: block;
                            font-weight: 100;
                            a {
                                display: inline-block;
                                margin-left: 5px;
                            }
                        }
                    }
                }

            }

            //MAIL
            .mail-information {
                margin-left: 12%;
                .box-title {
                    height: 20px;	
                    width: 143px;	
                    color: #CC0099;	
                    font-family: "J&J Circular";	
                    font-size: 16px;	
                    font-weight: bold;	
                    line-height: 20px;
                    margin: 0 0 10px 0;
                }
                img {
                    margin-right: 15px;
                }
                a {
                    height: 18px;	
                    width: 271px;	
                    color: #000099;	
                    font-family: "J&J Circular";	
                    font-size: 16px;	
                    font-weight: 300;	
                    line-height: 18px;
                }
            }
        }
    }
}
        
@media screen and (min-width: 1024px) {     
    .contact-mobility-container-layout-three {
        display: flex;
        flex-direction: column;
        margin: 50px 0 0 0;

        .label {
            color: #212121;
            font-size: 26px;
            font-weight: 900;
            letter-spacing: -0.36px;
            line-height: 42px;
        }
        .main-text {	
            height: 39px;	
            width: 731.44px;	
            color: #212121;	
            font-size: 16px;	
            font-weight: 300;	
            line-height: 54px;
            margin-bottom:25px;
        }

        .details-section{
            display: flex;

            .horizontal{
                display: flex;
                flex-direction: row;
                width: 70%;    
                //PHONE
                .contact-by-phone{
                    position: relative;
                    img {
                        position: absolute;
                        left: 0;
                        top: 54px;
                    }
                    .boxes {
                        .box-title {
                            height: 20px;	
                            width: 143px;	
                            color: #CC0099;	
                            font-family: "J&J Circular";	
                            font-size: 16px;	
                            font-weight: bold;	
                            line-height: 20px;
                        }
                        .text{
                            height: 20px;	
                            width: 184px;	
                            color: #212121;	
                            font-family: "J&J Circular";	
                            margin: 0 0 0 0;
                            font-size: 14px;	
                            font-style: italic;	
                            font-weight: 300;	
                            line-height: 20px;
                        }
                        .box {
                            margin: 14px 0 10px 30px;
                            line-height: 22px;
                            p {
                                font-weight: bold;
                                font-style: 20px;
                            }
                            span {
                                font-size: 16px;
                                padding-top: 3px;
                                display: block;
                                font-weight: 100;
                                a {
                                    display: inline-block;
                                    margin-left: 5px;
                                }
                            }
                        }
                    }

                }

                //MAIL
                .mail-information {
                    margin-left: 12%;

                    .box-title {
                        height: 20px;	
                        width: 143px;	
                        color: #CC0099;	
                        font-family: "J&J Circular";	
                        font-size: 16px;	
                        font-weight: bold;	
                        line-height: 20px;
                        margin: 0 0 10px 0;
                    }
                    img {
                        margin-right: 15px;
                    }
                    a {
                        height: 18px;	
                        width: 271px;	
                        color: #000099;	
                        font-family: "J&J Circular";	
                        font-size: 16px;	
                        font-weight: 300;	
                        line-height: 18px;
                    }
                }
            }
        }
    }
}