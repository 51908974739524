@import '~main.scss';

.ManageYourHealth {
  padding-top: 57px;
  .text-block {
    padding-bottom: 57px;
    border-bottom: 1px solid $borderColor;
    .text {
      p {
        font-weight: 300;
        font-size: $subTitleSize;
        margin-bottom: 1.5rem;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .image {
      min-width: 300px;
      min-height: 131px;
      background-size: cover;
      background-position: 25% 50%;
      margin-left: 58px;
    }
  }
  .BenefitInfo {
    .Accordion > .accordion-item {
      .item-header {
        .ListItem {
          min-height: 110px;
          &.opened {
            border-color: $blueText;
          }
          .title-with-content .title {
            .index {
              min-width: 45px;
              height: 45px;
              border: 2px solid $subtitleColor;
              border-radius: 100%;
              margin-right: 25px;
              font-size: 22px;
              font-weight: 900;
              color: $blueText
            }
            .title-text {
              font-size: $subTitleSize;
              font-weight: 900;
              color: $blueText;
            }
          }
        }
      }
      .item-content {
        .benefit-info-content {
          padding: 0 70px;

          .description {
            font-size: $subTitleSize;
            font-weight: 300;
            margin-bottom: 25px;
          }
          .list-title {
            font-weight: 300;
            margin-bottom: 25px;
          }
          .benefit-service-center {
            .title {
              font-size: 24px;
              font-weight: 900;
              margin-top: 10px;
            }
            .phone-info{
              flex-wrap: wrap;
              margin: 15px 0;
              .phone-block {
                flex-grow: 1;
                margin: 10px 0;
                .phone-region {
                  font-weight: bold;
                  color: $subtitleColor;
                  margin-bottom: 5px;
                }
                .phone-number {
                  font-size: $subTitleSize;
                  font-weight: 300;
                  margin-bottom: 5px;
                }
                .additional-info {
                  font-weight: 300;
                }
              }
            }
          }
          .list {
            > li {
              display: flex;
              font-weight: 300;
              line-height: 1.8;
              &:not(last-child) {
                margin-bottom: 15px;
              }
              &::before {
                content: "•";
                display: block;
                color: $buttonsColor;
                margin-right: 10px;
              }
            }
          }
          .Link {
            margin-top: 40px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $breakPoint-lg) {
  .ManageYourHealth {
    .BenefitInfo {
      .Accordion > .accordion-item {
        .item-content {
          .benefit-info-content {
            padding: 0 15px;
          }
        }
      }
    }
  }
}
