.pre-initiate-move-container {
    .req-os-screen {
        h1 {
            margin-top: 30px;
            margin-bottom: 45px;
            letter-spacing: -0.5px;
            line-height: 48px;
        }
        .line {
            border: 1px solid #d8d8d8;
        }
        .move-base-url {
            color: #000099;
        }
        .top-content {
            display: flex;
            .left-info {
                width: 65%;
                padding-right: 60px;
                p {
                    color: #212121;
                    font-size: 20px;
                    font-weight: 300;
                    line-height: 30px;
                    margin-bottom: 35px;
                }
                .buttons {
                    display: flex;
                    .submit-button {
                        margin-right: 10px;
                        margin-top: 0;
                        a {
                            color: #fff;
                        }
                    }
                    .continue-button {
                        color: #ca001b;
                        font-family: "J&J Circular";
                        font-size: 14px;
                        background-color: #fff;
                        font-weight: bold;
                        line-height: 15px;
                        border: 1px solid #ca001b;
                        box-shadow: none;
                        text-align: center;
                        cursor: pointer;
                        padding: 13px 15px;
                        height: 52px;
                        @media screen and (max-width: 991px) {
                            width: 100%;
                        }
                    }
                }
            }
            .right-info {
                width: 32%;
                div {
                    span.text {
                        color: #cc0099;
                        margin-left: 10px;
                        font-weight: bold;
                        line-height: 20px;
                    }
                }
                p {
                    color: #888b8d;
                    margin-top: 20px;
                    font-size: 16px;
                    font-weight: 300;
                    line-height: 20px;
                    margin-left: 26px;
                    width: 80%;
                }
            }
        }
        .three-steps-accordion {
            margin-top: 45px;
            .accordion-box {
                border-bottom: 1px solid #e6e6e6;
                .Accordion {
                    .accordion-item {
                        .item-content {
                            &.opened {
                                padding: 30px 0;
                                cursor: default;
                            }
                        }
                        .item-header {
                            border: none;
                        }
                    }
                }
                .ListItem {
                    border: none;
                }
                .circle-number {
                    border-radius: 50%;
                    width: 35px;
                    height: 30px;
                    padding-top: 4px;
                    float: left;
                    font-size: 20px;
                    margin-top: 15px;
                    margin-right: 18px;
                    font-weight: 500;
                    background: #fff;
                    border: 2px solid #cc0099;
                    color: #000099;
                    text-align: center;
                    margin-left: 16px;
                }
                .congratulations-text {
                    color: #212121;
                    font-size: 20px;
                    font-weight: 300;
                    line-height: 30px;
                    width: 82%;
                    margin-bottom: 20px;
                    a {
                        color: #000099;
                        margin: 0 5px;
                    }
                }
                .req-os-first-step {
                    .blue-box {
                        display: flex;
                        background-color: #000099;
                        padding: 55px;
                        color: #fff;
                        .Icon {
                            margin-right: 45px;
                        }
                        p {
                            font-size: 16px;
                            font-weight: 300;
                            line-height: 26px;
                            color: #fff;
                            width: 71%;
                            margin-bottom: 20px;
                        }
                        .link-info {
                            display: flex;
                            align-items: center;
                            .link-btn {
                                margin-left: 10px;
                                width: 21px;
                                height: 21px;
                            }
                        }
                        a {
                            display: contents;
                            font-size: 16px;
                            font-weight: bold;
                            line-height: 18px;
                            text-align: right;
                            color: #fff;
                        }
                    }
                    .bullet-list {
                        margin-bottom: 40px;
                        .title {
                            color: #212121;
                            font-size: 16px;
                            font-weight: 900;
                            line-height: 22px;
                            margin-top: 43px;
                            margin-bottom: 23px;
                        }
                        p {
                            color: #212121;
                            font-size: 16px;
                            font-weight: 300;
                            line-height: 26px;
                            margin-top: 15px;
                        }
                        ul {
                            li {
                                list-style: none;
                                color: #212121;
                                font-weight: 300;
                                line-height: 26px;
                                font-size: 16px;
                                margin-bottom: 15px;
                                &:before {
                                    content: "•";
                                    margin-right: 10px;
                                    color: #ca001b;
                                }
                            }
                        }
                    }
                }
                .req-os-second-step {
                    .content {
                        display: flex;
                        .bullet-list {
                            &:nth-child(2) {
                                border-left: 1px solid #d8d8d8;
                                padding-left: 40px;
                            }
                            margin-bottom: 40px;
                            width: 50%;
                            padding-right: 40px;
                            .title {
                                color: #212121;
                                font-size: 16px;
                                font-weight: 900;
                                line-height: 22px;
                                margin-top: 0px;
                                margin-bottom: 23px;
                            }
                            p {
                                color: #212121;
                                font-size: 16px;
                                font-weight: 300;
                                line-height: 26px;
                                margin-top: 15px;
                            }
                            ul {
                                li {
                                    list-style: none;
                                    color: #212121;
                                    font-weight: 300;
                                    line-height: 26px;
                                    font-size: 16px;
                                    margin-bottom: 15px;
                                    &:before {
                                        content: "•";
                                        margin-right: 10px;
                                        color: #ca001b;
                                    }
                                }
                            }
                        }
                    }
                    .bottom-text {
                        color: #212121;
                        font-size: 16px;
                        font-weight: 300;
                        line-height: 26px;
                    }
                    .submit-button {
                        float: left;
                        margin-bottom: 40px;
                        a {
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .pre-initiate-move-container {
        .req-os-screen {
            .top-content {
                .right-info {
                    p {
                        margin-left: 0;
                        width: 100%;
                    }
                }
                .left-info {
                    .buttons {
                        display: flex;
                        flex-direction: column;
                        .submit-button {
                            margin-right: 0;
                            margin-bottom: 20px;
                            a {
                                color: #fff;
                            }
                        }
                    }
                }
            }
            .three-steps-accordion {
                .accordion-box {
                    .congratulations-text {
                        width: 100%;
                    }
                    .req-os-second-step {
                        .content {
                            display: flex;
                            flex-direction: column;
                            .bullet-list {
                                &:nth-child(2) {
                                    border-left: 0;
                                    padding-left: 0px;
                                }
                                width: 100%;
                                padding-right: 0;
                                margin-bottom: 25px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .pre-initiate-move-container {
        .req-os-screen {
            h1 {
                line-height: 32px;
            }
            .top-content {
                flex-direction: column;
                .right-info {
                    width: 100%;
                    margin-top: 30px;
                }
                .left-info {
                    width: 100%;
                    padding-right: 0;
                }
            }
            .three-steps-accordion {
                .accordion-box {
                    .congratulations-text {
                        font-size: 16px;
                    }
                    .req-os-first-step {
                        .blue-box {
                            padding: 20px;
                            flex-direction: column;
                            p {
                                width: 100%;
                            }
                        }
                    }
                    .req-os-second-step {
                        .content {
                            display: flex;
                            flex-direction: column;
                            .bullet-list {
                                width: 100%;
                                padding-right: 0;
                                margin-bottom: 25px;
                            }
                        }
                    }
                }
            }
        }
    }
}