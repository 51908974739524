@import "~main.scss";


.gal-greeting-header {
  padding: 50px;
  .withMargin {
    margin-bottom: 15px;
  }
  .GalHeader {
    padding: 18px 0px 0px 0px;
  }
  .gal-greeting-text {
    color: #CC0099;	
    font-family: "J&J Circular";	
    font-size: 16px;	
    font-weight: bold;	
    line-height: 20px;
  }

    .gal-taxes-section {
        hr {
            height: 3px;
            border: none;
            background-color: $borderColor;
            margin-top: 37px;
            margin-bottom: 37px;
        }

        .gal-taxes-grid {
            display: flex;
            justify-content: space-between;
            align-items: center;

            p {
                color: #63666A;
                font-size: 14px;
                font-weight: 300;
                line-height: 20px;
                font-family: "J&J Circular";
            }

            strong {
                color: #212121;
                font-size: 20px;
                font-weight: 900;
                letter-spacing: -0.5px;
                line-height: 22px;
                margin-left: 13px;
                font-family: "J&J Circular";
            }

            > div {
                align-items: flex-end;
            }
        }
    }
}


.HelpComponent .help-component-block {
  .info-block .text-block {
    h2 {
      color:$blueText;
    }
    .intro{
      font-weight: 300;;
      color: $placeHolderColor;
    }
  }
  .email-block{
    a {
      .chat, .phone,.email{
        line-height: 10px;
      }
    }
  }
}

.IntPrepPlanStep {
  .pad-left {
    width:40%  !important;
  }
    
  .midsect-sub-text {
    font-size: 20px !important;	
    font-weight: 300 !important;	
    line-height: 30px !important;
    padding-bottom: 30px;
  }


  .midsect-sub-header {
    font-size: 18px !important;	
    font-weight: 900  !important;	
    line-height: 22px !important;
    padding-bottom: 30px;
  }
  .mail-icon{
    background-color: #FFFFFF;
    border-radius: 4px;
  }
}
@media screen and (max-width: $breakPoint-lg) {
.MidSectionComponent,
  .IntPrepPlanStep {
    display: block !important;
  }
}

.IntInHostStep{ 
  h3 {
    padding-top: 20px;
  }
}
.AboutYourBenefits {
  p {
    font-size: $subTitleSize !important;	
    font-weight: 300 !important;	
    line-height: 30px !important;
  }
}
.IntArrivalStep, .IntInHostStep{
  .step-number {
    opacity: 0.7;	
    color: #FFFFFF;	
    font-size: 16px;	
    font-weight: 900;	
    line-height: 22px;
  }
  div.flexible:first-child {
    width: 100% !important;
  }
  .step-second-link {
    margin-top: 0px !important;
  }
}

.DomPrepPlanStep, .DomRelocateStep {
  .mid-section-body .vertical {
  width: 80%;
  
  p{
    font-size: 20px !important;	
    font-weight: 300 !important;	
    line-height: 30px !important;
  }
}
}

.QuestionListCard {
    .question-card {
        .text-block {
            h3 {
                font-size: 16px;
                color: $black;
            }
        }
    }
}
