@import '~main.scss';

.create-edit-root {
    ::-ms-clear {
        display: none;
    }

    padding: 70px 150px;

    .main-form-body {
        max-width: 770px;
    }

    .radio-button-root {
        min-width: 20px;
    }

    .form-section {
        margin-top: 40px;
    }

    .form-section-subtitle {
        font-weight: 900;
    }

    .formElement_errorText.error-message {
        margin: 10px 0;
    }

    .input-container {
        display: flex;
        flex-grow: 1;
        max-width: 48%;
        width: 48%;
    }

    .date-input-container {
        max-width: 47%;
        display: flex;
        flex-grow: 1;
    }

    .cancel-button {
        margin-right: 20px;
    }

    .currency-conversion-root {
        margin-top: 25px;
        > div {
            padding: 20px 0;
        }
    }

    .interbank-exchange-container {
        display: flex;
        flex-direction: row;
    }

    .exchange-name {
        max-width: 40%;
        margin-left: 10px;
    }

    .exchange-description {
        font-size: $smallSize;
        color: $placeHolderColor;
        font-weight: 300;
        line-height: 20px;
    }

    .exchange-info-block {
        width: 100%;
        margin-left: 10px;
    }

    .exchange-title {
        font-weight: 300;
        margin-bottom: 10px;
    }

    .border-top {
        border-top: 2px solid $borderColor;
    }
    .border-bottom {
        border-bottom: 2px solid $borderColor;
    }

    .text-with-label {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        .label {
            color: $placeHolderColor;
            font-size: 13px;
            margin-bottom: 5px;
        }
        .text {
            font-size: $subTitleSize;
            font-weight: 300;
            color: $textColor;
        }
    }

    .exchange-info {
        display: flex;
        flex-direction: row;
        flex-grow: 1;
        justify-content: space-between;
    }

    .exchange-rate-date {
        display: flex;
        flex-direction: row;
        flex-grow: 1;
        justify-content: space-between;
        padding: 0 50px;
    }

    .reimbursed-amount {
        min-width: 170px;
        padding: 0 20px;
        width: 170px;
        align-self: flex-end;
        justify-self: flex-end;
    }

    .override-button {
        margin-top: 20px;
    }

    .override-container {
        display: flex;
        flex-direction: row;

        .reimbursed-amount {
            align-self: flex-start;
            padding: 20px;
            background-color: $mainBackgroundColor;
        }
    }

    .override-date-rate {
        display: flex;
        justify-content: center;
        flex-grow: 1;
        flex-direction: column;
        padding: 0 50px;
    }

    .override-field {
        max-width: 200px;
    }
}

@media screen and (max-width: $breakPoint-md) {
    .create-edit-root {
        padding: 40px 60px;

        .exchange-name {
            max-width: 100%;
        }

        .exchange-info {
            margin: 25px 0 10px;
        }

        .exchange-rate-date {
            padding-left: 10px;
        }

        .interbank-exchange-container, .override-container {
            flex-direction: column;
        }

        .override-date-rate {
            padding-left: 10px;
        }
    }
}

@media screen and (max-width: $breakPoint-sm) {
    .create-edit-root {
        padding: 30px;

        .input-container {
            width: 100%;
            max-width: 100%;
        }

        .exchange-info {
            flex-direction: column;
        }

        .reimbursed-amount {
            align-self: flex-start;
            margin-top: 20px;
            padding: 10px !important;
        }
    }
}
