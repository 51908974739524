@import "~main.scss";

#menu-flightNumber,
#menu-leadership,
#menu-rotations,
#menu-californiaTier {
    ul {
        padding: 0 !important;
    }
}

.add-move-details-container{
    display: flex;
    width: 100%;
    flex-direction: column;
    margin: 0;
    .Input {
      &input {
        height: 40px;
      }
    }  

    .Mui-error:after {
      visibility: hidden !important;
    }
    
    .text-section{
      margin: 40px 0 85px 10%;
      width: 80%;

      .section-title {
        font-size: 35px;
        line-height: 40px;
      }
      .title{
        font-weight: 700;
        font-size: 17px;
        margin: 40px 0 30px 0;
      }
      .Mui-focused {
        .input-label {
            color: #63666A;
            font-size: 14px;
            font-weight: 300;
            line-height: 18px;
        }
        .Mui-required {
          color: #63666A;
          font-size: 14px;
          font-weight: 300;
          line-height: 18px;
          font-family: "J&J Circular";
        }
      }
      .Mui-required {
        color: #212121;
        font-size: 15px;
        font-weight: 300;
        line-height: 24px;
        font-family: "J&J Circular";
      }
      .input-label {
        height: 24px;
        color: #212121;
        font-size: 15px;
        font-weight: 300;
        line-height: 24px;
      }
      .start-end-date-container{
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .dateSection{
          display: flex;
          flex-direction: column;

          .dates{
            display: flex;
            flex-direction: column;
            width: 100%;
            justify-content: space-between;
            margin: 0 0 35px 0;
          }

          .label{
            width: 100%;
            font-size: 12px;
            word-spacing: 3px;
            margin: 0 0 45px 0;
            line-height: 22px;
    
            span{
              color: rgb(52, 52, 149);
            }
          }
        }
        .immigration_timeline_modal{ 
          display: flex;
          width: 100%;
          justify-content: center;
          align-items: center;

          .immigration_timelines{
            display: flex;
            justify-content: space-around;
            width: 438px;
            height: 150px;
            box-shadow: 0px 0px 24px -7px;
            border-radius: 10px 10px 10px 10px;

            img{
              width: 45px;
              height: 40px;
              margin: 16px 0 0 0;
            }
            section{
              display: flex;
              flex-direction: column;
              width: 75%;

              p{
                display: flex;
                margin: 20px 0 0 0;
                line-height: 26px;
                font-weight: 100;
                font-size: 11px;
              }
              .immigration_timeline_text{
                margin: 0 0 15px 0;
                color: rgb(52, 52, 149);
              }
            }
            .closeButton{
              margin: 15px;
              cursor: pointer;
            }
          }
          &.close{
            display: none;
          }
        }
      }
    

      .input-select-container {
        display: flex;
        width: 300px;
        .placeholder{
          color: #212121;
          line-height: 18px;
          font-size: 14px;
          font-weight: 300;
          transition: all 0.25s linear;
          position: absolute;
          left: 0;
        }
        .placeholder:after{
          content: ""
        }
      }

      .option-select-container {
        display: flex;
        width: 300px;
      }
    }

    .download-policy-section{
      display: flex;
      flex-direction: column;
      align-items: center;

      .line {
        display: block;
        box-sizing: border-box;
        height: 2px;
        width: 80%;
        border: 1px solid #E3E3E3;
        margin: 55px 0 0 0;
      }

      .buttons{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 80%;
        margin-top: 35px;

        .white-button{
          height: 43.55px;
          width: 250px;
          margin: 0 0 10px 0;

          .buttonLabel{
            display: flex;
            font-size: 16px;
          }
        }

        section{
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .red-button{
            height: 43.55px;
            .buttonLabel{
              font-size: 16px;
            }
            &.disabled{
              background-color: rgb(190, 188, 188);
            }
          }
          .white-button{
            height: 43.55px;

            .buttonLabel{
              display: flex;
              font-size: 16px;
              width: 200px;
            }
            &.second{
              padding: 10px 39px;
            }
          }
        }
      }
    }
}

@media screen and (min-width: 1024px) {
  .add-move-details-container{
    margin: 0 0 200px 0;

    .messages {
      margin-top: 26px;
      max-width: 473px;

      @media all and (max-width: $small) {
          max-width: 100%;
      }

      .message {
          border-radius: 9px;
          background-color: $disabledColor;
          padding: 21px 17px 20px 27px;
          font-size: 16px;
          font-weight: 300;
          line-height: 24px;

          @media all and (min-width: $small) {
              width: 100%;
          }

          &:not(:last-of-type) {
              margin-bottom: 13px;
          }
      }
  }

  h5 {
    font-size: 18px;
    line-height: 36px;
    letter-spacing: normal;

    &.california-title,
    &.base-title,
    &.family-title {
        margin-top: 46px;
    }
}
.dropdown {
        .dropdown-label {
            color: $black;
            font-size: 14px;
            line-height: 24px;

            span {
                color: $buttonsColor;
            }
        }

        .dropdown-root {
            padding: 0;
        }

        .dropdown-menu {
            min-width: 240px;
            font-size: 14px;
            font-weight: 300;
            line-height: 24px;
            padding-bottom: 4px;
            border-bottom: 2px solid $black;

            @media screen and (min-width: $small) {
                min-width: 315px;
            }
        }
    }

    .tooltip-popper {
        opacity: 1;
        border: 3px solid #eaebec;
        pointer-events: all;

        @media screen and (min-width: $small) {
            left: 40px !important;
            top: 107px !important;
        }

        h4 {
            font-size: 28px;
            color: $black;
            line-height: 32px;
        }

        .tier {
            margin-top: 20px;
            font-size: 14px;
            font-weight: 300;
            line-height: 18px;

            &:first-of-type {
                margin-top: 32px;
            }

            span {
                font-weight: 900;
                display: block;
            }
        }
    }

    .tooltip-root {
        padding: 15px 17px 35px 20px;
        background-color: #fff;
        margin: 0;
        position: relative;

        @media screen and (min-width: $small) {
            padding: 15px 17px 35px 30px;
        }

        @media screen and (min-width: $medium) {
            max-width: 451px;
        }

        &:after,
        &:before {
            content: "";
            display: none;
            position: absolute;
            left: -39px;
            width: 0;
            height: 0;
            border-style: solid;

            @media screen and (min-width: $medium) {
                display: block;
            }
        }

        &:after {
            top: 52px;
            border-color: transparent #fff transparent transparent;
            border-width: 22px;
        }

        &:before {
            top: 56px;
            border-color: transparent #eaebec transparent transparent;
            border-width: 18px;
        }
    }

    .close-button {
        display: flex;
    }

    .radio-root {
        margin-right: 27px;
    }

    .radio-button-root {
        padding: 0 14px;

        svg {
            fill: $disabledColor;
        }
    }

    .radio-label {
        font-size: 14px;
        font-weight: 300;
        line-height: 18px;
    }

    .divider {
        margin-top: 50px;
        height: 2px;
        background-color: #e3e3e3;
        width: 100%;

        @media screen and (min-width: $large) {
            width: 773px;
        }
    }

    .text-section{
      margin: 40px 0 85px 10%;
      width: 80%;

      .section-title {
        height: 112px;	
        width: 908px;	
        color: #000099;	
        font-family: "J&J Circular";	
        font-size: 44px;	
        font-weight: 900;	
        letter-spacing: -0.5px;	
        line-height: 56px
      }

      .title{
        height: 36px;
        width: 668px;
        color: #212121;
        font-family: "J&J Circular";
        font-size: 18px;
        font-weight: 900;
        line-height: 36px;
      }

      .start-end-date-container{
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .dateSection{
          display: flex;
          flex-direction: column;

          .dates{
            display: flex;
            flex-direction: row;
            width: 550px;
            justify-content: space-between;
            margin: 0 0 35px 0;
          }
          .label{
            height: 40px;
            width: 473px;
            color: #212121;
            font-family: "J&J Circular";
            font-size: 12px;
            font-weight: 300;
            line-height: 20px;
    
            span{
              color: rgb(52, 52, 149);
              cursor: pointer;
            }
          }

        }
        .immigration_timeline_modal{ 
          display: flex;
          width: 450px;
          justify-content: center;
          align-items: center;
          width: 438px;

          .immigration_timelines{
            display: flex;
            justify-content: space-around;
            width: 438px;
            height: 150px;
            box-shadow: 0px 0px 24px -7px;
            border-radius: 10px 10px 10px 10px;

            img{
              width: 74px;
              margin: 10px 0 0 12px;
              height: 62px;
            }
            section{
              display: flex;
              flex-direction: column;
              width: 75%;

              p{
                height: 72px;
                width: 312px;
                color: #212121;
                font-family: "J&J Circular";
                font-size: 16px;
                font-weight: 300;
                line-height: 24px;
              }
              .immigration_timeline_text{
                margin: 0 0 15px 0;
                color: rgb(52, 52, 149);
              }
            }
            .closeButton{
              margin: 15px;
              cursor: pointer;
            }
          }
          &.close{
            display: none;
          }
        }
      }
    
      .input-select-container {
        display: flex;
        width: 750px;
        .placeholder{
          color: #212121;
          line-height: 45px;
          font-size: 14px;
          font-weight: 300;
          transition: all 0.25s linear;
          position: absolute;
          left: 0;
        }
        input:focus ~ span {
          color: #63666A;
          font-size: 12px;
          font-weight: 300;
          line-height: 18px;
        }
        .placeholder:after{
          content: ""
        }
        .Input {
          input {
            background: transparent;
            height: 30px;
          }
        }
      }

      .option-select-container {
        display: flex;
        width: 290px;
      }
    }
    .download-policy-section{
      display: flex;
      flex-direction: column;
      align-items: center;

      .line {
        display: block;
        box-sizing: border-box;
        height: 2px;
        width: 80%;
        border: 1px solid #E3E3E3;
        margin: 55px 0 0 0;
      }

      .buttons{
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 80%;
        margin-top: 35px;

        .white-button{
          height: 43.55px;
          width: 250px;

          .buttonLabel{
            display: flex;
            font-size: 16px;
            width: 200px;
          }
        }

        section{
          display: flex;
          flex-direction: row;
          width: 475px;
          justify-content: space-between;

          .red-button{
            height: 43.55px;
            width: 202px;
            .buttonLabel{
              font-size: 16px;
            }
            &.disabled{
              background-color: rgb(190, 188, 188);
            }
          }
          .white-button{
            height: 43.55px;
            width: 250px;

            .buttonLabel{
              display: flex;
              font-size: 16px;
              width: 200px;
            }
            &.second{
              padding: 10px 39px;
            }
          }
        }
      }
    }
  }
}
.dropdown-item {
  font-size: 14px !important;
  font-weight: 300;
  border: 1px solid $disabledColor !important;
}

.select-field-spinner {
  position: absolute;
  top: 32px;
  right: 25px;
  .Spinner {
      height: 5px;
      width: 5px;
      margin: -4px 0 0 5px;
  }
}