@import "~main.scss";

.employee-job-details-container-nc{
  padding: 0px 10px;
  .header-section{
    width: 100%;
    margin: 0 0 66px 0;

    .section-title {
      .error-heading {
        color: #000099;
        font-size: 44px;
        font-weight: 900;
        margin-top: 30px;
        letter-spacing: -0.5px;
        line-height: 48px;
      }
      .error-subheading {
        color: #212121;
        font-size: 32px;
        font-weight: 300;
        margin-top: 30px;
        letter-spacing: -0.44px;
        line-height: 42px;
      }
    }
  }
  .input-section{
    display: flex;
    flex-direction: column;

    .inputs{
      width: 100%;
      margin: 0 0 50px 0;
      
      p{
        color: #63666A;	
        font-family: "J&J Circular";	
        font-size: 14px;	
        font-weight: 300;	
        line-height: 24px;
        margin: 10px 0 0 0;
        &.shift-on-error {
          margin: 0;
          float: left;
        }
      }
      .Input {
        height: auto;
        display:block;
        position: relative;
        margin: 0px 0px 50px;
        &.on-error {
          //height: 70px;
          .reqid-error-msg {
            display: block;
            float: left;
            position: relative;
            margin: 0;
            }
        }

        .placeholders {
          color: #212121;	
          font-family: "J&J Circular";	
          font-size: 14px;
          font-weight: 300;
          line-height: 24px;
          transition: all 0.25s linear;
          position: relative;
          left: 0;
          top: 26px;
          &.has-wwid-error {
            color: #ca001b;
          }
          &.has-reqid-error {
            color: #ca001b;
            margin-top: 6px;
          }

          .required-symbol{
            color: #ca001b;
          }
        }

        .text-input-error {
          color: #ca001b;
          border-bottom: 2px solid #ca001b;
        }
        span.wwid-error-msg {
          display: block;
          color: #ca001b !important;
          font-weight: bold;
          margin-top: 10px 0 0 0;
          position: relative;
          width: 230px;
        }
        span.reqid-error-msg {
          color: #ca001b !important;
          font-weight: bold;
          margin-top: 71px;
          position: absolute;
          width: 230px;
        }
        input {
          outline: none;
          border: none;
          border-bottom: 2px solid rgb(0, 0, 0);
          height: 30px;
          font-size: $largeSize;
          font-weight: 100;
          position: relative;
          background: none;
          z-index: 1;
          &:focus, &:valid {
            &~span {
              &:before, &:after {
                display: none;
              }
              top: 0px;
              color: #63666A;
              font-size: 12px;
              font-weight: 300;
              line-height: 18px;
            }
          }
      
        }
      }
    }
    .immigration_timeline_modal{ 
      display: flex;
      width: 100%;
      height: 191px;
      box-shadow: 0px 0px 24px -7px;
      border-radius: 10px 10px 10px 10px;
    
      .icon-section{
        display: flex;
        justify-content: center;
        width: 70px;

        img{
          width: 45px;
          height: 40px;
          margin: 16px 0 0 0;
        }
      }
      .text-section{
        display: flex;
        flex-direction: column;
        padding: 20px 0 0 0;
        width: 247px;
        
        .title{
          color: #212121;	
          font-family: "J&J Circular";	
          font-size: 16px;	
          font-weight: 500;	
          line-height: 24px;
        }
        p{
          color: #212121;	
          font-family: "J&J Circular";	
          font-size: 16px;	
          font-weight: 100;	
          line-height: 24px;
        }
        .email-section{
          margin: 10px 0 0 0;

          p{
            color: #000099;
            font-family: "J&J Circular";	
            font-size: 14px;	
            font-weight: 500;	
            line-height: 20px;
          }
        }
      }
    }
  }
  .information-section{
    float: left;
    width: 100%;

    .title-section{
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 80px;

      .title{
        color: #000099;	
        font-family: "J&J Circular";	
        font-size: 20px;	
        font-weight: 300;	
        line-height: 30px;
      }
    }
    .body-section{
      margin: 0 0 70px 0;

      .text{
        height: 100%;
        color: #212121;	
        font-family: "J&J Circular";	
        font-size: 20px;	
        font-weight: 300;	
        line-height: 30px;
      }
      .sub-heading{
        color: #212121;	
        font-family: "J&J Circular";	
        font-size: 26px;	
        font-weight: 900;	
        letter-spacing: -0.36px;	
        line-height: 42px;
        margin: 30px 0 20px 0;
      }
      .small-text{
        color: #212121;
        font-family: "J&J Circular";
        font-size: 14px;
        font-weight: 300;
        line-height: 24px;
      }
      ul{
        margin: 20px 0 0 0;
      }
    }
  }
  .button-section{
    float: left;
    width: 100%;
    padding: 30px 0px 50px;
    .line {
        display: block;
        box-sizing: border-box;
        height: 2px;
        width: 100%;
        border: 1px solid #e3e3e3;
        margin: 0;
    }
    .buttons {
        display: flex;
        justify-content: space-between;
  
        .white-button {
          height: 44px;	
          width: 133px;
          cursor: pointer;
          border: 1px solid rgb(197, 51, 51);

          .buttonLabel {
            color: rgb(197, 51, 51);
            font-family: "J&J Circular";	
            font-size: 16px;	
            font-weight: bold;	
            line-height: 20px;	
            text-align: center;
          }
          &.disabled{
            background-color: rgb(190, 188, 188);
          }
        }
  
        .red-button {
          height: 44px;	
          width: 133px;
          background-color: rgb(197, 51, 51);
          cursor: pointer;

          .buttonLabel {
            color: #ffffff;
            font-family: "J&J Circular";	
            font-size: 16px;	
            font-weight: bold;	
            line-height: 20px;	
            text-align: center;
          }
          &.disabled{
            background-color: rgb(190, 188, 188);
          }
        }
    }
  }
  .line{
    display: block;
    box-sizing: border-box;
    height: 2px;
    width: 100%;
    background: #e3e3e3;
    margin: 55px 0 0 0;
  }
}
@media screen and (min-width: 1024px) {
  .employee-job-details-container-nc{
    padding: 0px 10px;
  
    .header-section{
      width: 730px;
      margin: 0 0 50px 0;
    }
    .input-section{
      display: flex;
      flex-direction: row;
      width: 100%;
      float: left;
      .spinner-wrappar {
        width: 100%;
      }
  
      .inputs{
        width: 50%;
        height: auto;
        // margin: 0 159px 0 0;
        float: left;
  
        p{
          color: #63666A;	
          font-family: "J&J Circular";	
          font-size: 14px;	
          font-weight: 300;	
          line-height: 24px;
          margin: 10px 0 0 0;
  
        }
        .Input {
          height: auto;
          width: 300px;
          display: block;
          position: relative;
          margin: 0;

          .placeholders {
            color: #212121;	
            font-family: "J&J Circular";	
            font-size: 14px;	
            font-weight: 300;	
            line-height: 24px;
            transition: all 0.25s linear;
            position: absolute;
            left: 0;
            top: 18px;
  
            .required-symbol{
              color: #ca001b;
            }
          }
          input {
            margin-top: 10px;
            outline: none;
            border: none;
            border-bottom: 2px solid rgb(0, 0, 0);
            height: 30px;
            font-size: $largeSize;
            font-weight: 100;
            position: relative;
            background: none;
            z-index: 1;
            &.text-input-error {
              margin-top: 17px;
            }
            &:focus, &:valid {
              &~span {
                &:before, &:after {
                  display: none;
                }
                top: 0px;
                color: #63666A;
                font-size: 12px;
                font-weight: 300;
                line-height: 18px;
              }
            }
        
          }
        }
      }
      .immigration_timeline_modal{ 
        display: flex;
        width: 352px;
        height: 191px;
        box-shadow: 0px 0px 24px -7px;
        border-radius: 10px 10px 10px 10px;
        float: right;
      
        .icon-section{
          display: flex;
          justify-content: center;
          width: 70px;
  
          img{
            width: 45px;
            height: 40px;
            margin: 16px 0 0 0;
          }
        }
        .text-section{
          display: flex;
          flex-direction: column;
          padding: 20px 0 0 0;
          width: 247px;
          
          .title{
            color: #212121;	
            font-family: "J&J Circular";	
            font-size: 16px;	
            font-weight: 500;	
            line-height: 24px;
          }
          p{
            color: #212121;	
            font-family: "J&J Circular";	
            font-size: 16px;	
            font-weight: 100;	
            line-height: 24px;
          }
          .email-section{
            margin: 10px 0 0 0;
  
            p{
              color: #000099;
              font-family: "J&J Circular";	
              font-size: 14px;	
              font-weight: 500;	
              line-height: 20px;
            }
          }
        }
      }
    }
    .information-section{
  
      .title-section{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 80px;
  
        .title{
          color: #000099;	
          font-family: "J&J Circular";	
          font-size: 20px;	
          font-weight: 300;	
          line-height: 30px;
        }
      }
      .body-section{
        margin: 0 0 70px 0;
  
        .text{
          height: 108px;
          color: #212121;	
          font-family: "J&J Circular";	
          font-size: 20px;	
          font-weight: 300;	
          line-height: 30px;
        }
        .sub-heading{
          height: 42px;	
          width: 504px;	
          color: #212121;	
          font-family: "J&J Circular";	
          font-size: 26px;	
          font-weight: 900;	
          letter-spacing: -0.36px;	
          line-height: 42px;
          margin: 0 0 20px 0;
        }
        .small-text{
          color: #212121;
          font-family: "J&J Circular";
          font-size: 14px;
          font-weight: 300;
          line-height: 24px;
        }
        ul{
          margin: 20px 0 0 0;
        }
      }
    }
    .button-section{
      .line {
          display: block;
          box-sizing: border-box;
          height: 2px;
          width: 100%;
          border: 1px solid #e3e3e3;
          margin: 0;
      }
      .buttons {
          display: flex;
          justify-content: space-between;
    
          .white-button {
            height: 44px;	
            width: 133px;
            cursor: pointer;
            border: 1px solid rgb(197, 51, 51);

  
            .buttonLabel {
              color: rgb(197, 51, 51);
              font-family: "J&J Circular";	
              font-size: 16px;	
              font-weight: bold;	
              line-height: 20px;	
              text-align: center;
            }
            &.disabled{
              background-color: rgb(190, 188, 188);
            }
          }
    
          .red-button {
            height: 44px;	
            width: 133px;
            background-color: rgb(197, 51, 51);
            cursor: pointer;
  
            .buttonLabel {
              color: #ffffff;
              font-family: "J&J Circular";	
              font-size: 16px;	
              font-weight: bold;	
              line-height: 20px;	
              text-align: center;
            }
            &.disabled{
              background-color: rgb(190, 188, 188);
            }
          }
      }
    }
    .line{
      float: left;
      display: block;
      box-sizing: border-box;
      height: 2px;
      width: 100%;
      background: #e3e3e3;
      margin: 55px 0 0 0;
    }
  }
}

.employee-job-details-container-nc {
  .input-section {
      .inputs {
          .Input {
            input {
              background: none;  
            }
          }
      } 
  }
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px)  {
  .employee-job-details-container-nc {
    .input-section {
        .inputs {
            .Input {
              input {
                width: 75%;
              }
            }
        } 
    }
  }
  .employee-job-details-container-nc {
    .input-section {
      .inputs {
        .Input {
          input {
            margin-top: 15px;
          }
          .placeholders {
            top: 18px;
            position: absolute;
          }
        }
      }
    }
  }
}
@media only screen and (max-width : 768px)  {
  .employee-job-details-container-nc {
    .input-section {
      .inputs {
        .Input {
          input {
            margin-top: 20px;
          }
          .placeholders {
            position: absolute;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 600px) {
  .employee-job-details-container-nc {
      .emp-job-details-wrapper {
          .line {
              margin-top: 50px;
          }
      }
  }
}
@media screen and (max-width: 600px) {
  .employee-job-details-container-nc {
    width: 100%;
    .button-section {
        .flexible {
            flex-direction: column;
            .Button:not(:first-of-type) {
                margin-top: 20px;
            }
        }
    }
    .line {
        margin: 0px;
    }
    .input-section {
        .inputs {
            .Input {
              input {
                width: 90%;
              }
            }
        } 
    }
  }
} 