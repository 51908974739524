@import '~main.scss';

.AllowanceItem {
  min-height: 65px;
  padding: 10px 0;
  box-sizing: border-box;

  &.opened {
    * {
      color: $blueText;
    }
  }

  .icon-block {
    min-width: 300px;
    .allowance-value {
      font-size: $largeSize;
      .currency {
        font-size: 24px;
        font-weight: 900;
      }
    }
  }

  .allowance-name {
    margin-right: 15px;
    font-weight: 300;
    font-size: $largeSize;
  }

}

@media screen and (max-width: $breakPoint-lg) {
  .AllowanceItem {
    .icon-block {
      min-width: 160px;

      .allowance-value {
        font-size: $mediumSize;

        .currency {
          font-size: $subTitleSize;
        }
      }
    }
  }
  .allowance-name {
    font-size: $mediumSize;
  }
}
