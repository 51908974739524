@import '~main.scss';

.title-with-description {
    .twd-subtitle {
        font-size: 20px;
        font-weight: bold;
        color: $subtitleColor;
        margin-bottom: 10px;
    }

    .twd-title {
        color: $blueText;
    }

    .twd-description-text {
        font-size: 16px;
        font-weight: 300;
        line-height: 25px;
        color: $black;
    }

    .twd-additional-content {
        margin-top: 20px;
    }

    .twd-description {
        margin-top: 15px;
        @media screen and (max-width: $breakPoint-sm) {
            display: none;
        }
    }
}
