@import "~main.scss";

.fast-feedback-popup {
    padding: 0 !important;
}

.Feedback {
    width: 733px;
    height: 448px;
    margin: 0px;
    padding: 0px;
    display: flex;
    box-sizing: border-box;

    &.thankyou {
        padding: 0 61px;
        width: 574px;
    }

    .right{
        position: relative;
        .content {
            margin: 0;
            padding: 75px 60px 56px 52px;
            textarea {
                height: 30px;
                border: none;
                resize: none;
                border-bottom: 2px solid $borderColor;
                background: none;
                outline: none;
                padding-top: 10px;
                display: block;
                width: 100%;
            }
            .user-name {
                color: $subtitleColor;
                font-size: 16px;
                margin-bottom: 15px;
            }
            .text {
                font-size: 24px;
                color: $blueText;
                font-weight: bold;
                margin-bottom: 35px;
            }
            .optional {
                font-size: 18px;
                font-weight: 100;
                color: $placeHolderColor;
            }
            .list-item {
                height: 60px;
                padding-right: 30px;
                margin-top: 20px;
                input:checked ~ div {
                    &:after {
                        opacity: 1;
                    }
                    &:before {
                        background-color: $blueText;
                        border-color: $blueText;
                    }
                }
                label {
                    cursor: pointer;
                    max-width: 100%;
                }
                .task-link{
                    color: $blueText;
                }
                div {
                    max-width: calc(100% - 36px);
                    height: 35px;
                    position: relative;
                    margin-left: 15px;
                    align-items: flex-end;
                    font-size: 14px;
                    font-weight: 300;
                    &:after {
                        content: '✓';
                        color: #fff;
                        position: absolute;
                        font-size: 14px;
                        left: -26px;
                        opacity: 0;
                        top: 6px;
                    }
                    &:before {
                        content: '';
                        display: flex;
                        width: 20px;
                        height: 20px;
                        border: 1px solid #e6e6e6;
                        position: absolute;
                        border-radius: 1px;
                        background-color: #fff;
                        top: 2px;
                        left: -31px;
                        cursor: pointer;
                    }
                }
            }
        }
        .close {
            position: absolute;
            top: 30px;
            right: 27px;
            margin: 0;
        }
    }

    .HelpComponent {
        width: 251px;
        height: 100%;
        margin: 0px;
        padding: 0px;
        min-width: 251px;
        background-color: #000099;
        &.feedback {
            .info-block {
                > p {
                    margin-bottom: 50px;
                    font-size: 18px;
                    span {
                        width: 43px;
                        height: 2px;
                        display: block;
                        background-color: $white;
                        margin-top: 32px;
                    }
                }
            }
            .text-block {
                margin: 0;
                margin-top: 32px;
                text-align: center;
                max-width: 100%;
            }
        }


        .image-block {
            margin: auto;
        }

        p {
            color: white;
        }

        .text-block {
            span.name {
                color:  white;
            }

        }

    }

    .pointer {
        cursor: pointer;
        margin-top: 68px;
        text-align: right;
        span {
            font-size: 14px;
            color: $blueText;
            font-weight: 100;
        }
    }

    .contact {
        width: 251px;
        height: 100%;
        float: left;
        margin: 0px;
        padding: 0px;

    }

    .right {
        img.close {
            height: 15px;
            width: 15px;
        }
    }
    ul {
        display: flex;
    }

    li {
        float: right;
        width: 110px;
        height: 110px;
        background-size: cover;
        background-position: 25% 50%;
        margin: 7px;
    }

}

button.Feedback {
    position: fixed;
    top: 59%;
    left: 71%;
    z-index: 50;
    width: 100px;
    height: 35px;
}

@media screen and (max-width: $breakPoint-md){
    .Feedback {
        display: flex;
        flex-direction: column-reverse;
        height: auto;
        width: 375px;
        &.thankyou {
            padding-bottom: 56px;
            width: 375px;
            padding-right: 25px;
            padding-left: 25px;
            .right {
                .content {
                    padding-bottom: 0;
                }
            }
        }
        > .contact {
            width: 100%;
            height: 130px;
            .HelpComponent {
                width: 100%;
                .help-component-block {
                    width: 100%;
                    .info-block {
                        display: flex;
                        padding: 25px;
                        box-sizing: border-box;
                        justify-content: flex-start;
                        align-items: center;
                        position: relative;
                        width: 100%;
                      p {
                          order: 1;
                          margin: 0;
                          font-size: 18px;
                          position: absolute;
                          top: 45px;
                          left: 135px;
                          span {
                              display: none;
                          }
                      }
                        .image-block {
                            order: 0;
                            width: 90px;
                            height: 90px;
                            margin-right: 20px;
                            margin-left: 0;
                        }
                        .text-block {
                            margin-top: 42px;
                            .name {
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
        }
        .right {
            > .content {
                padding-left: 25px;
                padding-right: 25px;
                .pointer {
                    text-align: center;
                }
            }
            &.comments {
                .list-item ~ div {
                    width: 100%;
                    margin-top: 50px;
                    .Button {
                        width: 100%;
                    }
                }
            }
        }
    }
}
