@import '~main.scss';

.Options {
  position: absolute;
  z-index: 1000;
  background-color: white;
  max-height: 200px;
  width: 150px;
  overflow-y: auto;
  padding: 5px;
  border-radius: 3px;
  -webkit-box-shadow: 0 -2px 5px 6px black;
  -moz-box-shadow:    0 -2px 5px 6px black;
  box-shadow:         0 -2px 20px 5px $grey;

  .closeButton {
    position: absolute;
    height: 25px;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 20px;
    top: 20px;
    font-size: 30px;
    color: $darkGrey;
    cursor: pointer;
    border-radius: 4px;

    &:hover {
      background-color: $borderColor;
    }

    &:after {
      display: block;
      content: "⨯";
    }
  }

  &>ul {
    list-style-type: none;

    & .option {
      height: 20px;
      max-width: 200px;
      padding: 5px;
      cursor: pointer;
      color: $textColor;
      &:hover {
        background-color: $borderColor;
      }

      &.selected {
        background-color: $blueText;
        color: white;
      }

        .count {
            height: 25px;
            width: 25px;
            background-color: $purple;
            border-radius: 50%;
            color: $white;
            margin-left: 12px;
            font-size: 14px;
            font-weight: 900;
        }
    }
  }
}
