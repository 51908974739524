@import "~main.scss";

.PayGradeContainer {
    margin-bottom: 50px;
    .section-title {
        margin-bottom: 50px;
    }
    .selection-card-block {
        > h2 {
            height: 30px;
            width: 223.05px;
            color: #212121;
            font-size: 20px;
            font-weight: 300;
            line-height: 30px;
        }
        > p {
            display: flex;
            flex-wrap: wrap;
            height: 41px;
            width: 205px;
            color: #212121;
            font-size: 32px;
            font-weight: 900;
            line-height: 41px;
        }
    }
}

@media screen and (max-width: 767px) {
    .regularSelectionCard {
        cursor: pointer;
        max-width: 295px;
        height: 245px;
        margin: 18px 30px 0 0;
        background-color: #ffffff;
        border: 1px solid #d8d8d8;
    }
    .PayGradeContainer {
        &.assignment-section {
            margin-bottom: 0px;
            .CardSection {
                flex-direction: column;
                .optionSelectionCard {
                    padding: 15px 0;
                    max-width: 300px;
                    margin-bottom: 20px;
                    .icon-block {
                        display: none;
                    }
                    .selection-card-block {
                        h2 {
                            margin-left: 30px;
                            margin-bottom: 0;
                            font-size: 20px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .regularSelectionCard {
        cursor: pointer;
        max-width: 295px;
        height: 245px;
        margin: 0 30px 0 0;
        background-color: #ffffff;
        border: 1px solid #d8d8d8;
    }

    .PayGradeContainer {
        .TextSection {
            margin: 25px 0;
            .section-title {
                font-size: 35px;
            }
        }
        .CardSection {
            display: flex;
            flex-direction: row;
        }
        &.assignment-section {
            margin-bottom: 0px;
            .CardSection {
                flex-direction: row;
                .optionSelectionCard {
                    padding: 15px 0;
                    width: 300px;
                    .icon-block {
                        display: none;
                    }
                    .selection-card-block {
                        h2 {
                            margin-left: 30px;
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1024px) {
    .regularSelectionCard {
        cursor: pointer;
        max-width: 295px;
        height: 245px;
        margin: 0 30px 0 0;
        background-color: #ffffff;
        border: 1px solid #d8d8d8;
    }
    .RecruitedSelectorContainer {
        display: flex;
        width: 100%;
        flex-direction: column;
        margin: 0;
        .selection-card-block {
            > h2 {
                height: 30px;
                width: 223.05px;
                color: #212121;
                font-size: 20px;
                font-weight: 300;
                line-height: 30px;
            }
            > p {
                display: flex;
                flex-wrap: wrap;
                height: 41px;
                width: 205px;
                color: #212121;
                font-size: 32px;
                font-weight: 900;
                line-height: 41px;
            }
        }
        .PayGradeContainer {
            width: 80%;
            margin: 20px 0 85px -36px;

            .TextSection {
                margin: 0 0 25px 0;

                .section-title {
                    font-size: 45px;
                    width: 100%;
                }
            }
            .CardSection {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
            }
            &.assignment-section {
                margin-bottom: 200px;
                .CardSection {
                    flex-direction: column;
                    .optionSelectionCard {
                        margin-bottom: 30px;
                        padding: 45px 0;
                        width: 370px;
                        .icon-block {
                            display: none;
                        }
                        .selection-card-block {
                            h2 {
                                margin-left: 30px;
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}
