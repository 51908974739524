@import "~main.scss";

.About {
  .about-main {
    padding: 72px 150px 10px 150px;
    flex-grow: 1;

    .header-text  {
      font-size: 44px;
      line-height: 40px;
    }

    .description-about {
      padding-bottom: 20px;
      width: 100%;
      color: #212121;
      font-family: "J&J Circular";
      font-size: 20px;
      font-weight: 300;
      line-height: 30px;
    }

    .question-last {
      .QuestionListCard {
        .text-block {
          border-bottom: 0px;
          padding-bottom: 0px;
        }
      }
    }

    .QuestionListCard {
      .text-block {
        h2 {
          color: #000099;	font-family: "J&J Circular";	font-size: 20px;	font-weight: 900;	letter-spacing: -0.5px;	line-height: 26px;
        }
        h3 {
          color: #CC0099;
          font-family: "J&J Circular";
          font-size: 16px;
          font-weight: bold;
          line-height: 30px;
        }

        .the-talent-mobility-p {
          color: #212121;
          font-family: "J&J Circular";
          font-size: 20px;
          font-weight: 300;
          line-height: 30px;
        }

        .strategic-mobility-p {
          color: #212121;
          font-family: "J&J Circular";
          font-size: 16px;
          font-weight: 300;
          line-height: 30px;
          padding-bottom: 20px;
          padding-top: 10px;
        }

        .manager-service {
          width: 100%;
        }
        .employee-service {
          width: 100%;
        }
      }
    }
  }
  .about-midcomp {
    padding: 80px 150px;
    width: calc(100% - 300px);

    h3 {
      color: #FFFFFF;
      font-family: "J&J Circular";
      font-size: 20px;
      font-weight: 900;
      letter-spacing: -0.5px;
      line-height: 42px;
    }
    p {
      color: #FFFFFF;
      font-family: "J&J Circular";
      font-size: 24px;
      font-weight: 300;
      line-height: 36px;
    }

    .mid-section-body {
      .icon-block {
        width: 70px;
      }
    }
  }


  .stackla-carousel {
    background-color: $white;
    padding: 30px 50px;
    .CTAComponent {
      margin: 5px;
    }
    > h2 {
      font-size: 30px;
      color: $blueText;
      span {
        height: 2px;
        width: 50px;
        background-color: $blueText;
        margin-top: 26px;
      }
    }
    .stackla-carousel-body {
      padding: 50px 0;
      flex-flow: row wrap;
    }
  }


  .three-cards {
    margin: 0;
    .InfoCard{
      flex: 1;
      margin: 0;

      .Link:after {
        height: 27px;
        width: 27px;
        font-size: 30px;
      }

      .info-card-block {
        height: 100%;
        justify-content: space-between;
        > a {
          h2 {
            font-size: 36px;
          }
        }
      }
      &:nth-child(2) {
        p {
          color: $black;
        }
        span {
          color: $black;
        }
        h2 {
          color: $blueText;
        }
        a {
          h2 {
            color: $blueText;
          }
        }
      }

      &:nth-child(3) {
        .Link:after {
          background-color: white;
          color: $buttonsColor;
        }
      }
    }
  }
}

@media screen and (max-width: $breakPoint-lg) {
  .About {
    .about-main {
    padding: 40px 25px;
    }
    .three-cards {
      flex-direction: column;
      margin-top: 20px;
      .InfoCard {
        max-width: 100%;
      }
    }
    .TextComponent {
      padding: 30px;
      width: calc(100% - 60px);
      .ordered-list-components {
        flex-flow: row wrap;
        .OrderedList {
          min-width: 270px;
        }
      }
    }
    .makeEasyFlexibleBlock {
      display: block;
    }
    .get-proccess {
      margin: 0 30px;
    }

    .question-part {
      margin: 0 30px;
      margin-bottom: 30px;
      .question-block {
        flex-direction: column-reverse;
      }
    }

    .about-midcomp {
      padding: 50px 25px;
      width: calc(100% - 50px);

      p {
        font-size: 16px;
        font-weight: 300;
        line-height: 26px;
      }
    }

  }
}

@media screen and (max-width: $breakPoint-sm) {
  .About {
    h1 {
      font-size: 28px !important;
    }
    .about-main {
      .description-about {
        font-size: 16px;
      }
      .question-block {
        .QuestionListCard {
          margin-bottom: 0;
          &:first-child {
            margin-top: 0;
          }
          .question-card {
            .text-block {
              padding-bottom: 0;
              border: none;
              .the-talent-mobility-p {
                font-size: 16px;
              }
              > div {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }

    .QuestionListCard .question-card .text-block p, .QuestionListCard .question-card .text-block div {
      & > p,
      & > h2,
      & > ul,
      & > strong {
        margin-left: -45%;
      }
    }
  }
}

//crosbrowser styles

.ie {
  #root {
    .About {
      > div {
        .question-part {
          .question-block {
            > div:first-child {
              max-width: calc(100% - 100px);
            }
          }
        }
      }
    }
    .question-block {
      justify-content: space-between;
      > div:first-child {
          max-width: calc(100% - 100px);
          margin-right: 65px;
          flex-shrink: 0;
        .QuestionListCard {
          margin-top: 0;
        }
        .text-block {
          max-width: calc(100% - 70px);
          flex-shrink: 0;
          flex-grow: 0;
        }
      }
    }
    .three-cards {
      .InfoCard {
        max-width: 100%;
        display: flex;
        .info-card-block {
          max-width: 100%;
          flex-shrink: 0;
        }
      }
    }
  }
}
