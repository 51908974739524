@import "~main.scss";

.back-icon {
    display: inline-flex;
    cursor: pointer;

    p {
        color: $black;
        font-size: 16px;
        font-weight: 300;
        margin-left: 8px;
    }
}

h1.section-title {
    margin-top: 30px;
    letter-spacing: -0.5px;
    line-height: 48px;

    @media screen and (max-width: $breakPoint-md) {
        font-size: 30px;
        line-height: 35px;
    }
}

.GetStartedContainer {
    padding: 85px 150px;

    .required-symbol {
        color: #ca001b;
    }

    .line {
        margin-top: 50px;
    }

    .submit-button {
        background-color: #ca001b;
        box-shadow: none;
        padding: 18px 33px;
        margin-top: 30px;
        color: #fff;
        font-size: 16px;
        font-weight: bold;
        border: 0;
        cursor: pointer;
        &:disabled {
            background-color: #D8D8D8;
        }
    }
    .section-title {
        margin: 40px 0 40px 0;
        font-size: 45px;
    }

    .CardSection {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
    }

    .get-started-intro-screen {
        h1 {
            font-size: 60px;
            font-weight: 900;
            letter-spacing: -0.68px;
            line-height: 60px;
            max-width: 1024px;

            @media screen and (max-width: 767px) {
                font-size: 30px;
                line-height: 32px;
                letter-spacing: -0.5px;
            }
            @media screen and (max-width: 1024px) {
                font-size: 35px;
                line-height: 30px;
                letter-spacing: -0.5px;
            }
        }

        .line {
            width: 90%;
            border: 1px solid #D8D8D8;
            float: left;
        }

        .top-content {
            display: flex;
            margin-top: 35px;

            .left-info {
                width: 65%;
                padding-right: 60px;

                @media screen and (max-width: 600px) {
                    float: left;
                    width: 100%;
                }

                p {
                    color: #212121;
                    font-size: 20px;
                    font-weight: 300;
                    line-height: 30px;
                    margin-bottom: 25px;
                }

                .general-info {
                    margin-top: 35px;
                    font-size: 16px;
                    font-weight: 300;
                    line-height: 26px;
                    width: 90%;
                }

                .buttons {
                    display: flex;

                    .submit-button {
                        float: left;
                        margin-top: 0;
                        height: 20px;
                        width: 70px;
                    }
                }
            }

            .right-info {
                width: 32%;

                @media screen and (max-width: 600px) {
                    float: left;
                    width: 100%;
                    margin-top: 30px;
                    padding-bottom: 30px;
                }

                div {
                    span.text {
                        color: #CC0099;
                        margin-left: 10px;
                        font-weight: bold;
                        line-height: 20px;
                        font-size: 16px;
                    }
                }

                p {
                    color: #888B8D;
                    margin-top: 20px;
                    font-size: 16px;
                    font-weight: 300;
                    line-height: 20px;
                    margin-left: 26px;
                    width: 80%;
                    @media screen and (max-width: 600px) {
                        margin-left: 0;
                        width: 100%;
                    }
                    @media screen and (max-width: 1024px) {
                        margin-left: 0;
                    }
                }
                span.Icon {
                    position: relative;
                    svg {
                        position: absolute;
                        width: 26px;
                        height: 26px;
                        left: -5px;
                        top: -5px;
                    }
                }
                span.text {
                    margin-left: 26px !important;
                }
            }
        }
        .contactmobility-wrapper {
            float: left;
            .contact-mobility-container-layout {
                p {
                    &.main-text {
                        @media screen and (max-width: 600px) {
                            width: 70%;
                            line-height: 20px;
                        }
                    }
                }
                .details-section {
                    .horizontal {
                        width: 100%;
                    }
                }
            }
            .details-section {
                .img-information {
                    @media screen and (max-width: 600px) {
                        position: relative;
                        left: 0;
                        margin: 0;
                    }
                }
            }
        }

    }
}

@media screen and (max-width: $breakPoint-lg) {
    .GetStartedContainer {
        padding: 85px 30px;

        .section-title {
            font-size: 25px;
        }
    }
}

@media screen and (max-width: 768px) {
    .GetStartedContainer {
        .CardSection {
            flex-direction: column;

            .optionSelectionCard {
                margin-bottom: 20px;
            }
        }
        .contact-mobility-container-layout {
            .details-section {
                .horizontal {
                    .mail-information {
                        margin-left: 0px !important;
                    } 
                    .contact-by-phone {
                        margin: 0 0 14px 0;
                    }   
                }
            }
        }
    }
}

