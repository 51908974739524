//Global colors
$white: #fff;
$black: #212121;
$borderColor: #e6e6e6;
$mainBackgroundColor: #f4f4f4;
$blueText: #000099;
$textColor: #242424;
$buttonsColor: #ca001b;
$subtitleColor: #cc0099;
$grey: #6e7174;
$darkGrey: #888b8d;
$headerGrow: #9C9C9C;
$disabledColor: #D8D8D8;
$placeHolderColor: #63666A;
$tableSelected: rgba(0, 176, 255, 0.14);
$popupBackground: rgba(255, 255, 255, 0.85);
$purple: #6633CC;

// Global Font sizes
$smallSize: 14px;
$mediumSize: 16px;
$largeSize: 18px;
$bigTitleSize: 36px;
$subTitleSize: 20px;
$mediumTitleSize: 32px;
$pageTitleSize: 44px;

//shadows
$material-header-shadow: 0 3px 5px -1px rgba(0,0,0,.2), 0 6px 10px 0 rgba(0,0,0,.14), 0 1px 18px 0 rgba(0,0,0,.12);


// <!-- does not use
// <!-- does not use
$backgroundColor: #F4F4F4;
$text-color: #6c6f73;
$hard-text-color: #2D3238;
// --!>

$wat-asphalt: #1d4b5a;
$midnight-blue: #4a6f7b;

$night-rider: #2d2d2d;
$trout: #4c5658;

$gainsboro: #dbdbdb;
$whisper: #e6e6e6;

$cinnabar: #E94444;
$roman: #EC6060;

$casablanca: #F4B15A;
$chardonnay: #FDC67A;

$white-smoke: #F5F4F4;
$snow: #F9F9F9;
$white: #FFFFFF;

$light-sea-green: #30AFA2;
$puerto-rico: #59BFB5;

$grey: #848282;
$silver: #BEBEBE;

//Mixins and functions

@function filter($property, $prefix ) {
  @return ('-' + $prefix + '-' + $property);
}

@mixin prefixer($property, $value){
  #{filter($property, webkit)} : $value;
}

// Screen breakpoints
$small: 600px;
$medium: 960px;
$large: 1280px;
$extra-large: 1920px;


$breakPoint-sm: 600px;
$breakPoint-md: 960px;
$breakPoint-lg: 1280px;
$breakPoint-xl: 1920px;

@mixin paddingVertical($size) {
    padding-top: $size;
    padding-bottom: $size;
}

@mixin paddingHorizontal($size) {
    padding-left: $size;
    padding-right: $size;
}
