@import '~main.scss';

$lg-size: 450px;
$sm-size: 220px;

.main-top-container {
    position: relative;
    min-height: $lg-size;

    .background-opacity {
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.7);
    }

    .main-top-content {
        position: relative;
        flex-wrap: wrap;
        flex-direction: row;
    }

    .main-top-section {
        min-width: 50%;
        flex: 1;

        &:first-child {
            height: $lg-size;
        }
    }

    .top-container-background {
        position: absolute;
        top: 0;
        height: $lg-size;
        width: 100%;
        background-size: cover;
        background-position: center center;
    }

    .top-container-footer {
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        display: table;
    }

    .main-top-footer-offset {
        position: relative;
        transform: translateY(50%);
    }

    .main-top-footer-back-button {
        cursor: pointer;
    }
}

@media screen and (max-width: $breakPoint-md) {
    .main-top-container {
        .main-top-content {
            flex-direction: column;
        }

        .main-top-footer-offset {
            transform: translateY(0);
        }

        .main-top-section {
            margin-bottom: 50px;
        }
    }
}

@media screen and (max-width: $breakPoint-sm) {
    .main-top-container {
        min-height: $sm-size;

        .top-container-background {
            height: $sm-size;
        }

        .main-top-section {
            flex: 1 1 auto;
        }

        .main-top-section:first-child {
            height: $sm-size;
        }
    }
}
