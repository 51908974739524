@import '~main.scss';

.footer {
    background-color: #000099;
    padding: 36px 150px 33px;
    color: #fff;
    display: flex;
    align-items: flex-end;
    @include prefixer(flex-align, flex-end);

    .content {
        margin-left: 28px;

        h4, p, .link span {
            color: #fff;
        }

        h4 {
            line-height: 42px;
            letter-spacing: -0.5px;
        }

        p {
            font-size: 16px;
            font-weight: 300;
            line-height: 26px;
        }

        .link {
            margin-top: 13px;
        }
    }

    @media screen and (max-width: $breakPoint-md) {
        padding: 25px 57px  31px;
        flex-direction: column;
        @include prefixer(flex-direction, column);

        .content {
            margin-left: 0;
            margin-top: 8px;

            h4 {
                font-size: 20px;
            }
        }
    }
}
