.mobile-table {
    .mobile-table-row {
        .mobile-table-row-header {
            background-color: #000099;
            padding: 10px 25px 10px 12px;
            p {
                font-size: 18px;
                line-height: 20px;
                font-weight: 900;
                color: #fff;
            }
        }
        .mobile-row {
            padding: 18px 12px;
            &:nth-child(2n + 1) {
                background-color: #c8d7ff;
            }
            .row-title {
                font-weight: 900;
                font-size: 16px;
                line-height: 18px;
                color: #212121;
                margin-bottom: 4px;
            }
            .row-content {
                font-weight: 300;
                font-size: 16px;
                line-height: 20px;
                color: #212121;
            }
        }
    }
}
