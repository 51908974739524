@import '~main.scss';

.my-move-overview {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    .left-content {
        flex: 1 0 auto;
        min-width: 205px;
        margin-right: 65px;
    }

    img {
        width: auto;
        height: 100%;
    }

    .right-content {
        .body2 {
            margin-bottom: 50px;
        }

        .icon-with-title {
            margin: 10px;
            .Icon * {
                fill: white;
            }
        }
    }
}

@media (max-width: $medium) {
    .my-move-overview {
        flex-direction: column;
        align-items: center;

        .left-content {
            margin-right: 0;
            margin-bottom: 25px;
        }
    }
}

@media (max-width: $small) {
    .my-move-overview {
        height: 80vh;
        margin: 20px 0;
        overflow-y: scroll;
        box-sizing: border-box;
        .managements {
            flex-wrap: wrap;
        }

        .left-content img{
            height: 250px;
        }

        .right-content {
            .body2 {
                margin-bottom: 25px;
            }
        }
    }
}
