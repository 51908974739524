@import "~main.scss";

* {
    margin: 0;
    padding: 0;
    color: #212121;
    font-family: "J&J Circular";
    list-style: none;
    text-decoration: none;
}

html,body, #root {
    width: 100%;
    height: 100%;
}

body {
    background-color: $backgroundColor;
}

.defaultBg {
    background-color: $backgroundColor !important;
}

svg {
    stroke-width: 2px;
}

#pending {
    position: absolute;
    top: 77px;
    z-index: 10;
    height: 100%;
    left: 0;
    right: 0;
    background: $white;
    padding-top: 50px;
    display: none;

    .Spinner {
        margin: 0 auto;
    }

    &.show {
        display: block;
    }
}

.slick-list {
    .slick-track {
        .slick-slide {
            > div {
                display: flex;
                justify-content: center;
            }
            .truncate{
                font-size: $mediumSize;
            }
        }
    }
}

.slick-slider {
    max-width: 100%;
    .slick-disabled {
        opacity: 0;
        pointer-events: none;
    }
    .slick-arrow {
        &.slick-prev, &.slick-next {
            top: calc(50% - 25px);
            &:before {
                display: none;
            }
        }
    }
    .slick-prev {
        left: -50px;
    }
    .slick-next {
        right: -20px;
    }
}

@media screen and (min-width: $breakPoint-xl){
    .slick-slider {
        .slick-prev {
            left: -10px;
        }
        .slick-next {
            right: 20px;
        }
    }
}

#root {
    > div {
        > article {
            padding-bottom: 61px;
            min-height: 100vh;
            max-width: 1280px;
            position: relative;
            margin: 0 auto;
            background-color: #fff;
            box-sizing: border-box;

            @media screen and (max-width: $breakPoint-md) {
                padding-bottom: 106px;
            }

            @media screen and (max-width: $breakPoint-sm) {
                padding-bottom: 186px;
            }
        }
    }
}

#modals-root {
  position: absolute;
  left: 0;
  top: 0
}

*::-webkit-scrollbar {
    width: 12px;
}

*::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
}

*::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
}

//Global styles for creating flexible components

.withStretch {
    display: flex;
    flex-grow: 1;
}

.flexible {
    display: flex;
    @include prefixer(flexbox, flex);
    &.horizontal {
        flex-direction: row;
        @include prefixer(flex-direction, row);
    }
    &.vertical {
        flex-direction: column;
        @include prefixer(flex-direction, column);
    }
    &.jStart {
      justify-content: flex-start;
        @include prefixer(flex-pack, flex-start);
    }
    &.jEnd {
        justify-content: flex-end;
        @include prefixer(flex-pack, flex-end);
    }
    &.jCenter {
        justify-content: center;
        @include prefixer(flex-pack, center);
    }
    &.jAround {
        justify-content: space-around;
        @include prefixer(flex-pack, space-around);
    }
    &.jBetween {
        justify-content: space-between;
        @include prefixer(flex-pack, space-between);
    }
    &.aStart {
        align-items: flex-start;
        @include prefixer(flex-align, flex-start);
    }
    &.aEnd {
        align-items: flex-end;
        @include prefixer(flex-align, flex-end);
    }
    &.aCenter {
        align-items: center;
        @include prefixer(flex-align, center);
    }
    &.wrap {
        flex-wrap: wrap;
        @include prefixer(flex-wrap, wrap);
    }
    &.noWrap {
        flex-wrap: nowrap;
        @include prefixer(flex-wrap, nowrap);
    }
    &.grow {
        flex-grow: 1;
        @include prefixer(flex-positive, 1);
    }
    &.noGrow {
        flex-grow: 0;
        @include prefixer(flex-positive, 0);
    }
    &.shrink {
        flex-shrink: 1;
        @include prefixer(flex-negative, 1);
    }
    &.noShrink {
        flex-shrink: 0;
        @include prefixer(flex-negative, 0);
    }

    .staticSize {
        flex: 0 0 auto;
    }

    .resizeSize {
        flex: 1 1 auto;
    }
}

.flex-grow-1 {
    flex-grow: 1 !important;
}
.flex-shrink-1 {
    flex-shrink: 1 !important;
}

.height100vh {
    min-height: 100vh;
}

.singleLine {
    white-space: nowrap;
}

.truncate-lines-styles {
    display: -webkit-box;
    word-break: break-word;
}

.truncate {
    text-overflow: ellipsis;
    overflow: hidden;
    &.twoLines {
        -webkit-line-clamp: 2;
        @extend .truncate-lines-styles;
        -webkit-box-orient: vertical;
    }
    &.threeLines {
        -webkit-line-clamp: 3;
        @extend .truncate-lines-styles;
        -webkit-box-orient: vertical;
    }
    &.fourLines {
        -webkit-line-clamp: 4;
        @extend .truncate-lines-styles;
        -webkit-box-orient: vertical;
    }
    &.fiveLines {
        -webkit-line-clamp: 5;
        @extend .truncate-lines-styles;
        -webkit-box-orient: vertical;
    }
}

iframe {
    border: none;
    width: 100%;
}

.Spinner {
    width: 70px;
    height: 70px;
    background-color: #fff;
    border: 3px solid #e6e6e6;
    border-radius: 100%;
    animation: spinnerAnimate 1s infinite;
    border-top-color:  #000099;
    margin: 3rem;
    align-self: center;
}

.PopupSpinner {
    position: fixed;
    left: 50%;
    top: 50%;
    width: 100%;
    height: 100%;
    z-index: 9999;
}

.header-text {
  color: $blueText;
  font-size: $bigTitleSize;
  margin-bottom: 40px;
}

@keyframes spinnerAnimate {
    from { transform: rotate(0) }
    to { transform: rotate(360deg) }
}

@-webkit-keyframes fadeInDown {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, -90%, 0);
        transform: translate3d(0, -90%, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.hide {
    display: none;
}

.animated {
    -webkit-animation-duration: .6s;
    animation-duration: .6s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.fadeInDown {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
}

//crosbrowser styles

.ie {
    #root {
        .Header {
            &~section {
                min-height: calc(100vh - 281px);
            }
        }
        .slick-slider {
            width: 100%;
        }
    }
}

.line-separated-list {
    display: flex;
    flex-direction: row;
    @media screen and (max-width: 1280px) {
        flex-direction: column;
    }
    &-item {
        $list-spacing: 15px;
        @media screen and (min-width: 1280px) {
            margin-right: $list-spacing;
            padding-right: $list-spacing;
            border-right: 1px solid $borderColor;

            &:last-child {
                margin-right: 0;
                padding-right: 0;
                border-right: none;
            }
        }

        @media screen and (max-width: 1280px) {
            margin-bottom: $list-spacing;
            &:last-child {
                margin-bottom: 0;
            }
        }

    }
}

h1 {
    color: $blueText;
    font-size: 44px;
    font-weight: 900;
    line-height: 48px;
    &.inverse {
        color: white;
        background-color: $blueText;
    }
}

h2 {
    color: $blueText;
    font-size: 36px;
    font-weight: 900;
    line-height: 42px;
    letter-spacing: -0.5px;
    &.inverse {
        color: white;
        background-color: $blueText;
    }
}

h3 {
    color: $blueText;
    font-size: 30px;
    font-weight: 900;
    line-height: 34px;
    &.inverse {
        color: white;
        background-color: $blueText;
    }
}

h4 {
    color: $blueText;
    font-size: 20px;
    font-weight: 900;
    line-height: 26px;
    &.inverse {
        color: white;
        background-color: $blueText;
    }
}

h5 {
    color: black;
    font-size: 16px;
    font-weight: 900;
    line-height: 22px;
    &.inverse {
        color: white;
        background-color: $blueText;
    }
}

.body1 {
    color: $black;
    font-size: 44px;
    line-height: 48px;
    font-weight: 900;
    letter-spacing: -0.5px;
    &.inverse {
        color: white;
        background-color: $blueText;
    }
}

.body2 {
    color: $black;
    font-size: 20px;
    line-height: 30px;
    font-weight: 300;
    &.inverse {
        color: white;
        background-color: $blueText;
    }
}

$spaces: (0, 10, 15, 20, 25, 30, 40, 50, 60, 70, 80, auto);
$sides: (top, bottom, left, right, all);

@each $space in $spaces {
    @each $side in $sides {
        @if $space == 'auto'{
            @if $side == 'all' {
                .m-#{$space} {
                    margin: #{$space} !important;
                }

                .p-#{$space} {
                    padding: #{$space} !important;
                }
            } @else {
                .m#{$side}-#{$space} {
                    margin-#{$side}: #{$space} !important;
                }

                .p#{$side}-#{$space} {
                    padding-#{$side}: #{$space} !important;
                }
            }
        } @else {
            @if $side == 'all' {
                .m-#{$space} {
                    margin: #{$space}px !important;
                }

                .p-#{$space} {
                    padding: #{$space}px !important;
                }
            } @else {
                .m#{$side}-#{$space} {
                    margin-#{$side}: #{$space}px !important;
                }

                .p#{$side}-#{$space} {
                    padding-#{$side}: #{$space}px !important;
                }
            }
        }
    }
}

@media screen and (max-width: $medium) {
    h1 {
        font-size: 30px;
    }

    h2 {
        font-size: 22px;
    }

    h3 {
        font-size: 20px;
    }

    h4 {
        color: $black;
        font-size: $mediumSize;
    }
    .body1 {
        font-size: 30px;
        line-height: 34px;
    }
    .body2 {
        font-size: 16px;
        line-height: 26px;
    }
}

@media screen and (min-width: 1280px){
    .Header {
        > section {
            width: 1180px;
        }
    }
}
