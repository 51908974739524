@import "~main.scss";

.InitiateMoveDetailContainer {
    .success-container {
        max-width: calc(100% - 180px);
        margin: 90px 120px;
        .success-completion-page {
            width: 100%;
            h2 {
                color: #000099;
                font-size: 20px;
                font-weight: 900;
                line-height: 26px;
                margin-top: 10px;
                span {
                    margin-top: 15px;
                }
            }
            .line {
                display: block;
                box-sizing: border-box;
                height: 2px;
                width: 100%;
                border: 1px solid #e3e3e3;
                margin: 55px 0 0 0;
            }
            .top {
                position: relative;
                h1 {
                    color: #000099;
                    font-size: 75px;
                    line-height: 95px;
                    width: 100%;
                    span {
                        color: #cc0099;
                    }
                }
                img {
                    position: relative;
                    top: 15px;
                    margin-left: 20px;
                    width: 55px;
                }
            }
            .middle {
                display: flex;
                width: 100%;
                margin-top: 32px;
                margin-bottom: 70px;
            }
            .left-middle {
                width: 60%;
                padding-right: 60px;
                p {
                    font-size: 20px;
                    line-height: 30px;
                    color: #212121;
                }
            }
            .right-middle {
                width: 28%;
                display: flex;
                div {
                    padding-left: 10px;
                    p.title {
                        color: #cc0099;
                    }
                    p.content {
                        font-size: 16px;
                        color: #888b8d;
                        margin-top: 15px;
                    }
                }
            }
            .slider {
                width: 100%;
                .ColumnComponent {
                    padding: 0;
                    .column-component-body {
                        padding-top: 40px;
                        .card {
                            max-width: 338px;
                            height: 413px;
                        }
                        .estimate {
                            bottom: initial;
                            position: absolute;
                            padding-top: 0px;
                            padding-right: 10px;
                            hr {
                                text-align: left;
                            }
                        }
                        h3 {
                            font-size: 16px;
                            color: #212121;
                        }
                        .slick-list {
                            .slick-track {
                                .slick-slide>div {
                                    justify-content: flex-start;
                                }
                                .slick-slide {
                                    .truncate {
                                        height: 126px;
                                    }
                                }
                            }
                        }
                        .slick-arrow {
                            z-index: 9;
                            &.slick-next {
                                right: 0;
                            }
                            button {
                                background-color: #ca001b;
                                svg {
                                    path {
                                        stroke: #fff;
                                    }
                                }
                            }
                        }
                        .slick-slider {
                            .slick-arrow {
                                &.slick-next {
                                    button {
                                        margin-left: -8px;
                                        @media screen and (max-width: 1124px) {
                                            margin-left: 0;
                                        }
                                    }
                                }
                                &.slick-prev {
                                    button {
                                        margin-left: 25px;
                                        @media screen and (max-width: 1124px) {
                                            margin-left: 0;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .submitButton {
                background-color: #ca001b;
                box-shadow: none;
                padding: 13px 30px;
                margin-top: 30px;
                color: #fff;
                font-size: 16px;
                font-weight: bold;
                border: 0;
                float: right;
                cursor: pointer;
                margin-bottom: 50px;
            }
        }
    }
}

@media screen and (max-width: 800px) {
    .InitiateMoveDetailContainer {
        .ComponentsContainer {
            .line {
                width: 100%;
            }
        }
        .success-container {
            .success-completion-page {
                h2 {
                    line-height: 30px;
                    font-size: 24px;
                }
                .slider {
                    .ColumnComponent {
                        .column-component-body {
                            .slick-list {
                                .slick-track {
                                    .slick-slide>div {
                                        justify-content: center;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: $breakPoint-lg) {
    .InitiateMoveDetailContainer {
        .success-container {
            .success-completion-page {
                .slider {
                    .ColumnComponent {
                        .column-component-body {
                            .slick-list {
                                .slick-track {
                                    .slick-slide>div {
                                        justify-content: center;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: $breakPoint-md) {
    .InitiateMoveDetailContainer {
        .success-container {
            .success-completion-page {
                .top {
                    h1 {
                        font-size: 42px;
                        width: 100%;
                        line-height: 48px;
                    }
                    img {
                        top: 5px;
                        width: 27px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: $breakPoint-sm) {
    .InitiateMoveDetailContainer {
        .success-container {
            max-width: calc(100% - 30px);
            margin: 30px;
            .success-completion-page {
                .middle {
                    .left-middle {
                        width: 100%;
                        margin-bottom: 20px;
                        padding: 0;
                    }
                    .right-middle {
                        width: 100%;
                    }
                }
                .top {
                    h1 {
                        font-size: 28px;
                        width: 100%;
                        line-height: 30px;
                    }
                    img {
                        top: 5px;
                        width: 27px;
                    }
                }
                .slider {
                    .ColumnComponent {
                        .column-component-body {
                            .slick-slider {
                                .slick-prev {
                                    left: -25px;
                                }
                                .slick-arrow {
                                    button {
                                        min-width: 38px;
                                        min-height: 38px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


@media only screen and (min-device-width : 768px) and (max-device-width : 1024px)  {
    .InitiateMoveDetailContainer {
        .success-container {
            .success-completion-page {
                .slider {
                    .ColumnComponent {
                        .column-component-body {
                            .slick-slider {
                                .slick-arrow {
                                    &.slick-next {
                                        button {
                                            margin-left: -14px;
                                        }
                                    }
                                    &.slick-prev {
                                        button {
                                            margin-left: 35px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            } 
        }
    }
}

@media screen and (max-width: 600px) {
    .InitiateMoveDetailContainer {
        .success-container {
            .success-completion-page {
                .slider {
                    .ColumnComponent {
                        .column-component-body {
                            .slick-slider {
                                .slick-arrow {
                                    &.slick-next {
                                        button {
                                            margin-left: 2px;
                                        }
                                    }
                                    &.slick-prev {
                                        button {
                                            margin-left: 5px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            } 
        }
    }
}