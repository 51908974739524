@import "~main.scss";

.QuestionListCard {
  margin-bottom: 2rem;
  &:first-child {
    margin-top: 2rem;
  }
  .question-card {
    .facts{
      margin-bottom: 1rem;
      .fact{
        .fact-label{
          color: $placeHolderColor;
          font-size: 14px;
          font-weight: 100;
          line-height: 20px;
          white-space: nowrap;
          @media screen and (max-width: $small) {
            white-space: normal;
          }
        }
        .fact-value{
          font-size: 20px;
          font-weight: 900;
          letter-spacing: -0.5px;
          line-height: 42px;
          white-space: nowrap;
          @media screen and (max-width: $small) {
            white-space: normal;
          }
        }
      }
    }
    .icon-block {
      min-width: 70px;
    }
    .text-block {
      margin-left: 2rem;
      border-bottom: 2px solid $borderColor;
      padding-bottom: 2rem;
      ul{
        list-style: none;
        display: table;
      }
      ul > li::before{
          content: "•";
          color: $buttonsColor;
          font-size: $smallSize;
          font-weight: 300;
          line-height: 26px;
          display: table-cell;
          text-align: right;
          padding-right: 10px;
          padding-bottom: 10px;
      }

      ul > li {
          display: table-row;
      }

      ol {
        list-style: none;
        counter-reset: item;
      }

      ol > li::before{
        content: counter(item);
        font-size: 14px;
        font-weight: 900;
        line-height: 16px;
        color: $buttonsColor;
        padding-right: 10px;
      }

      ol > li{
        counter-increment: item;
      }

      h3 {
        font-size: 18px;
        margin-bottom: 1rem;
        font-weight: bold;
      }
      p, div {
        font-size: 16px;
        margin-bottom: 1.5rem;
        font-weight: 100;
        line-height: 25px;
      }
      h4 {
        margin-top: 1rem;
        margin-bottom: 1rem;
        span {
          margin-right: 15px;
          border-right: 1px solid $borderColor;
          padding-right: 15px;
          font-weight: bold;
          display: flex;
          align-items: center;
          cursor: pointer;
          .Icon {
            min-width: 21px;
            max-width: 21px;
            height: 21px;
            background-color: $buttonsColor;
            border-radius: 100%;
            padding: 0;
            align-items: center;
            justify-content: center;
            margin-left: 1rem;
            z-index: 5;
            svg {
              fill: #fff;
              transform: rotate(-90deg);
            }
          }
          &:last-child {
            border: none;
          }
        }
      }
    }
  }
}
