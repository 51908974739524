.domestic-package-selection-container {
    .domestic-scenario-wrapper {
        .CardSection {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            height: 230px;
            margin-top: 50px;
        }
        .section-title {
            color: #000099;
            font-size: 42px;
            font-weight: 900;
            letter-spacing: -0.5px;
            line-height: 48px;
        }
        .reasons {
            .reason {
                padding: 10px;
                .reason-info {
                    text-align: left;
                    padding: 35px;
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .domestic-package-selection-container {
        .domestic-scenario-wrapper {
            .section-title {
                margin: 20px 0 30px 0;
            }

            .CardSection {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                height: 230px;
                margin-top: 20px;
                margin-bottom: 50px;
                .optionSelectionCard {
                    margin-top: 20px;
                }
            }
        }
    }
}

_:-ms-fullscreen,
:root .optionSelectionCard .icon-block img {
    width: 35px;
}
