@import '~main.scss';

.TaxMilestonesHeader {
    hr {
        height: 2px;
        border: none;
        background-color: $disabledColor;
    }

    .move-info {
        display: flex;
        justify-content: space-between;
        margin-top: 53px;
        align-items: center;
        margin-bottom: 24px;

        @media screen and (max-width: $breakPoint-md) {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    .move-info-country-container {
        display: flex;
        align-items: center;

        img {
            margin-right: 13px;
            width: 49px;
            height: 49px;
        }

        h4 {
            color: $placeHolderColor;
            font-size: 14px;
            font-weight: 300;
            line-height: 18px;
        }

        @media screen and (max-width: $breakPoint-md) {
            img {
                margin-right: 10px;
            }

            margin-bottom: 26px;
        }
    }

    .move-info-country-date {
        margin-top: 2px;
        display: flex;
        align-items: center;
        color: #000000;
        line-height: 25px;
        font-size: 20px;

        strong {
            font-weight: 900;
        }

        p {
            margin-left: 10px;
            font-weight: 300;
        }

        @media screen and (max-width: $breakPoint-md) {
            flex-direction: column;
            align-items: flex-start;

            strong {
                margin-top: 3px;
            }

            p {
                margin-left: 0;
                margin-top: 3px;
            }
        }
    }
}

.text {
    color: $black;
    font-size: 20px;
    font-weight: 300;
    line-height: 30px;
    margin-top: 25px;

    @media screen and (max-width: $breakPoint-md) {
        font-size: 16px;
    }
}


