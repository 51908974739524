@import "~main.scss";

.Uploader {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .uploader-button {
    position: relative;
    height: 42px;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 15px;
    .title {
      padding: .7rem 1.5rem;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      font-weight: 400;
      border: 1px solid $buttonsColor;
      border-radius: 0;
      background-color: $white;
      color: $buttonsColor;
      font-size: $smallSize;
      z-index: 0;
      position: absolute;
      top: 0;
      left: 0;
    }
    input {
      max-width: 155px;
      height: 40px;
      z-index: 1;
      opacity: 0;
      cursor: pointer;
    }
  }
  .Spinner{
    width:20px;
    height: 20px;
    margin:5px;
  }
}
