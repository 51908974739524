@import "~main.scss";

.MoveDetailContainer {
    display: flex;
    flex-direction: column;

    .StepperContainer {
        display: flex;
        width: 100%;

        .TableRowContainer {
            display: flex;
            width: 100%;
            justify-content: center;

            .RowContainer {
                display: flex;
                align-items: center;
                margin: 0 0 13px 0;
                font-size: 13px;
                height: 46px;

                &.active {
                    // background-color: rgba(247, 247, 247, 0.966);
                    //.step-description{
                    //color: rgb(70, 68, 68);
                    // }
                }
                .step-descriptionContainer {
                    display: none;
                }
                .active {
                    margin: 0 2px 0 2px;
                    box-sizing: border-box;
                    height: 16px;
                    width: 16px;
                    border: 3px solid #ca001b;
                    border-radius: 50%;
                }
                .complete {
                    margin: 0 2px 0 2px;
                    width: 16.19px;
                    height: 16.19px;
                }
                .inactive {
                    margin: 0 2px 0 2px;
                    box-sizing: border-box;
                    height: 16px;
                    width: 16px;
                    border: 3px solid #d8d8d8;
                    border-radius: 50%;
                }
            }
        }
    }

    .ComponentsContainer {
        display: flex;
        margin: 44px 33px 0 33px;
        .line {
            box-sizing: border-box;
            height: 2px;
            width: 306px;
            border: 1px solid #e3e3e3;
        }
        .requestOverViewContainer {
            .textHeaderSection {
                margin: 0 0 45px 0;

                .user-name {
                    font-size: 20px;
                    font-weight: bold;
                    color: #cc0099;
                    margin-bottom: 10px;
                }
                .text-header {
                    max-width: 680px;
                    font-size: 30px;
                    font-weight: 900;
                    color: #000099;
                }
            }

            .employeeDetailsSection {
                display: flex;
                flex-direction: column;

                section {
                    margin: 0 0 30px 0;

                    .move-details-titles {
                        height: 20px;
                        width: 268px;
                        color: #63666a;
                        font-size: 14px;
                        font-weight: 300;
                        line-height: 20px;
                    }
                    .move-details-heading {
                        height: 42px;
                        width: 268px;
                        color: #212121;
                        font-size: 20px;
                        font-weight: 900;
                        letter-spacing: -0.5px;
                        line-height: 25px;
                    }
                    .move-details-sub-heading {
                        height: 24px;
                        width: 268px;
                        color: #212121;
                        font-size: 14px;
                        font-weight: 300;
                        line-height: 24px;
                    }
                    //NEW HIRE TAG
                    .buttonSection {
                        .newHire {
                            height: 22px;
                            width: 81px;
                            background-color: #000099;
                            float: left;

                            .buttonLabel {
                                height: 15px;
                                width: 57px;
                                color: #ffffff;
                                font-family: "J&J Circular";
                                font-size: 12px;
                                font-weight: bold;
                                letter-spacing: -0.3px;
                                line-height: 15px;
                                text-align: center;
                            }
                        }
                    }
                }
            }

            .line {
                display: none;
            }

            .buttonSectionGet {
                width: 100%;
                float: left;
                margin: 0 0 30px 0;

                .getStarted {
                    float: right;
                    height: 43.55px;
                    width: 100%;
                    background-color: #ca001b;
                    color: rgb(255, 255, 255);
                    float: right;
                    cursor: pointer;
                    &.disabled{
                        background-color: rgb(190, 188, 188);
                    }

                    .buttonLabel {
                        height: 20px;
                        width: 144px;
                        color: #ffffff;
                        font-size: 16px;
                        font-weight: bold;
                        line-height: 20px;
                        text-align: center;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .MoveDetailContainer {
        display: flex;
        height: -webkit-fill-available;

        .StepperContainer {
            display: flex;
            width: 100%;
            // padding: 37px 0 40px 15px;

            .TableRowContainer {
                display: flex;
                width: 100%;
                justify-content: center;

                .RowContainer {
                    display: flex;
                    align-items: center;
                    margin: 0 0 13px 0;
                    font-size: 13px;
                    height: 46px;

                    &.active {
                        // background-color: rgba(247, 247, 247, 0.966);
                        // .step-description{
                        //     color: rgb(70, 68, 68);
                        // }
                    }

                    .step-descriptionContainer {
                        display: none;
                    }
                    .active {
                        margin: 0 4px 0 4px;
                        box-sizing: border-box;
                        height: 16px;
                        width: 16px;
                        border: 3px solid #ca001b;
                        border-radius: 50%;
                    }
                    .complete {
                        margin: 0 4px 0 4px;
                        width: 16.19px;
                        height: 16.19px;
                    }
                    .inactive {
                        margin: 0 4px 0 4px;
                        box-sizing: border-box;
                        height: 16px;
                        width: 16px;
                        border: 3px solid #d8d8d8;
                        border-radius: 50%;
                    }
                }
            }
        }

        .ComponentsContainer {
            display: flex;
            margin: 44px 55px 0 55px;

            &.JobDetails,
            &.Finance {
                margin: 44px 55px 0 99px;
            }

            .line {
                box-sizing: border-box;
                height: 2px;
                width: 100%;
                border: 1px solid #e3e3e3;
            }

            .requestOverViewContainer {
                .textHeaderSection {
                    margin: 0 0 45px 0;

                    .user-name {
                        font-size: 20px;
                        font-weight: bold;
                        color: #cc0099;
                        margin-bottom: 10px;
                    }
                    .text-header {
                        max-width: 680px;
                        font-size: 30px;
                        font-weight: 900;
                        color: #000099;
                    }
                }
                .line {
                    display: none;
                }

                .employeeDetailsSection {
                    display: flex;
                }

                .buttonSectionGet {
                    width: 100%;
                    float: right;

                    .getStarted {
                        height: 43.55px;
                        width: 202px;
                        background-color: #ca001b;
                        color: rgb(255, 255, 255);
                        cursor: pointer;

                        .buttonLabel {
                            height: 20px;
                            width: 144px;
                            color: #ffffff;
                            font-size: 16px;
                            font-weight: bold;
                            line-height: 20px;
                            text-align: center;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1024px) {
    .MoveDetailContainer {
        display: flex;
        flex-direction: row;
        // height: 670px;

        .StepperContainer {
            display: flex;
            width: 220px;
            height: 100%;
            padding: 37px 0 40px 15px;
            // border-right: .5px solid rgb(219, 218, 218);

            .TableRowContainer {
                display: block;
                width: 210px;

                .RowContainer {
                    display: flex;
                    align-items: center;
                    margin: 0 0 13px 0;
                    font-size: 13px;
                    height: 46px;
                    width: 210px;

                    &.active {
                        background-color: rgba(247, 247, 247, 0.966);

                        .step-description {
                            color: rgb(70, 68, 68);
                        }
                        p.step-description {
                            color: #212121;
                        }
                    }
                    .step-descriptionContainer {
                        display: flex;

                        .step-description {
                            color: grey;
                        }
                    }
                    .active {
                        margin: 0 10px 0 25px;
                        box-sizing: border-box;
                        height: 16px;
                        width: 16px;
                        border: 3px solid #ca001b;
                        border-radius: 50%;
                    }
                    .complete {
                        margin: 0 10px 0 25px;
                        width: 16.19px;
                        height: 16.19px;
                    }
                    .inactive {
                        margin: 0 10px 0 25px;
                        box-sizing: border-box;
                        height: 16px;
                        width: 16px;
                        border: 3px solid #d8d8d8;
                        border-radius: 50%;
                    }
                }
            }
        }

        .verticalLine {
            size: 500;
            width: 1px;
            background: #d8d8d8;
            border: 0;
        }
        .ComponentsContainer {
            display: flex;
            margin: 44px 0 100px 55px;

            &.JobDetails,
            &.Finance {
                margin: 44px 200px 0 99px;
            }

            .requestOverViewContainer {
                .textHeaderSection {
                    margin: 0 0 45px 0;

                    .user-name {
                        font-size: 20px;
                        font-weight: bold;
                        color: #cc0099;
                        margin-bottom: 10px;
                    }
                    .text-header {
                        height: 96px;
                        width: 672px;
                        color: #000099;
                        font-family: "J&J Circular";
                        font-size: 44px;
                        font-weight: 900;
                        letter-spacing: -0.61px;
                        line-height: 48px;
                    }
                }

                .employeeDetailsSection {
                    display: flex;
                    flex-direction: column;
                    flex-wrap: wrap;
                    height: 300px;

                    section {
                        width: 50%;
                        height: 83px;

                        .move-details-titles {
                            height: 20px;
                            width: 268px;
                            color: #63666a;
                            font-size: 14px;
                            font-weight: 300;
                            line-height: 20px;
                        }
                        .move-details-heading {
                            height: 42px;
                            width: 268px;
                            color: #212121;
                            font-size: 20px;
                            font-weight: 900;
                            letter-spacing: -0.5px;
                            line-height: 25px;
                        }
                        .move-details-sub-heading {
                            height: 24px;
                            width: 268px;
                            color: #212121;
                            font-size: 14px;
                            font-weight: 300;
                            line-height: 24px;
                        }
                        //NEW HIRE TAG
                        .buttonSection {
                            .newHire {
                                height: 22px;
                                width: 81px;
                                background-color: #000099;
                                float: left;

                                .buttonLabel {
                                    height: 15px;
                                    width: 57px;
                                    color: #ffffff;
                                    font-family: "J&J Circular";
                                    font-size: 12px;
                                    font-weight: bold;
                                    letter-spacing: -0.3px;
                                    line-height: 15px;
                                    text-align: center;
                                }
                            }
                        }
                    }
                }
                .line {
                    display: block;
                    box-sizing: border-box;
                    height: 2px;
                    width: 773px;
                    border: 1px solid #e3e3e3;
                }

                .buttonSectionGet {
                    width: 100%;
                    float: right;

                    .getStarted {
                        height: 43.55px;
                        width: 202px;
                        background-color: #ca001b;
                        color: rgb(255, 255, 255);
                        cursor: pointer;

                        .buttonLabel {
                            height: 20px;
                            width: 144px;
                            color: #ffffff;
                            font-size: 16px;
                            font-weight: bold;
                            line-height: 20px;
                            text-align: center;
                        }
                    }
                }
            }
        }
    }
    .move-details-success {
        display: flex;
        flex-direction: column; 
    }
}
