@import "~main.scss";

.access-denied {
    padding: 60px 50px 0;

    .access-denied-content {
        width: 574px;
        height: 574px;
        background-color: $blueText;

        > div {
            padding: 94px 107px 0 52px;
            color: $white;
        }

        h1 {
            color: $white;
            font-size: 44px;
            font-weight: 900;
            letter-spacing: -0.5px;
            line-height: 55px;
        }

        p {
            color: $white;
            margin-top: 50px;
            font-size: 20px;
            font-weight: 300;
            line-height: 30px;
        }
    }

    .access-denied-img {
        width: 570px;

        img {
            height: 100%;
        }
    }
}

@media screen and (max-width: $breakPoint-lg) {
    .access-denied {
        padding: 60px 30px 0;
       .access-denied-content {
           width: 48%;
           height: auto;
           padding-bottom: 30px;

           > div {
               padding: 40px 50px 0;
           }
       }

        .access-denied-img {
            width: 48%;

            img {
                height: auto;
            }
        }
    }
}

@media screen and (max-width: $breakPoint-md) {
    .access-denied {
       .access-denied-content {
           > div {
               padding: 20px 30px;
           }

           h1 {
               font-size: 30px;
               line-height: 30px;
           }

           p {
               margin-top: 30px;
               font-size: 15px;
               line-height: 20px;
           }
       }
    }
}

@media screen and (max-width: $breakPoint-sm) {
    .access-denied {
        padding: 60px 0 0;

        .access-denied-wrapper {
            flex-direction: column;
            @include prefixer(flex-direction, column);
        }

        .access-denied-content {
            width: 100%;

            > div {
                padding: 30px;
            }
        }

        .access-denied-img {
            width: 100%;
            margin-top: 30px;
        }
    }
}
