@import "~main.scss";

.package-selection-container {
    padding: 45px 150px;

    .reason-info {
        color: #212121;
        font-size: 20px;
        font-weight: 300;
        line-height: 30px;
        margin-top: 25px;
    }

    @media screen and (max-width: $breakPoint-md) {
        padding: 30px;
    }
    .TextSection {
        .back-icon {
            margin: 0;
        }
        .section-title {
            margin: 0;
        }
    }

    .line {
        margin-top: 50px;
        border: 1px solid #D8D8D8;
    }

    .reasons {
        margin-top: 32px;
        &.jBetween {
            justify-content: flex-start;
        }

        .reason {
            width: 30%;
            box-sizing: border-box;
            padding: 30px 30px 30px 30PX;
            margin-bottom: 21px;
            min-height: 120px;
            cursor: pointer;
            border: 1px solid #D8D8D8;
            background-color: #FFFFFF;
            margin-left: 3%;
            &:hover {
                border: 1px solid $blueText;
            }

            @media screen and (max-width: $breakPoint-sm) {
                width: 100%;
            }

            h5 {
                color: #000099;
                font-size: 24px;
                font-weight: 900;
                line-height: 32px;
            }

            p {
                margin-top: 5px;
                color: #212121;
                font-size: 14px;
                font-weight: 300;
                line-height: 24px;
            }

            .des-highlighted {
                color: #212121;
                font-size: 14px;
                font-weight: 900;
                line-height: 24px;
                margin-top: 5px;
            }
        }
    }
    .contactmobility-wrapper {
        .contact-mobility-container-layout {
            p {
                &.label {
                    @media screen and (max-width: 600px) {
                        width: 100%;
                        padding-top: 15px;
                    }
                }
                &.main-text {
                    @media screen and (max-width: 600px) {
                        width: 100%;
                        line-height: 20px;
                    }
                }
            }
            .details-section {
                .horizontal {
                    .contact-by-phone {
                        @media screen and (max-width: 1024px) {
                            margin-bottom: 30px;
                        } 
                    }
                    .mail-information {
                        @media screen and (max-width: 1024px) {
                            margin-bottom: 20px;
                        } 
                    }
                }
                .img-information {
                    @media screen and (max-width: 600px) {
                        position: relative;
                        left: auto;
                    }
                }
            }
        }
    }
}
