@import '~main.scss';

.initiation-desc-part {
    height: 100%;

    .initiation-req-list {
        margin-top: 25px;

    }

    .initiation-req-section {
        &:first-child {
            margin-right: 20px;
        }
        > li {
            display: flex;
            font-size: $smallSize;
            font-weight: 300;
            line-height: 20px;
            margin-bottom: 5px;
            &::before {
                content: "•";
                display: block;
                color: $buttonsColor;
                margin-right: 10px;
            }
        }
    }

    .view-init-checklist-link {
        cursor: pointer;
        font-size: $smallSize;
        color: $blueText;
        font-weight: 300;
    }
}

.sirva-connect-part {
    height: 100%;

    .sirva-connect-desc {
        padding: 0 80px;
        @media screen and (max-width: $breakPoint-sm) {
            padding: 0 30px;
        }
    }

    .sirva-connect-text {
        font-weight: 300;
        color: $textColor;
        line-height: 26px;
    }

    .sirva-connect-external-link {
        margin-bottom: 15px;
        font-size: $smallSize;
        color: $blueText;
        font-weight: 300;
        line-height: 18px;
        cursor: pointer;
    }

    .sirva-connect-with-border {
        padding-left: 80px;
        border-left: 2px solid $borderColor;
        @media screen and (max-width: $breakPoint-md) {
            padding-left: 0;
            border-left: none;
        }
    }
}
