@import "~main.scss";

.hr-IntArivalQuestionsData {

.dom-expl-question-part {
    .question-block {
        width: 80%;

        p:first-child {
            font-size: $subTitleSize;
            font-weight: 300;
            line-height: 30px;
        }
    }
}


.MidSectionComponent {
    .mid-section-body {
        .step-number {
            opacity: 0.7;
            color: $white;
            font-size: $mediumSize;
            font-weight: 900;
            line-height: 22px;
        }
    }
}
.expl_hr_acc_item_part_1 {
    color:$black;
    font-family: "J&J Circular";
    font-weight: 300;
    line-height: 30px;
}
.expl_hr_acc_item_part_2 {
    color:$black;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: $mediumSize;
    font-weight: 300;
    line-height: 26px;
}
.expl_hr_acc_item_part_3 {
    padding-top: 8px;
    padding-bottom: 10px;
    font-size: $mediumSize;
    font-weight: 500;
    line-height: 26px;
}
.dom-expl-questions {
    width: 65%;
    .question-part {
        padding: 0px 40px 20px 80px;
    }
    .dom-expl-first-step-questions {
        .title-with-content  {
            padding-bottom: 10px;
            font-size: $mediumSize;
            font-weight: 900;
            line-height: 22px;
        }
        .dom-expl-first-step-info-content {
            padding-left: 70px;
        }
        img {
            width: 50px;
            height: 50px;
            padding-right: 19px;
        }
        .list {
            > li {
            display: flex;
            font-weight: 300;
            line-height: 1.8;
            &:not(last-child) {
                margin-bottom: 15px;
            }
            &::before {
                content: "•";
                display: block;
                color: $buttonsColor;
                margin-right: 10px;
            }
            }
        }
        .Link {
            margin-top: 40px;
            margin-right: 20px;
            font-size: $mediumSize;
            font-weight: bold;
            line-height: 18px;
            }
        }
    }

    .dom-expl-mobility{
        width: 30%;
    }
}

@media screen and (max-width: $breakPoint-lg) {
.hr-IntArivalQuestionsData {
    .dom-expl-question-part {
        display: block;
        .dom-expl-questions {
            width: 100%;
        }
        .dom-expl-mobility{
            width: 100%;
        }
    }
    .dom-expl-questions {
        .question-part {
            padding: 10px;
        }
        .dom-expl-first-step-info-content {
            padding-left: 10px !important;
        }
    }
}
}
