@import "~main.scss";

.imageSelectionCard {
    cursor: pointer;
    padding: 40px 20px;
    width: 260px;
    height: 165px;
    margin: 0 12px 30px 0;
    border: 2px solid rgb(230, 230, 230);
    &:hover {
        border-color: #000099;
    }

    .icon-block {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        height: 50%;
        width: 100%;
        margin: 0;
        order: -1;
        img {
            width: 50px;
            padding-bottom: 25px;
        }
    }

    .selection-card-block {
        height: 50%;
        > h2 {
            color: rgb(29, 29, 131);
            margin-bottom: 0.5rem;
            letter-spacing: -0.5px;
            font-weight: bold;
            font-size: 24px;
            font-weight: 500;
            line-height: 26px;
        }
        > p {
            margin-bottom: 1.5rem;
            color: black;
            font-size: 14px;
            font-weight: 100;
            line-height: 30px;
            word-spacing: 1px;
        }
        span {
            color: white;
            font-size: 18px;
            font-weight: bold;
            line-height: 26px;
            margin-bottom: 4px;
        }
    }
}

.regularSelectionCard {
    cursor: pointer;
    max-width: 311px;
    height: 310px;
    margin: 0 30px 23px 0;
    background-color: #ffffff;
    border: 1px solid #d8d8d8;
    &:hover {
        border-color: #000099;
    }
    .selection-card-block {
        height: auto;
        padding: 65px 15px 0 21px;

        > h2 {
            color: #000099;
            font-weight: bold;
            font-size: 24px;
            width: 258px;
            font-weight: 900;
            line-height: 32px;
            margin: 0 0 22px 0;
        }
        > p {
            height: 144px;
            width: 238px;
            color: #212121;
            font-size: 14px;
            font-weight: 300;
            line-height: 24px;
        }
        span {
            color: white;
            font-size: 18px;
            font-weight: bold;
            line-height: 26px;
            margin-bottom: 4px;
        }
    }
}

.optionSelectionCard{
    display: flex;
    cursor: pointer;
    padding: 0 20px;
    width: 80%;
    height: 92px;
    margin: 0 30px 0 0;
    border: 2px solid rgb(230, 230, 230);
    &:hover {
        border-color: #000099;
    }

    .icon-block {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin: 0 20px 0 0;
        padding: 0 0 7px 0;
        order: -1;
        img {
            width: 50px;
            padding-bottom: 25px;
        }
    }

    .selection-card-block {
        display: flex;
        align-items: center;
        height: 100%;

        > h2 {
            color: rgb(29, 29, 131);
            margin-bottom: 0.5rem;
            letter-spacing: -0.5px;
            font-weight: bold;
            font-size: 25px;
            font-weight: 500;
            line-height: 26px;
        }
    }
}
.countrySelectionCard{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 193px;
    height: 215px;
    border: 1px solid #d8d8d8;
    cursor: pointer;
    margin: 0 0 24px 0;
    .imageContainer{
        display: flex;
        width: 50px;
        height: 50px;
        margin: 0 0 10px 0;

        .icon-block {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            width: 100%;
            height: 100%;
            padding: 0 0 7px 0;
            order: -1;
            img {
                width: 100%;
            }
        }   
    }
}
@media screen and (min-width: 1024px) {
    //REGULAR SELECTION CARD
    .regularSelectionCard {
        cursor: pointer;
        max-width: 303px;
        height: 311px;
        margin: 0 30px 0 0;
        background-color: #ffffff;
        border: 1px solid #d8d8d8;
        &:hover {
            border-color: #000099;
        }
        .selection-card-block {
            height: 50%;
            padding: 54px 15px 42px 30px;

            > h2 {
                color: #000099;
                font-weight: bold;
                font-size: 24px;
                width: 258px;
                font-weight: 900;
                line-height: 32px;
                margin: 0 0 22px 0;
            }
            > p {
                height: 144px;
                width: 238px;
                color: #212121;
                font-size: 14px;
                font-weight: 300;
                line-height: 24px;
            }
            span {
                color: white;
                font-size: 18px;
                font-weight: bold;
                line-height: 26px;
                margin-bottom: 4px;
            }
        }
    }

    //ICON/IMAGE SELECTION CARD
    .imageSelectionCard {
        cursor: pointer;
        padding: 40px 20px;
        max-width: 260px;
        height: 165px;
        margin: 0 30px 0 0;
        border: 2px solid rgb(230, 230, 230);
        &:hover {
            border-color: #000099;
        }

        .icon-block {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            height: 50%;
            width: 100%;
            margin: 0;
            order: -1;
            img {
                width: 50px;
                padding-bottom: 25px;
            }
        }

        .selection-card-block {
            height: 50%;
            > h2 {
                color: rgb(29, 29, 131);
                margin-bottom: 0.5rem;
                letter-spacing: -0.5px;
                font-weight: bold;
                font-size: 25px;
                font-weight: 500;
                line-height: 26px;
            }
            > p {
                margin-bottom: 1.5rem;
                color: black;
                font-size: 14px;
                font-weight: 100;
                line-height: 30px;
                word-spacing: 1px;
            }
            span {
                color: white;
                font-size: 18px;
                font-weight: bold;
                line-height: 26px;
                margin-bottom: 4px;
            }
        }
    }

    .optionSelectionCard{
        display: flex;
        cursor: pointer;
        padding: 0 20px;
        width: 260px;
        height: 92px;
        margin: 0 30px 0 0;
        border: 2px solid rgb(230, 230, 230);
        &:hover {
            border-color: #000099;
        }

        .icon-block {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            margin: 0 20px 0 0;
            padding: 0 0 7px 0;
            order: -1;
            img {
                width: 50px;
                padding-bottom: 25px;
            }
        }

        .selection-card-block {
            display: flex;
            align-items: center;
            height: 100%;

            > h2 {
                color: rgb(29, 29, 131);
                margin-bottom: 0.5rem;
                letter-spacing: -0.5px;
                font-weight: bold;
                font-size: 25px;
                font-weight: 500;
                line-height: 26px;
            }
        }
    }
    
    //Country Celection
    .countrySelectionCard{
        display: flex;
        flex-direction: column;
        justify-content: center;
        cursor: pointer;
        align-items: center;
        width: 153px;
        height: 165px;
        border: 1px solid #d8d8d8;
        margin: 0 24px 24px 0;
        .imageContainer{
            display: flex;
            width: 50px;
            height: 50px;
            margin: 0 0 10px 0;

            .icon-block {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                width: 100%;
                height: 100%;
                padding: 0 0 7px 0;
                order: -1;
                img {
                    width: 100%;
                    max-height: 50px;
                }
            }   
        }
    }
}

.phone-consult {
    .selection-card-block {
        h2 {
            color: #000099;
            font-size: 24px;
            font-weight: 900;
            line-height: 32px;
        }
    }
}

.autosuggest-container {
    input::-ms-clear {
        display: none;
    }
}
