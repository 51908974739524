@import "~main.scss";

.domestic-package-selection-container {
    padding: 45px 150px;

    @media screen and (max-width: $breakPoint-md) {
        padding: 30px;
    }
    .TextSection {
        .back-icon {
            margin: 0;
        }
        .section-title {
            margin: 0;
        }
    }

    .reasons {
        margin-top: 32px;
        &.jBetween {
            justify-content: flex-start;
        }

        .reason {
            width: 30%;
            box-sizing: border-box;
            padding: 30px 30px 30px 30PX;
            margin-bottom: 21px;
            min-height: 120px;
            cursor: pointer;
            border: 1px solid #D8D8D8;
            background-color: #FFFFFF;
            margin-left: 3%;
            margin-bottom: 10%;
            &:hover {
                border: 1px solid $blueText;
            }
            &:first-child {
                margin-left: 0;
                @media screen and (max-width: 767px) {
                    margin-left: 3%;
                }
            }

            @media screen and (max-width: $breakPoint-sm) {
                width: 100%;
            }

            h5 {
                color: #000099;
                font-size: 24px;
                font-weight: 900;
                line-height: 32px;
            }

            .reason-info {
                margin-top: 5px;
                color: #212121;
                font-size: 20px;
                font-weight: 300;
                line-height: 30px;
                text-align: center;
            }
            .always-for-new-hire {
                color: #000099;
                font-size: 12px;
                font-weight: 300;
                line-height: 20px;
                margin-top: 0px;
                text-align: center;
            }
        }
    }
}
