@import '~main.scss';

.Profil {
  .articles {
    padding: 50px 0;
    flex-flow: row wrap;
  }
  .pagination {
    display: flex;
    flex-direction: row;
    flex-grow: 0;
     >li {
       margin: 0 12px;
       padding: 5px;
       font-size: 16px;
       font-weight: 900;
       &.active {
         border-bottom: 1px solid $blueText;
         > a {
           color: $blueText;
         }
       }
     }
  }
}