@import "~main.scss";

.Tabs {
  align-self: flex-start;

  &.withBorder .TabItem{
    position: relative;
    &:not(:last-child):after {
      content: '';
      position: absolute;
      right: 0;
      height: 16px;
      border-right: 1px solid silver;
    }
  }

  &.linear-selected .TabItem {
    margin-right: 40px;
    &:last-child {
      margin-right: 0;
    }
    &:hover {
      background-color: inherit;
      border-bottom: 1px solid $black;
    }
    &.active {
      background-color: inherit;
      color: $blueText;
      border-bottom: 1px solid $blueText;
    }
  }
}