@import '~main.scss';

.ArticleDetail {
  .header-with-image {
    margin-top: 70px;
    padding: 0 150px;
    .title {
      font-size: 44px;
      color: $blueText;
      font-weight: 900;
    }
    .image {
      width: 100%;
      margin: 30px 0;
      > img {
        width: 100%;
        height: auto;
      }
    }
  }
}

@media screen and (max-width: $breakPoint-lg) {
  .ArticleDetail {
    .header-with-image {
      margin-top: 25px;
      padding: 0 25px;

      .title {
        font-size: 30px;
      }
    }
  }
}
