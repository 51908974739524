@import '../../../main.scss';

.Accordion {
  .accordion-item {
    &:not(.disabled) {
      cursor: pointer;
    }
    .item-header {
      border-bottom: 1px solid $borderColor;
      .title {
        z-index: 6;
        width: 100%;
        .title-text {
          max-width: calc(100% - 70px);
          font-weight: 900;
        }
      }
    }
    .item-content {
      height: 0px;
      transition: all .25s ease-out;
      overflow: hidden;
      &.opened {
        height: auto;
        padding: 35px 0;
        font-weight: 300;
      }
    }
  }
}

.ie {
  #root {
    .Profil {
      .accordion-item {
      }
    }
    .Accordion {
      .item-header {
        border: none
      }
      .ListItem {
        border: none
      }
      .accordion-item {
        border-bottom: 1px solid $borderColor;
        &.open {
          border: none;
        }
        .accordion-header {
          .title {
            z-index: 6;
          }
        }
        .accordion-body {
          z-index: 7;
          border-top: 1px solid $blueText;
        }
      }
    }
  }
}
