@import "~main.scss";

.ContactUs {
  padding: 72px 150px;
  flex-grow: 1;
  .flag {
    width: 300px;
    height: 400px;
    border: 1px solid black;
  }
  .TabItem {
    padding: 0px;
    height: 25px;
    font-weight: 900;
  }
  h1 {
    font-size: 44px;
  }
  .HelpComponent {
    margin-bottom: 2rem;
      .info-block{
        .text-block {
          > h2 {
            color: $subtitleColor;
          }
        }
      }
      .email-block {
        justify-content: center;
        a {
          margin-bottom: 15px;
          color: $blueText;
          span {
            color: $blueText;
          }
        }
      }
    }
  .marginBot25 {
    margin-bottom: 2.5rem
  }

  @media screen and (min-width: $breakPoint-lg) {
    .Accordion {
      .accordion-item {
        .accordion-body {
          .accordion-body-item {
            .info-block {
              .ColumnComponent {
                .card {
                  height: 310px;
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: $breakPoint-lg) {
  .ContactUs {
    .HelpComponent {
      .help-component-block {
        flex-direction: column;
        align-items: center;
        .email-block {
          border: none;
          margin-top: 0;
          padding-top: 20px;
          padding-left: 0;
          a {
            margin-bottom: 20px;
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
  .Accordion {
    .accordion-item {
      .accordion-body {
        .accordion-body-item {
          .info-block {
            .ColumnComponent {
              .column-component-body{
                display:block;
                .card{
                  height:310px;
                  width:100%;
                  margin-bottom: 30px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: $breakPoint-md) {
  .ContactUs {
    padding: 72px 40px;
    .header-text {
      font-size: 36px;
    }
    .HelpComponent {
      min-width: inherit;
      .help-component-block {
        flex-direction: column;
        .info-block {
          padding-bottom: 20px;
          .image-block {
            min-width: 100px;
            width: 100px;
            height: 100px;
          }
          .text-block {
            padding-top: 25px;
            > h2 {
              margin-bottom: 0;
              font-size: 16px;
            }
            .name {
              font-size: 20px;
            }
          }
          .contact-us-description {
            position: relative;
            left: -115px;
            top: 20px;
            width: calc(100% + 132px);
          }
        }
        .email-block {
          border: none;
          padding-left: 0;
          margin-top: 0;
          width: 100%;
          a {
            margin-bottom: 15px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $breakPoint-sm) {
  .ContactUs {
    padding: 25px;
    .header-text {
      font-size: 30px;
    }
    .HelpComponent {
      padding: 1rem;
    }
    .Accordion {
      .accordion-body-item {
        .info-block {
          flex-direction: column;
        }
      }
    }
  }
}

//crosbrowser styles

.ie {
  #root {
    .ContactUs {
      min-height: calc(100vh - 281px);
      .HelpComponent {
        .email-block {
          width: 60%;
          padding-left: 20px;
        }
      }
    }
  }
}
