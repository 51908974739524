@import '~main.scss';

.validator-popup {
    &-subtitle {
        font-size: $mediumSize;
        color: $subtitleColor;
    }

    &-title {
        margin-top: 10px;
    }

    &-file-form {
        padding-bottom: 30px;
    }

    .file-name-label {
        color: $placeHolderColor;
    }

    .file-name-section {
        align-items: flex-end;
        margin-top: 30px;
    }

    .file-extension {
        font-weight: 900;
        margin-bottom: 13px;
    }

    .file-name-input {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        max-width:  270px;
    }

    .char-counts {
        font-size: 13px;
        color: $placeHolderColor
    }

    .validation-message {
        font-size: 13px;
        color: #f35351;
        min-height: 15px;
    }
    .cancel-button, .save-button {
        width: 170px;
        height: 45px;
        padding: 0;
        box-sizing: border-box;
    }

    .original-file{
        color: $blueText;
        font-size: 13px;

        > span {
            margin-right: 10px;
            color: $blueText;
            font-weight: 900;
        }
    }
}
