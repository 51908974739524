@import "~main.scss";

.AllowancesAndDeductions {
    background-color: $blueText;
    padding: 75px 150px;

    &.domestic {
        flex-direction: row;
        padding: 50px;
        justify-content: space-between;
        .ad_header {
            margin-bottom: 0;
            display: flex;
            min-width: 250px;
            flex-shrink: 0;
            align-items: center;
            font-size: $bigTitleSize;
        }
        .ad_content {
            max-width: 900px;
            .left_block {
                &:not(.fullWidth) {
                    max-width: 670px;
                }
            }
            .right_block {
                .Button {
                    width: 150px;
                }
            }
        }
    }
    &.international {
        flex-direction: column;
    }

    & * {
        color: white;
    }
    .ad_header {
        margin-bottom: 50px;
        font-size: 30px;
    }
    .ad_content {
        justify-content: space-between;
        .left_block {
            &:not(.fullWidth) {
                max-width: 570px;
                margin-right: 25px;
            }
            > p {
                font-weight: 300;
                line-height: 1.7;
            }
        }
        .right_block {
            .bs_date_header {
                font-size: $smallSize;
                margin-bottom: 10px;
            }
            .bs_date {
                font-size: $subTitleSize;
                margin-bottom: 25px;
            }
            .Button {
                height: 55px;
                width: 250px;
                padding: 0.7rem 0;
                box-sizing: border-box;
                > span {
                    font-size: $mediumSize;
                }
            }
        }
    }
}

@media screen and (max-width: $breakPoint-lg) {
    .AllowancesAndDeductions {
        padding: 35px 25px;
        .ad_header {
            font-size: 24px;
        }
        &.domestic {
            flex-direction: column;
            .ad_header {
                margin-bottom: 15px;
            }
        }
        .ad_content {
            flex-direction: column;
            .right_block {
                margin-top: 30px;
                justify-content: flex-start;
            }
        }
    }
}
