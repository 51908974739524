@import '~main.scss';

.nomatch-container {
    .nomatch-icon {
        >svg {
            width: 120px;
            height: 120px;
        }
    }
    .nomatch-title {
        font-size: 65px;
        margin-bottom: 25px;
        margin-top: 15px;
    }

    .nomatch-text {
        margin-bottom: 30px;
        font-size: $subTitleSize;
        line-height: 1.5;
        font-weight: 300;
    }
    .goto-homepage-button {
        padding: 18px 40px;
        >span {
            font-size: 16px;
        }
    }
}

@media screen and (max-width: $breakPoint-sm) {
    .nomatch-container {
        .nomatch-icon {
            > svg {
                width: 70px;
                height: 70px;
            }
        }
        .nomatch-title {
            font-size: 36px;
            margin-bottom: 20px;
            margin-top: 0;
        }
        .hidden-xs {
            padding: 0 !important;
        }
    }
}
