@import "~main.scss";

#menu-flightNumber,
#menu-leadership,
#menu-rotations,
#menu-californiaTier {
    ul {
        padding: 0 !important;
    }
}

.job-details {
    padding-bottom: 74px;

    .messages {
        margin-top: 26px;
        max-width: 473px;

        @media all and (max-width: $small) {
            max-width: 100%;
        }

        .message {
            border-radius: 9px;
            background-color: $disabledColor;
            padding: 21px 17px 20px 27px;
            font-size: 16px;
            font-weight: 300;
            line-height: 24px;

            @media all and (min-width: $small) {
                width: 100%;
            }

            &:not(:last-of-type) {
                margin-bottom: 13px;
            }
        }
    }

    .job-details-form {
        margin-top: 28px;

        h5 {
            font-size: 18px;
            line-height: 36px;
            letter-spacing: normal;

            &.california-title,
            &.base-title,
            &.family-title {
                margin-top: 46px;
            }
        }

        .leadership-container {
            @media screen and (max-width: $medium) {
                flex-direction: column;
                @include prefixer(flex-direction, column);
                align-items: flex-start;
                @include prefixer(flex-align, flex-start);

                .dropdown {
                    &:last-of-type {
                        margin-left: 0 !important;
                    }
                }
            }
        }
    }

    .Input{
        width:50%;
        
    }

    .dropdown {
        .dropdown-label {
            color: $black;
            font-size: 14px;
            line-height: 24px;

            span {
                color: $buttonsColor;
            }
        }

        .dropdown-root {
            padding: 0;
        }

        .dropdown-menu {
            min-width: 240px;
            font-size: 14px;
            font-weight: 300;
            line-height: 24px;
            padding-bottom: 4px;
            border-bottom: 2px solid $black;

            @media screen and (min-width: $small) {
                min-width: 315px;
            }
        }
    }

    .tooltip-popper {
        opacity: 1;
        border: 3px solid #eaebec;
        pointer-events: all;

        @media screen and (min-width: $small) {
            left: 40px !important;
            top: 107px !important;
        }

        h4 {
            font-size: 28px;
            color: $black;
            line-height: 32px;
        }

        .tier {
            margin-top: 20px;
            font-size: 14px;
            font-weight: 300;
            line-height: 18px;

            &:first-of-type {
                margin-top: 32px;
            }

            span {
                font-weight: 900;
                display: block;
            }
        }
    }

    .tooltip-root {
        padding: 15px 17px 35px 20px;
        background-color: #fff;
        margin: 0;
        position: relative;

        @media screen and (min-width: $small) {
            padding: 15px 17px 35px 30px;
        }

        @media screen and (min-width: $medium) {
            max-width: 451px;
        }

        &:after,
        &:before {
            content: "";
            display: none;
            position: absolute;
            left: -39px;
            width: 0;
            height: 0;
            border-style: solid;

            @media screen and (min-width: $medium) {
                display: block;
            }
        }

        &:after {
            top: 52px;
            border-color: transparent #fff transparent transparent;
            border-width: 22px;
        }

        &:before {
            top: 56px;
            border-color: transparent #eaebec transparent transparent;
            border-width: 18px;
        }
    }

    .close-button {
        display: flex;
    }

    .radio-root {
        margin-right: 27px;
    }

    .radio-button-root {
        padding: 0 14px;

        svg {
            fill: $disabledColor;
        }
    }

    .radio-label {
        font-size: 14px;
        font-weight: 300;
        line-height: 18px;
    }

    .divider {
        margin-top: 50px;
        height: 2px;
        background-color: #e3e3e3;
        width: 100%;

        @media screen and (min-width: $large) {
            width: 773px;
        }
    }

    .job-details-submit {
        padding: 12px 67px;
        margin-top: 28px;

        span {
            font-size: 16px;
        }
    }
}

.dropdown-item {
    font-size: 14px !important;
    font-weight: 300;
    border: 1px solid $disabledColor !important;
}

.select-field-spinner {
    position: absolute;
    top: 32px;
    right: 25px;
    .Spinner {
        height: 5px;
        width: 5px;
        margin: -4px 0 0 5px;
    }
}
