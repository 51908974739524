@import "~main.scss";

.MobilityConnectBlock {
    padding-bottom: 20px;
    .task-list-items {
        padding: 30px;
    }
    .info-block {
        padding-top: 10px;
    }
}
