.spinner-wrappar {
    position: relative;

    .spinner-root {
        position: absolute;
        z-index: 10;
        top: 0;
        left: 0;
        display: flex;
        width: 100%;
        height: 100%;
        background: rgba(255,255,255,.5);
        justify-content: center;
        align-items: center;
    }
}
