@import '~main.scss';

.Link {
  cursor: pointer;
  font-weight: bold;
  font-size: $mediumSize;
  line-height: 14px;
  .Icon {
    min-width: 21px;
    max-width: 21px;
    height: 21px;
    max-height: 21px;
    background-color: $buttonsColor;
    border-radius: 100%;
    padding: 0;
    align-items: center;
    justify-content: center;
    margin-left: 1rem;
    position: relative;
    svg {
      fill: #fff;
      transform: rotate(-90deg);
      position: absolute;
      left: 5px;
      top: 5px;
    }
  }
}
