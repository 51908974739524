@import "~main.scss";

.TextComponent {
  width: calc(100% - 160px);
  background-color: $blueText;
  padding: 80px;
  .text-component-block {
    > h2 {
      color: $white;
      font-size: 32px;
      margin-bottom: 2.5rem;
    }
    .ordered-list-components {
      .OrderedList {
        box-sizing: border-box;
        padding: 1rem;
        margin: .5rem;
        flex-basis: 30%;
        display: flex;
        align-items: center;
        .order {
          min-width: 42px;
          height: 42px;
          border-radius: 100%;
          border: 2px solid $subtitleColor;
          span {
            color: $white;
            font-size: $largeSize;
            font-weight: bold;
          }
        }
        .text {
          color: $white;
          margin-left: 1rem;
        }
      }
    }
    .texts-with-url {
      .TextsWithUrl {
        .text-block {
          > h4 {
            color: $white;
            margin-bottom: 1rem;
          }
          .text-list {
            margin-bottom: 2.5rem;
            > p {
              color: $white;
              font-size: $smallSize;
              line-height: $subTitleSize;
            }
          }
          span {
            color: $white;
          }
        }
      }
    }
  }
}