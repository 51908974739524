@import "~main.scss";

.finance {
    margin-bottom: 63px;

    .messages {
        margin-top: 26px;
        width: 473px;

        @media all and (max-width: $small) {
            width: 100%;
        }

        .message {
            border-radius: 9px;
            background-color: $disabledColor;
            padding: 21px 17px 20px 27px;
            font-size: 16px;
            font-weight: 300;
            line-height: 24px;

            &:not(:last-of-type) {
                margin-bottom: 18px;
            }
        }
    }

    .finance-contacts {
        margin-top: 18px;
        margin-bottom: 9px;

        .Icon {
            margin-left: 9px;

            svg {
                width: 10px;
                height: 10px;
                left: 6px;
            }
        }
    }

    .finance-empty-partner {
        margin-top: 37px;
        font-size: 16px;
        font-weight: 300;
        line-height: 24px;

        width: 473px;

        @media all and (max-width: $small) {
            width: 100%;
        }
    }

    .divider {
        margin-top: 60px;
        height: 2px;
        background-color: #e3e3e3;
        width: 100%;

        @media screen and (min-width: $large) {
            width: 776px;
        }
    }

    .finance-submit {
        padding: 12px 67px;
        margin-top: 28px;

        &.disabled {
            background-color: $disabledColor;
        }
        span {
            font-size: 16px;
            font-weight: bold;
        }
    }
}
