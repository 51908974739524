@import '~main.scss';

.ListItem {
  min-height: 65px;
  padding: 10px 0;
  box-sizing: border-box;

  &.opened {
    * {
      color: $blueText;
    }
  }

  .icon-block {
    max-width: 180px;
  }

}
