@import '~main.scss';

.site-overview {
    position: fixed;
    height: 100%;
    width: 100%;
    max-width: inherit;
    top: 0;
    background-color: $blueText;
    z-index: 200;
    transition: top 1s ease-out;
    padding: 50px 150px;
    box-sizing: border-box;

    .close-button {
        position: absolute;
        top: 50px;
        right: 50px;
        cursor: pointer;
    }

    .site-overview-content{
        align-items: flex-start;
        width: 100%;
        padding-top: 127px;
        * {
            color: white;
        }

        .firstSlide {
            p.body2 {
                max-width: 370px;

                @media (max-width: $breakPoint-lg) {
                    max-width: 100%;
                }
            }
        }

        .icon-with-title {
            > p {
                margin-top: 24px;
                font-weight: 300;
                font-size: 20px;
            }
        }

        .slick-dots {
            bottom: -50px;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;

            .dots-container {
                margin-top: 10px;

                li {
                    margin: 0;
                }
            }

            .next-button {
                margin-left: 20px;
                padding: 15px 5px;
                width: 150px;
                height: 55px;
                box-sizing: border-box;

                >span {
                    font-size: 16px;
                    font-weight: bold;
                }
            }

            .dots-container {
                .slick-active > .custom-dot {
                    background-color: white;
                }

                .custom-dot {
                    width: 5px;
                    height: 5px;
                    border-radius: 100%;
                    border: 2px solid white;

                }
            }
        }
    }
}

@media (max-width: $breakPoint-lg) {
    .site-overview {
        .site-overview-content {
            padding-top: 0;
            align-items: center;

            .slick-dots {
                bottom: -46px;
            }
        }
    }
}

@media (max-width: $medium) {
    .site-overview {
        padding: 30px;

        .close-button {
            top: 25px;
            right: 25px;
        }

        .slick-dots {
            bottom: 0;
        }
    }
}

@media (max-width: $small) {
    .site-overview {
        .site-overview-content {
            align-items: inherit;
            height: calc(100vh - 160px);
        }
    }
}

.ie {
    .site-overview-content {
        .slick-slider {
            height: 100%;
            .slick-list {
                height: calc(100% - 55px) !important;
                .slick-track {
                    height: 100%;
                    .slick-slide{
                        > div {
                            height: 100%;
                            display: flex;
                            align-items: center;
                            .overview-info-content {
                                width: 980px;
                                align-items: center;
                                .title-with-description {
                                    width: 460px;
                                    height: 362px;
                                }
                                .info-content-asset {
                                    width: 460px;
                                    height: 362px;
                                }
                            }
                            .my-move-overview {
                                align-items: center;
                                height: 411px;
                                .left-content {
                                    width: 205px;
                                }
                                .right-content {
                                    width: 710px;
                                }
                            }
                        }
                    }
                }
            }
            .slick-dots {
                height: 55px;
                bottom: 0;
            }
        }
    }
}
