@import '~main.scss';

.ListItem {
  
  border-bottom: 1px solid $borderColor;

  .list-item-header {
    flex-basis: 100%;
    font-size: 28px;
    color: $blueText;
  }
  .list-item-middle-content {
  }

  .list-item-right-buttons {
  }
  .button_download {
    cursor: pointer;

    &:after {
      display: block;
      content: '⇥';
      font-style: normal;
      transform: rotate(90deg);
      font-size: 45px;
      color: $blueText;
    }
  }
}
.ListItem.pointer{
  cursor: pointer;
}