@import "~main.scss";
.review-and-submit-container {
    .back-icon {
        margin-top: 6%;
    }
    @media screen and (min-width: 1024px) {
        width: 1024px;
        margin: 0 auto;
    }
    @media screen and (max-width: 1024px) {
        width: 90%;
        margin: 0 auto;
    }
    .header-section {
        padding: 30px 0 45px;
        border-bottom: 1px solid #D8D8D8;
        h1 {
            color: #000099;	
            font-size: 44px;
            font-weight: 900;
            letter-spacing: -0.5px;
            line-height: 48px;
        }
    }
    .body-section {
        .relocation-package-details, .employee-details, .current-job-details, .new-job-details, .additional-move-details, .finance-details {
            padding: 30px 0 40px;
            float: left;
            width: 100%;
            border-bottom: 1px solid #D8D8D8;
            .top-section {
                float: left;
                width: 100%;
                padding-bottom: 25px;
                h2 {
                    color: #212121;
                    font-size: 26px;
                    font-weight: 900;
                    letter-spacing: -0.36px;
                    line-height: 42px;
                    float: left;
                }
                a {
                    float: right;
                    color: #000099;	
                    font-size: 16px;	
                    font-weight: 300;	
                    line-height: 30px;	
                    text-align: right;
                    cursor: pointer;
                }
            }
            .bottom-section {
                float: left;
                width: 100%;

                div {
                    width: 21%;
                    float: left;
                    padding-top: 10px;
                    word-wrap: break-word;
                    &.second {
                        width: 7%;
                        float: left;
                        @media screen and (max-width: 767px) {
                            display: none;
                        }
                    }
                    @media screen and (max-width: 767px) {
                        width: 100%;
                        float: none;
                    }
                    &.last {
                        width: 26%;
                        @media screen and (max-width: 767px) {
                            width: 100%;
                            float: none;
                        }
                    }
                    ul {
                        padding-right: 10px;;
                        li {
                            word-break: break-woard;
                            &.title {
                                color: #63666A;	
                                font-size: 12px;	
                                font-weight: 300;	
                                line-height: 20px;
                                margin-bottom: 8px;
                            }
                            &.desc{
                                color: #212121;	
                                font-size: 16px;	
                                font-weight: 500;	
                                letter-spacing: -0.4px;	
                                line-height: 20px;
                                margin-bottom: 20px;
                                @media screen and (max-width: 767px) {
                                    margin-bottom: 10px;
                                    float: left;
                                    width: 100%;
                                }
                            }
                        }
                    }
                    &.review-flag-section {
                        width: 7% !important; 
                        @media screen and (max-width: 767px) { 
                            width: 100% !important;
                            float: left;
                        }
                        .icon-block {
                            width: 100% !important;
                            float: left;
                            margin: 0;
                            padding: 0;
                            @media screen and (max-width: 767px) { 
                                width: 15% !important;
                            }
                            img {
                                width: 100%;
                            }
                        } 
                        span {
                            &.country {
                                display: block;
                                width: 70%;
                                float: left;
                            }
                        }
                    }
                }
            }
        }
        .new-job-details {
            .bottom-section {
                div {
                    width: 21% !important;
                    &.last {
                        width: 9% !important;
                        @media screen and (max-width: 767px) { 
                            width: 100% !important;
                        }
                    }
                    @media screen and (max-width: 767px) {
                        width: 100% !important;
                        float: none;
                    }
                }
            }
        }
        .finance-details {
            .bottom-section {
                div {
                    ul {
                        li {
                            &.desc{
                                margin-bottom: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
    .button-section {
        padding: 30px 0;
        float: left;
        width: 100%;
        margin-bottom: 50px;
        @media screen and (min-width: 767px) and (max-width: 1024px) {
            margin-bottom: 30px;
        }
        div.delete {
            cursor: pointer;
            color: #000099;	
            font-size: 16px;	
            font-weight: 300;	
            line-height: 30px;
            width: 30%;
            float: left;
            span {
                &.Icon {
                    padding-right: 5px;
                    display: block;
                    width: 20px;
                    float: left;
                    margin-top: 3px;
                }
            }
            @media screen and (max-width: 767px) {
                margin: 0 auto;
                text-align: center;
                width: 220px;
                float: none;
            }
        }
        .button-wrapper {
            float: right;
            @media screen and (max-width: 767px) {
                float: none;
            }
            .Button {
                text-align: center;
                &.save-button {
                    margin-right: 15px;
                    @media screen and (max-width: 767px) {
                        width: 225px !important;
                        margin: 15px auto;
                        display: block;
                    }
                }
                &.submit-button {
                    float: right;
                    @media screen and (max-width: 767px) {
                        width: 225px !important;
                        float: none ;
                        margin: 15px auto;
                        display: block;
                    }
                }
            }
        }
    }
}
