.download-policy-section {
    .title {
        font-weight: 700;
        font-size: 28px;
        margin: 40px 0 40px 0;
    }
    .horizontal-section {
        display: flex;

        p {
            font-size: 15px;
            margin: 0 0 0 15px;
            color: #000099;
            cursor: pointer;
        }
        .Icon {
            cursor: pointer;
        }
        a {
            color: #000099;
            cursor: pointer;
        }
    }
    .line {
        display: block;
        box-sizing: border-box;
        height: 2px;
        width: 100%;
        background: #e3e3e3;
        margin: 55px 0 0 0;
    }
    .buttons {
        display: flex;
        justify-content: space-between;

        .white-button {
            height: 40.55px;
            padding: 10px 70px;
            color: rgb(255, 255, 255);
            border: 1px solid #c53333;
            float: right;
            cursor: pointer;
            background-color: #ffffff;

            .buttonLabel {
                height: 20px;
                width: 144px;
                color: rgb(197, 51, 51);
                font-size: 14px;
                font-weight: bold;
                line-height: 20px;
                text-align: center;
            }
        }

        .red-button {
            height: 40.55px;
            padding: 10px 30px;
            background-color: rgb(197, 51, 51);
            color: rgb(255, 255, 255);
            float: right;
            cursor: pointer;
            border: 0;
            &:disabled {
                background-color: #D8D8D8;
            }
            .buttonLabel {
                height: 20px;
                width: 144px;
                color: #ffffff;
                font-size: 16px;
                font-weight: bold;
                line-height: 20px;
                text-align: center;
            }
        }
    }
    .lum-sum-policy-header-title {
        margin-top: 15px;
    }
    .lum-sum-policy-checkbox-area {
        font-size: 13px;
        color: #000099;
        .MuiIconButton-colorSecondary {
            padding: 15px 0px;
            .MuiIconButton-label {
                path {
                    color: #000099;
                }
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .RecruitedSelectorContainer .RecruitedSelector {
        &.eligible-container {
            width: 88%;
        }
    }
    .download-policy-section {
        .line {
            margin-top: 35px;
        }
        .buttons {
            flex-direction: column-reverse;
            .red-button {
                margin-bottom: 15px;
            }
        }
    }
}
