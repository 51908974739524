@import "~main.scss";

.Profil {
    .tasks-notification {
        padding: 15px 50px 15px;
        background-color: $purple;
        position: relative;

        .task-notification-link {

            span:first-child {
                color: $white;
                font-size: 16px;
                font-weight: 500;
                line-height: 20px;
            }
        }

        .close-button {
            cursor: pointer;
            position: absolute;
            right: 50px;
            top: 18px;
        }

        @media screen and (max-width: $small){
            padding: 12px 25px;

            .task-notification-link {
                position: relative;
                left: 0;
                transform: none;
                max-width: 255px;
            }
        }


        @media screen and (max-width: 350px) {
            .task-notification-link {
                max-width: 225px;
            }
        }
    }

    .slick-track {
        width: 100%;
        display: flex !important;
    }

    .hr-title {
        display: none;
    }

    .EmployeeHero {
        .image-block {
            img {
                max-width: 100%;
            }
        }
    }
    .ordered-list-components {
        .OrderedList {
            align-items: flex-start !important;
        }
    }
    .employee-stepper {
        margin: 60px 40px 40px 40px;
        padding-bottom: 10px;
        overflow-x: auto;
        &.domestic-initiate-step-single {
            .circle {
                display: none;
            }
        }
    }
    .question-block {
        padding: 80px;
    }
    .TextComponent {
        margin-bottom: 40px;
    }
    .three-cards {
        .InfoCard{
            box-sizing: border-box;
            max-width: 33.3%;
            margin: 0;
            width: 33.3%;

            &.small {
                padding: 76px 57px !important;

                .info-card-block {
                    h2 {
                        font-size: 36px;
                        letter-spacing: -1.5px;
                    }
                }
            }

            .Link:after {
                height: 27px;
                width: 27px;
                font-size: 30px;
            }

            .info-card-block {
                height: 100%;
                justify-content: space-between;
                > a {
                    h2 {
                        font-size: 36px;
                    }
                }
            }
            &:nth-child(2) {
                p {
                    color: $black;
                }
                span {
                    color: $black;
                }
                h2 {
                    color: $blueText;
                }
                a {
                    h2 {
                        color: $blueText;
                    }
                }
            }

            &:nth-child(3) {
                .Link:after {
                    background-color: white;
                    color: $buttonsColor;
                }
            }
        }
    }
    .HelpComponent {
        margin-top: 1rem;
        margin-bottom: 2rem;
    }
    .CTA-components {
        background-color: $mainBackgroundColor;
        padding: 76px 50px;
        .CTAComponent {
            margin: 5px;
        }
        > h2 {
            font-size: 30px;
            color: $blueText;
            font-weight: 900;
            letter-spacing: -0.5px;
            line-height: 30px;

            span {
                height: 2px;
                width: 50px;
                background-color: $blueText;
                margin-top: 26px;
            }
        }
    }
    .stackla-carousel {
        background-color: $white;
        padding: 30px 50px;
        .CTAComponent {
            margin: 5px;
        }
        > h2 {
            font-size: 30px;
            color: $blueText;
            span {
                height: 2px;
                width: 50px;
                background-color: $blueText;
                margin-top: 26px;
            }
        }
        .stackla-carousel-body {
            padding: 50px 0;
            flex-flow: row wrap;
        }
    }

    .question-card {
        padding: 0 150px;

        & > .text-block {
            border-bottom: 0;

            div {
                margin: 0;
            }
        }
    }

    .question-part {
        margin-bottom: 30px;
        padding: 0 80px;
        .question-block {
            padding: 80px 0;
            .TaskListComponent, .HelpComponent {
                max-width: 400px;
            }
            .question-card {
                padding: 0;
            }
            > .flexible:first-child {
                margin-right: 32px;
            }
        }
        > h2 {
            font-size: $bigTitleSize;
            color: $blueText;
            margin: 15px 0;
            line-height: 42px;
        }
        > h4 {
            font-size: $mediumSize;
            color: $subtitleColor;
            margin: 0 0 10px !important;
            font-weight: bold;
        }
    }

    .steps-columns-section .question-block{
        padding: 0;
    }

    .give-the-gift {
        margin-top: 0px;
    }

    .questions-list-card {
        h2 {
            margin-top: 0;
        }
    }

    .get-proccess {
        margin: 0 80px;
        h2 {
            font-size: $bigTitleSize;
            color: $blueText;
            margin-bottom: 15px;
        }
        h4 {
            font-size: $mediumSize;
            color: $subtitleColor;
            margin-bottom: 10px;
            font-weight: bold;
        }
        p {
            font-size: $subTitleSize;
            color: $black;
            line-height: 30px;
        }
        ul {
            margin-top: 20px;
            li {
                font-size: $subTitleSize;
                color: $black;
                line-height: 30px;
                display: flex;
                align-items: flex-start;
                &:before {
                    content: '';
                    min-width: 5px;
                    height: 5px;
                    border-radius: 100%;
                    background-color: $subtitleColor;
                    margin-right: 10px;
                    display: block;
                    margin-top: 12px;
                }
            }
        }
    }

    .hr-main-content {
        margin-top: 20px;
        .competitive-entry-wraper {
            .spinner-wrappar {	
	            .spinner-root {	
	                position: relative;	
	                .Spinner {	
	                    position:absolute;	
	                    top: 45%;	
	                    left: 45%;	
	                    margin-top: 8%;	
	                    @media screen and (max-width: 767px) {	
	                        left: 25%;	
	                    }	
	                }	
	            }	
	            .header-section {	
	                text-align: center;	
	            }	
            }
        }
        .question-part {
            padding: 0 150px;
            margin-bottom: 2px;
            .QuestionListCard {
                margin-bottom: 2px;
                .question-card {
                    padding: 0;
                    .text-block {
                        .question-part {
                            padding: 0;
                        }
                    }
                }
            }
        }
    }

    .give-the-gift {
        .QuestionListCard {
            margin-top: 0px;
        }    
        .questions-list-card {
            .question-part {
                padding: 0;
            }
        }
    }


    .hr-carousel-main {
        position: relative;

        .image-block {
            width: 100%;
            height: 430px;
            background-position: 50% 25%;
            background-size: cover;

            &.opacity {
                opacity: 0.1;
            }
        }
        .hero-text-section{
            position: absolute;
            top: 0;
            height: 100%;
            max-width: 540px;
            padding: 0 80px;
            background: linear-gradient(to right, rgba(255,255,255, .9), rgba(255,255,255, .8), rgba(255,255,255, .6), transparent);

            .user-name {
                font-size: 20px;
                font-weight: bold;
                color: $subtitleColor;
                margin-bottom: 10px;
            }

            .hero-text {
                max-width: 540px;
                font-size: 44px;
                font-weight: 900;
                color: $blueText;
            }

            .hero-text2 {
                max-width: 540px;
                font-size: 30px;
                font-weight: 900;
                color: $blueText;

                &_small {
                    margin-top: 22px;
                    font-size: 16px;
                    font-weight: initial;
                    line-height: 25px;
                    color: $grey;
                }
            }

            .hero-text-link {
                max-width: 540px;
                font-size: 25px;
                font-weight: 900;
                color: $subtitleColor;
                margin-top: 25px;

                a {
                    font-size: 25px;
                    font-weight: 900;
                    color: $subtitleColor;
                    text-decoration: underline;
                }

                &_small {
                    margin-top: 22px;
                    font-size: 16px;
                    font-weight: initial;
                    line-height: 25px;
                    color: $grey;
                }
            }
            @media screen and (max-width: 767px) {
                .hero-text-link {
                    font-size: 22px;
                }
            }

            .buttons {
                margin-top: 10px;
            }

            .button {
                margin-top: 15px;
                padding: 20px 30px;
                background: $buttonsColor;
            }
        }

        .cards {
            padding: 122px 60px;
            top: 100px;
            right: 0;
            position: absolute;
        }

        .card {
            padding: 30px !important;
            width: 265px !important;
            margin-right: 20px;

            p {
                color: $blueText;
                font-size: 19px;
            }
        }
    }

    .hr-carousel-back-button {
        margin-top: -30px;

        .back-button-component-body {
            display: flex;
            table-layout: fixed;
            width: 100%;
            justify-content: space-around;
            cursor: pointer;
            p {
                width: 60px;
                border: 1px solid $borderColor;
                position: relative;
                height: 30px;
                box-sizing: border-box;
                background-color: red;
                color: white;
            }
        }

        img {
            cursor: pointer;
            position: relative;
            z-index: 1;
        }
    }

    &.new-flow {
        .your-talent-moves-container {
            padding: 0 150px;
            margin-top: 60px;
            @media screen and (max-width: 1024px) {
                padding: 0 50px;
            }
            @media screen and (max-width: 767px) {
                padding: 0 20px;
            }
            .profile-header-right {
                color: #212121;
                font-size: 18px;
                font-weight: bold;
                line-height: 26px;
                text-align: right;   
            }  
            h1 {
                letter-spacing: -0.5px;
            }

            .items {
                margin-top: 23px;
                border-top: 1px solid $disabledColor;
            }

            .initiate-a-move {
                font-size: 18px;

                .Icon {
                    margin-left: 11px;
                    min-height: 29px;
                    min-width: 29px;

                    svg {
                        top: 9px;
                        left: 9px;
                    }
                }
            }
        }

        .hr-main-content {
            margin-top: 84px;
        }

        .question-part {
            margin-bottom: 94px;

            > h2 {
                margin: 0 0 16px;
                font-size: 28px;
                line-height: 36px;
            }

            .text-block {
                p {
                    font-size: 14px;
                }
            }
        }

        .QuestionListCard .question-card .text-block h4 {
            font-size: 18px;

            span {
                &:nth-child(2) {
                    margin-left: 15px;
                    @media screen and (max-width: 767px) {
                        margin: 0;
                    }
                }

                .profile-move-icon {
                    min-width: 29px;
                    max-width: 29px;
                    height: 29px;
                    margin-left: 11px;
                }
            }
        }
    }
}



@media screen and (max-width: $breakPoint-xl){
    .arrow-styles-for-slick {
        width: 30px;
        height: 30px;
        &:before {
            font-size: 17px;
        }
    }
}
@media screen and (min-width: $breakPoint-xl){
    .Profil {
        .slick-slider  {
            width: 100%;
            padding: 0 80px;
            .slick-slide {
                min-width: 256px;
            }
            .slick-track {
                justify-content: space-between;
                .card {
                    width: 100%;
                    max-width: 92%;
                    min-width: 265px;
                }
            }
        }
    }
}

@media screen and (max-width: $breakPoint-lg) {
    .Profil {
        .hr-carousel-main .hero-text-section .hero-text {
            font-size: 30px;
        }

        .hr-carousel-main .cards {
            top: 0;
            left: 0;
            margin: 0;
            padding: 0;
            position: relative;
            width: 100%;
            flex-direction: column;
            padding: 0 50px;
            box-sizing: border-box;
            margin-top: 40px;
            .column-component-body {
                flex-direction: column;
                .card {
                    width: 100% !important;
                    max-width: initial !important;
                    margin-bottom: 10px;
                }
            }
        }


        .slick-track {
            display: block ;
        }

        .quotes-image > img {
            width: 100%;
        }

        .benefits {
            margin: 0;
        }

        .benefits__card-container_right-image .icon-block {
            margin: 0;
            padding: 0;
        }

        .give-the-gift .icon-block {
            display: none;
        }

        .benefits .question-card .icon-block {
            width: 100%;
            padding-bottom: 40px;
            margin: 0;
            order: -1;
            img {
                width: 100%;
            }
        }

        .hr-main-content .question-part .flexible.horizontal > span > span {
            margin: 0 0 0 9px !important;
        }

        .hr-main-content .question-part .QuestionListCard .question-card > .icon-block {
            display: none;
        }

        .QuestionListCard {
            margin-top: 0;
        }

        .hr-main-columns .column-component-body .card {
            padding: 35px 30px;
            span {
                margin: 0;
                padding-right: 35px;
            }
            a {
                margin: 0;
            }
        }


        .question-card {
            flex-direction: column;
        }

        .quotes-image__content {
            margin: 0;
        }

        .quotes-image {
            padding: 30px 50px;
        }

        .hr-carousel-main {
            overflow: hidden;
        }

        .hr-main-columns .column-component-body {
            padding-top: 0;
        }

        .hero-text2 {
            display: none;
        }

        .quotes-image {
            flex-direction: column;
        }

        .question-card > .text-block {
            margin: 0;
            padding: 0
        }

        .hr-main-columns .column-component-body span {
            width: initial;
        }

        .slick-list {
            padding:50px 0;
            box-sizing: border-box;
        }

        .slick-slider {
            width: 100%;
        }

        .slick-slide {
            float: none;
            margin: 0 0 10px 0;
            & > div {
                display: block !important;
            }
        }

        .hr-main-columns {
            margin-top: 25px;
        }

        .hr-carousel-main .hero-text-section {
            padding: 1rem 50px;
            box-sizing: border-box;
            width: 100%;
            height: 60%;
            max-width: initial;
            justify-content: center;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            & > div {
                position: absolute;
                top: 15%;
                width: 74%;
            }
        }

        .hero-text-section-contacts {
            position: relative;
            top: 0;
            left: 0;
            flex-direction: column;
            padding: 0 50px;
        }

        .hr-carousel-back-button {
            margin: 0;
            position: relative;
            margin-top: 30px;
        }

        .hero-text-section-contacts .contact:last-child {
            margin: 0;
            margin-top: 20px;
        }

        .hero-text-section__user-name {
            margin-top: 0;
        }

        .hr-main-content .question-part {
            width: 100%;
            margin: 0;
            box-sizing: border-box;
            padding: 0 50px;
            .flexible.horizontal {
                flex-direction: column;
                & > span {
                    border: none;
                }
                & > span:first-child {
                    margin-bottom: 24px;
                }
            }
        }

        .image-block {
            overflow: hidden;
        }


        .GalHeader .relocation-info {
            flex-direction: column;
        }

        .three-cards {
            flex-direction: column;
            align-items: center;
            margin-top: 20px;
            .InfoCard {
                width: 100%;
                max-width: 100%;
                min-width: inherit;
                padding: 25px;
                height: 310px;
                h2 {
                    font-size: 26px;
                }
                p {
                    font-size: 14px;
                }
                span {
                    font-size: 14px;
                }
            }
        }
        .TextComponent {
            padding: 30px;
            width: calc(100% - 60px);
            .ordered-list-components {
                flex-flow: row wrap;
                .OrderedList {
                    min-width: 270px;
                }
            }
        }
        .get-proccess {
            margin: 0 30px;
        }

        .question-part {
            margin: 0 30px;
            margin-bottom: 30px;
            .question-block {
                flex-direction: column-reverse;
                .TaskListComponent, .HelpComponent {
                    max-width: 100%;
                }
            }
        }

        .question-card {
            padding: 0 50px;
        }

        .employee-stepper {
            margin: 30px 50px;
            &.domestic-initiate-step-single {
                .circle {
                    display: none;
                }
            }
        }
    }
}

@media screen and (max-width: $breakPoint-md) {
    .Profil {
        .gal-greeting-header {
            .relocation-info {
                flex-direction: column;
            }
        }
        .ordered-list-components {
            flex-direction: column !important;
            .OrderedList {
                width: 100%;
                padding: 1rem 0 !important;
                margin: 1rem 0!important;
            }
        }
        .MidSectionComponent {
            padding: 50px;
            width: 100%;
            box-sizing: border-box;
            .mid-section-body {
                flex-direction: column;
                .with-for-item {
                    flex-flow: row wrap;
                }
                .divider {
                    opacity: 0;
                }
                .pad-left {
                    padding: 0;
                }
            }
        }
        .question-part {
            margin: 0;
            padding: 50px;
            .question-block {
                padding: 0;
                .question-card {
                    padding: 0;
                }
            }
        }
    }
}

@media screen and (max-width: $breakPoint-sm) {
    .Profil .hr-carousel-main .hero-text-section > div {
        top: 7%;
    }

    .Profil .hr-carousel-main .hero-text-section .hero-text {
        font-size: 26px;
    }

    .Profil .hr-carousel-main .image-block {
        height: 220px;
    }

    .Profil {
        .employee-stepper {
            margin: 30px;
            &.domestic-initiate-step-single {
                .circle {
                    display: none;
                }
            }
        }
        .HelpComponent, .TaskListComponent {
            min-width: inherit;
        }
        h2 {
            font-size: 26px !important;
        }
        .how-the-process-works {
            padding: 0 30px;
            margin-bottom: 0;
            .card {
                max-width: 255px;
                > p {
                    height: 75px;
                }
            }
        }
        .MidSectionComponent {
            padding: 15px;
            .mid-section-header {
                width: 100% !important;
            }
        }
        .TextWithImage {
            > section {
                .image-block {
                    height: 235px;
                }
            }
        }
        .HelpComponent {
            .email-block {
                flex-direction: column;
                align-items: center;
                a {
                    margin-bottom: 10px;
                    &:last-child {
                        margin-bottom: none;
                    }
                }
            }
        }
        .QuestionListCard {
            width: 100%;
            .question-card {
                flex-direction: column;
                align-items: center;
                .text-block {
                    margin-left: 0;
                    h3 {
                        padding-left: 80px;
                    }
                }
            }
        }
        .EmployeeHero {
            .image-block {
                img {
                    max-width: inherit;
                }
            }
        }
        .question-part {
            padding: 15px 30px !important;
            .question-card {
                .text-block {
                    h4 {
                        padding: 0 20px;
                    }
                }
            }
        }

        .question-card {
            padding: 0 25px;
            .text-block {
                > div {
                    padding: 0 !important;
                }
            }
        }

        .Profil__benefits.question-card.benefits {
            .icon-block {
                display: flex;
                justify-content: center;
                img {
                    max-width: 100%;
                }
            }
        }
    }
}

//crosbrowser styles

.ie {
    #root {
        .Profil {
            > div {
                .question-part {
                    .question-block {
                        > div:first-child {
                            max-width: calc(100% - 450px);
                        }
                    }
                }
            }

            .hr-DomExploreStep, .move-info {
                &~.three-cards {
                    .InfoCard {
                        width: 50%;
                    }
                }
            }
        }
        .move-info__contacts {
            min-width: 460px;
            max-width: 460px;
        }
        .QuestionListCard {
            .question-card {
                padding: 0;
                .text-block {
                    max-width: calc(100% - (70px + 2rem)) !important;
                }
            }
        }
        .give-the-gift {
            .QuestionListCard {
                padding: 0 80px;
            }
        }
        .Profil__benefits {
            padding: 0 80px;
            .benefits__card-container {
                .question-card {
                    .text-block {
                        max-width: calc(100% - 322px);
                        width: 100%;
                    }
                }
            }
        }
        .question-block {
            justify-content: space-between;
            > div:first-child {
                max-width: calc(100% - 450px);
                margin-right: 65px;
                flex-shrink: 0;
                .QuestionListCard {
                    margin-top: 0;
                }
                .text-block {
                    max-width: calc(100% - (70px + 2rem));
                    flex-shrink: 0;
                    flex-grow: 0;
                }
            }
            > div:last-child {
                max-width: 372px;
            }
        }
        .three-cards {
            .InfoCard {
                max-width: calc(33.3% - 120px);
                display: flex;
                .info-card-block {
                    max-width: 100%;
                    flex-shrink: 0;
                }
            }
            flex-direction: row;
        }
        .int-explore-step {
            .question-part {
                .question-block {
                    > div:first-child {
                        width: 100%;
                        max-width: inherit !important;
                    }
                }
            }
        }
        .questions-accordion {
            .Accordion {
                max-width: calc(100% - 460px);
                width: 100%;

            }
        }
        .Accordion {
            .accordion-item {
                .QuestionListCard {
                    width: 100%;
                    .question-card {
                        .text-block {
                            max-width: 100% !important;
                            > h4 {
                                span {
                                    white-space: nowrap;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
@media screen and (min-width: 1024px) {
    .Profil {
        padding-bottom: 60px;
        .three-cards {
            flex-direction: initial;
            .InfoCard{
                width: 33.33%;
            }
        }
    }
}
@media screen and (max-width: 991px) { 
    .your-talent-moves-container {
        .your-talent-move {
            .additional-info {
                .delete-move {
                    margin: 0 0 10px 0;
                }
            }
        }
    }
    .Profil {
        .three-cards {
            .InfoCard{
                height: 180px;
                .info-card-block {
                    p {
                        margin-bottom: 1px;
                        line-height: 17px;
                    }
                }
                span {
                    margin-bottom: 10px;
                }
            }
        }
    }
}
@media screen and (max-width: 767px) {
    .your-talent-moves-container {
        .your-talent-move {
            .services, .moves, .move-status, .additional-info {
                width: 100%;
                margin-left: 5px;
                .delete-move {
                    margin: 0 0 10px 0;
                    width: 80px;
                }
            }
        }
    }
}
@media screen and (max-width: 600px) {
    .Profil {
        .three-cards {
            .InfoCard{
                height: 240px;
                .info-card-block {
                    p {
                        margin-bottom: 1px;
                    }
                }
                span {
                    margin-bottom: 5px;
                }
            }
        }
    }
 }