@import "~main.scss";

.initiate-a-move-modal-window {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: $mainBackgroundColor;
  padding: 40px 40px 0 60px;

  .Icon {
    cursor: pointer;
  }

  .QuestionListCard {
    margin-bottom: 0;
  }

  .icon-block {
    display: none;
  }

  .text-block {
    margin: 0 !important;
    padding: 0 !important;
  }
  .question-part__list-container {
    margin-bottom: 1rem !important;
  }
  .question-part__list-container__lists {
    display: flex;
    justify-content: space-between;
    padding-right: 60px;
  }

  .question-parts {
    margin: 0 !important;

    &__list {
      display: block !important;
      padding-top: 10px;
      li {
        padding: 2px 0px;
        display: flex !important
      }

      li::before {
        display: initial !important;
      }
    }

    &__link {
      color: $blueText;
      cursor: pointer;
    }

    &__button {
      background-color: $buttonsColor;
      padding: 20px 30px;
      margin-top: 10px !important;
      font-size: $mediumSize !important;	
      font-weight: bold;	
      line-height: 20px;
      z-index: 1;
      
      span {
        font-size: $mediumSize !important ;	
      }
    }
  }

  &__container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
  }

  &__background {
    width: 100%;
    height: 100%;
    background-color: $textColor;
    opacity: 0.7;
  }

  &__header {
    font-size: 36px;
    color: $blueText;
    display: flex;
    justify-content: space-between;
  }

  &__description {
    margin-top: 25px;
    font-weight: 100;
  }

  &__checkbox {
    display: flex;
    align-items: center;
    padding: 15px 0;
    margin: 25px 0;
    border-top: 1px solid $gainsboro;
    border-bottom: 1px solid $gainsboro;
    cursor: pointer;
  }
}