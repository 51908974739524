.pre-initiate-move-container {
    .recruiter-knockout {
        h1 {
            margin-top: 30px;
            letter-spacing: -0.5px;
            line-height: 48px;
        }
        .add-info-list {
            margin-top: 25px;
            p.title {
                color: #212121;
                font-size: 20px;
                line-height: 30px;
                margin-bottom: 204px;
                font-weight: 300;
	            line-height: 30px;
            }
        }
        .line {
            box-sizing: border-box;
            height: 1px;
            border: 1px solid #E3E3E3;
        }
        .submit-button {
            background-color: #ca001b;
            box-shadow: none;
            padding: 13px 30px;
            margin-top: 40px;
            color: #fff;
            font-size: 16px;
            font-weight: bold;
            border: 0;
            float: right;
            line-height: 20px;
            text-align: center;
            cursor: pointer;
        }
    }
}

@media screen and (max-width: 768px) {
    .pre-initiate-move-container {
        .recruiter-knockout {
            h1 {
                font-size: 25px;
                line-height: 30px;
            }
        }
    }
}
