@import "~main.scss";

.TextWithLabel {
  height: auto;
  .label{
    font-size: $smallSize;
    color: $placeHolderColor;
  }
  .text {
    font-size: $subTitleSize;
    font-weight: 900;
  }
}