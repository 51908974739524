@import "~main.scss";

.BenefitInformation {
  padding: 72px 150px;
  flex-grow: 1;
  > h1 {
    font-size: 44px;
  }
  .Tabs {
    justify-content: flex-start;
    .TabItem {
      font-weight: bold;
    }
  }
  .Resources {
    padding: 0;
    padding-top: 57px;
    .resource-item {
      margin-bottom: 90px;
      &:last-child {
        margin-bottom: 0;
      }
      .title {
        margin-bottom: 50px;
        span:not(.Icon) {
          font-size: $bigTitleSize;
          color: $blueText;
          font-weight: 600;
          margin-left: 36px;
        }
      }
      .resource-item-info {
        margin-left: 100px;
        border-bottom: 1px solid $borderColor;
        padding-bottom: 32px;
        margin-bottom: 32px;
        cursor: pointer;
        &:last-child {
          margin-bottom: 0;
        }
        .name {
          font-size: 28px;
          color: $blueText;
          max-width: 475px;
        }
        .type {
          font-size: $mediumSize;
          font-weight: 100;
          .Icon {
            margin-left: 160px;
            cursor: pointer;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $breakPoint-lg) {
  .BenefitInformation {
    .ManageYourHealth {
      .text-block {
        flex-direction: column;
        align-items: center;
        .image {
          margin-top: 50px;
        }
      }
    }
    .Resources {
      .resource-item {
        .resource-item-info {
          margin-left: 0;
          .name {
            font-size: 20px;
            padding-right: 24px;
          }
          .type {
            .Icon {
              margin-left: 60px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: $breakPoint-md) {
  .BenefitInformation {
    padding: 42px 20px;
    .header-text {
      font-size: 36px;
    }
    .resource-item {
      .Icon {
        svg {
          width: 45px;
        }
        & ~ span {
          font-size: 30px !important;
        }
      }
    }
  }
}
