@import '~main.scss';

.DropDown {
  height: 30px;
  width: 120px;
  margin: 10px;
  cursor: pointer;

  .isOpen {
    color: $blueText;
    &~.Icon {
      transform: rotate(180deg);
    }
  }

  .label {
    font-size: $mediumSize;
    font-weight: bold;
  }

  &.material {
    height: 55px;
    box-sizing: border-box;
    border-bottom: 2px solid $borderColor;
    position: relative;
    .arrow:after {
      content: '‣';
    }
    .staticLabel {
      position: absolute;
      top: 16px;
      transition: all 0.2s linear;
      font-size: $largeSize;
      color: $placeHolderColor;

      &.selected {
        top: 0px;
        font-size: 13px;
      }
    }
  }
  .arrow {
    font-size: 22px;
    font-weight: bold;
    color: #CA001B;
    font-style: normal;
    &:after {
      content: '›';
      display: block;
      margin-left: 10px;
      transform: rotate(90deg);
    }
    &.arrow-up:after {
      transform: rotate(-90deg);
    }
  }
}

.dropdown-block {
  display: flex;
  max-width: 1280px;
  width: 100%;
}
