.link-button {
    cursor: pointer;
    display: flex;

    &__text {
        margin-top: 5px;
    }

    &__image {
        margin-left: 8px;
        width: 20px;
        height: 26px;
    }
}