@import "~main.scss";

.contacts {
    &__header {
        color: $subtitleColor;
        font-weight: bold;
        margin-left: 16px;
    }

    &__icon {
        margin: 5px 15px 0 0;
    }

    &__content {
        display: flex;
        align-items: flex-start;
        margin-top: 10px;
    }

    &__contact {
        margin-top: 15px;

        &:first-child {
            margin-top: 0;
        }

        &-header {
            font-weight: 900;
        }

        &-header, &-items {
            margin-top: 4px;

        }
        &-header, &-content {
            line-height: 22px !important;
        }
        &-content {
            font-weight: 300;
            &_link {
                color: $blueText;
                display: block;
            }
        }
    }
}
