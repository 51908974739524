.ie {
    .site-overview-content {
        .slick-slider {
            height: 100%;
            .slick-list {
                height: 100%;
            }
        }
    }
}
