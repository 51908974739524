@import "~main.scss";

.Accordion {
  .accordion-item {
    .accordion-body {
      .accordion-body-item {
        .info-block {
          .ColumnComponent {
            .card {
              height: 345px !important;
            }
          }
        }
      }
    }
  }

  .accordion-item {
    overflow: hidden;
    &.open {
      .accordion-header {
        border-color: $blueText;
        .title {
          color: $blueText;
        }
      }
      .accordion-body {
        display: flex;
      }
    }
    .accordion-header {
      cursor: pointer;
      border-bottom: 1px solid $borderColor;
      position: relative;
      z-index: 5;
      height: 67px;
      background-color: $white;
      .title {
        font-weight: 100;
        font-size: $largeSize;
        z-index: 6;
      }
      .icons-block {
        cursor: pointer;
      }
    }
    .regional-mobility {
      max-width: 100%;
      width: calc(100% - 80px);
      margin: 0 auto !important;
      .slick-slider {
        width: 100%;
          .slick-prev {
              transform: translateY(-50%);
              top: 50%;
              opacity: 1;
              left: -40px;
              z-index: 5;
              &:before {
                  content: "\2190";
                  display: block;
                  color: $buttonsColor;
                  font-size: 38px;
              }
          }
          .slick-next {
              transform: translateY(-50%);
              top: 50%;
              opacity: 1;
              right: -20px;
              &:before {
                  content: "\2192";
                  display: block;
                  color: $buttonsColor;
                  font-size: 38px;
              }
          }
      }
      .card {
        max-width: 278px;
      }
    }
    .accordion-body-item-static {
      border-bottom: 1px solid $borderColor;
      padding: 2rem 0;
      .info-block {
        margin-bottom: 2rem;
        line-height: 25px;
        h3 {
          font-weight: bold;
          font-size: 20px;
        }
        a {
          color: $blueText;
        }
        .body-phone {
          margin-top: .7rem;
          .Icon {
            height: 24px;
          }
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
      &:last-child {
        border: none;
      }
      .title {
        color: $subtitleColor;
        font-size: $mediumSize;
        margin-bottom: 23px;
      }
    }
    .accordion-body {
      .Link{
        margin-top: 1rem;
      }
      padding: 30px 0;
      display: none;
      cursor: initial;
      p {
        line-height: 28px;
        font-size: $mediumSize;
        max-width: 730px;
      }
      .accordion-body-item {
        padding: 30px 0;
        padding-top: 5px;
        border-bottom: 1px solid $borderColor;
        &:last-child {
          border: none;
        }
        .jnj-grid-column:first-child {
              margin-right: 30px;
        }
        .subtitle{
          color: $subtitleColor;
          font-size: $mediumSize;
          margin-bottom: 23px;
        }
        .body-title {
          font-size: $subTitleSize;
          margin-bottom: 2px;
          font-weight: bold;
        }
        .site {
          color: $blueText;
          margin-bottom: 10px;
        }
        .info-block {
          margin-top: 10px;
          .ColumnComponent{
            margin:0px;
            padding:0px;
            .column-component-body{
              padding:0px;
            }
            .card{
              height:240px;
              width:200px;
            }
          }
          .body-contacts {
            padding-right: 10px;
            border-right: 1px solid $borderColor;
          }
          .Icon {
            margin-right: 10px;
          }
          .body-phone {
            padding: 0 10px;
            border-right: 1px solid $borderColor;
            ul {
              margin-bottom: 1rem;
            }
          }
          .body-email {
            padding-left: 10px;
            color: $blueText;
            a {
              color: $blueText;
            }
          }
          .move-type-icon {
            width: 20px;
            height: 20px;
          }
          .team-area-left {
            width: 14%;
            float: left;
          }
          .team-area-right {
            width: 84%;
            float: left;
            line-height: 19px;
          }
        }
        .mobConnect-one {
          margin: 0px 35px 0px 16px;
        }
      }
    }
  }
}

@media screen and (max-width: $breakPoint-sm) {
    .regional-mobility {
        .slick-slider {
            .card {
                max-width: 260px !important;
                padding: 20px;
            }
        }
    }
    .Accordion {
      .accordion-item {
        .accordion-body {
          .accordion-body-item {
            .mobConnect-one {
              margin-top: 100px;
            }
            .mobConnect-one-email {
              margin-left: 15px;
              margin-top: 20px;
            }
          }
        }
      }
    }
}

.ie {
  #root {
    .Accordion {
      .accordion-item {
        border-bottom: 1px solid $borderColor;
        &.open {
          border: none;
        }
        .accordion-header {
          .title {
            z-index: 6;
          }
        }
        .accordion-body {
          z-index: 7;
          border-top: 1px solid $blueText;
        }
      }
    }
  }
}
