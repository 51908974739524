@import '~main.scss';

.TitleWithIcon {
  margin-bottom: 10px;
  .icon-block {
    width: 100px;
    >img {
      max-width: 71px;
      max-height: 71px;
    }
  }
  .title-block {
    .title {
      font-size: $bigTitleSize;
      color: $blueText;
      font-weight: 900;
    }
  }
}

@media screen and (max-width: $breakPoint-lg) {
  .TitleWithIcon {
    .icon-block {
      width: 70px;
      >img {
        max-width: 50px;
        max-height: 50px;
      }
    }
    .title-block {
      .title {
        display: flex;
        align-items: center;
        font-size: 30px;
      }
    }
  }
}
