@import "~main.scss";

.hr-carousel-main {
    &__footer {
        .hero-text2 {
            font-size: 16px;
        }
    }
}

.CarouselMain {
    .CarouselCard {
        display: flex;
        position: relative;
        cursor: pointer;
        border: 1px solid $borderColor;
        &:hover {
            border-color: $buttonsColor;
        }
        .LinkIcon {
            // right: 20px;
            // position: absolute;
            // bottom: 20px;
        }
        .CarouselItemText{
            font-weight: 500;
        }
        @media screen and (max-width: $breakPoint-md) {
            &Icon {
                margin-right: 25px;
            }
            @include paddingHorizontal(25px);
            @include paddingVertical(20px);

            .LinkIcon {
                display: none;
            }
        }
        @media screen and (min-width: $breakPoint-md) {
            @include paddingHorizontal(39px);
            @include paddingVertical(39px);
            flex-direction: column;
        }
    }

    &.new-flow {
        .hr-carousel-main {
            .image-block {
                height: 450px;
            }

            .hero-text-section {
                .user-name {
                    margin-top: 76px;
                    line-height: 36px;
                }

                .hero-text {
                    letter-spacing: -0.5px;
                }

                .initiate-a-move {
                    max-width: 186px;
                    padding: 1.1rem 1.5rem;
                    margin-top: 28px;
                    font-weight: bold;
                    box-sizing: border-box;
                    line-height: 20px;

                    span {
                        font-size: 16px;
                    }
                }
            }
        }
    }
}


.hero-text-section-full {
    max-width: 1120px !important;
    .pad-right {
        width: 65%;
    }
    .pad-left {
        width: 40%;
        border-left: 1px solid #d8d8d8;
        padding-left: 85px;

        p {
            font-size: $mediumSize;
            font-weight: 300;
            line-height: 26px;
        }

        .initiate-a-move-checkbox {
            display: flex;
            align-items: center;
            margin-top: 25px;
            p {
                font-size: $mediumSize;
                font-weight: 500;
                letter-spacing: -0.33px;
                line-height: 20px;
                padding-right: 20px;
                cursor: pointer;
            }
        }
        .initiate-a-move-answer {
            color: #000099;
            font-size: $smallSize;
            font-weight: 300;
            line-height: 18px;
            cursor: pointer;
            margin-left: 48px;
        }
    }
    .hero-text2 {
        max-width: 90% !important;
        .vertical:first-child {
            width: 60%;
        }
    }
    a {
        color: $blueText;
        font-size: $smallSize;
        font-weight: 300;
        line-height: 18px;
        cursor: pointer;
    }
    .Button {
        background-color: $buttonsColor;
        padding: 20px 30px;
        margin-top: 10px !important;
    }

    .list {
        :first-child {
            max-width: 80%;
        }
        > li {
        display: flex;
        font-size: $smallSize;
        font-weight: 300;
        line-height: 20px;
        margin-bottom: 5px;
        &::before {
            content: "•";
            display: block;
            color: $buttonsColor;
            margin-right: 10px;
        }
        }
    }
}
.hero-text-section {
    &__user-name {
        margin-top: 50px;
    }

    &-contacts {
        top: 92px;
        right: 60px;
        display: flex;
        position: absolute;

        .contact {
          &:last-child {
            margin-left: 62px;
        }
    }
  }
}

.hr-main-columns {
   .link-icon-flex{
    display: flex;
    justify-content: space-between;
   } 
   margin-top: auto;

    @media screen and (max-width: $breakPoint-md) {
        @include paddingHorizontal(25px);
    }
    @media screen and (min-width: $breakPoint-md) {
        @include paddingHorizontal(80px);
    }

    > div {
        > div {

        }
    }

    .divider{
        box-sizing: border-box;
        border: 1px solid #FFFFFF;
        opacity: 0.5;
    }
}

@media screen and (max-width: $breakPoint-sm) {
    .hero-text-section-full {

        .flexible:first-child {
            display: block;
        }
        .pad-right {
            width: 100%;
            display: block;
        }
        .pad-left {
            display: block;
            width: 100%;
            border-left: 0px;
            padding-left: 0px;
            padding-top: 5px;
        }

    }
    .hr-main-columns{
        .btnIcon {
            padding-right: 0px !important;
        }
    }
}
