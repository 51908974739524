@import "~main.scss";

.IconsList {
  padding: 72px 150px;
  flex-grow: 1;
  > h1 {
    color: $blueText;
    font-size: 44px;
    margin-bottom: 40px;
  }
  .icon-list {
    flex-flow: row wrap;
    .icon-block {
      width: 140px;
      height: 100px;
      border: 1px solid $borderColor;
      border-radius: 2px;
      margin: 10px;
      padding: 10px;
      background-color: $mainBackgroundColor;
    }
  }
}