@import '~main.scss';

.hr-journey-back {
  .help_content {
    margin: 20px 80px 70px 80px;
    padding: 35px 85px;
    background-color: $backgroundColor;
    .title {
      text-align: center;
      color: $blueText;
      font-size: $subTitleSize;
      font-weight: 900;
    }
    .divider {
      width: 50px;
      height: 2px;
      margin: 20px 0;
      background-color: $blueText;
    }

    .help-item {
      flex: 1;
      &:not(last-child) {
        margin-right: 65px;
      }
      .icon{
        margin-bottom: 25px;
        svg {
          width: 50px;
          height: 50px;
        }
      }
      h4 {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 5px;
      }
      p {
        font-weight: 300;
        line-height: 1.5;
      }
    }
  }

  .manager-tasks-content {
    .questions-tasks-block {
      padding: 0 50px;
      width: 65%;
      .tasks-block {
        margin-bottom: 40px;
        .description {
          font-size: $subTitleSize;
          font-weight: 300;
          line-height: 1.5;
          margin-bottom: 25px;
        }
        .hr-tasks {
          > div {
            margin-bottom: 35px;
            > span {
              margin-right: 20px;
              display: flex;
              flex: 1 0 auto;
              align-items: center;
              justify-content: center;
              border: 2px solid $subtitleColor;
              border-radius: 100%;
              font-size: $subTitleSize;
              font-weight: bold;
              height: 45px;
              width: 45px;
            }
            h4 {
              font-size: $mediumSize;
              font-weight: 900;
              margin-bottom: 10px;
            }
            p {
              font-weight: 300;
              line-height: 1.6;
            }
          }
        }
      }

      .questions-block {
        margin-bottom: 80px;
        .questions-list {
          .iconblock {
            margin-right: 20px;
            svg {
              width: 50px;
              height: 50px;
            }
          }
          .title-text {
            padding: 30px 0;
            font-weight: 900;
          }
          .question-description {
            font-weight: 300;
            line-height: 1.7;
            padding-left: 70px;
            .Link {
              margin-top: 20px;
            }
          }
        }
      }
    }
    .MobilityConnectBlock {
      width: 370px;
      margin-right: 50px;
    }
  }
}

@media screen and (max-width: $breakPoint-lg) {
  .hr-journey-back {
    .help_content {
      margin: 30px;
      padding: 25px;
      > div {
        flex-direction: column;
      }
      .help-item {
        .icon {
          margin-bottom: 10px;
        }
        &:not(last-child) {
          margin-right: 0px;
          margin-bottom: 25px;
        }
      }
    }
    .manager-tasks-content {
      flex-direction: column;
      .questions-tasks-block {
        padding: 30px;
      }
      .MobilityConnectBlock {
        width: 100%;
        margin-right: 0;
      }
    }
  }
}
