@import "~main.scss";

.autosuggest-container {
    position: relative;
    width: 473px;
    .select-field-spinner {
        position: absolute;
        top: 16px;
        right: 35px;
    }
    @media all and (max-width: $small) {
        width: 100%;
    }

    .close-icon {
        position: absolute;
        right: 10px;
        top: 10px;
        z-index: 5;
        cursor: pointer;
    }
}

.combo-box-container {
    position: relative;
    z-index: 1;
    width: 473px;

    @media all and (max-width: $small) {
        width: 100%;
    }
}

.combobox-item {
    justify-content: space-between !important;
    @include prefixer(flex-pack, space-between);
    box-shadow: inset 0 0 0 0 #d8d8d8, inset 0 0 0 0 #d8d8d8,
        inset 0 0 0 0 #d8d8d8, 0 0 1px 0 rgba(0, 0, 0, 0.5);
    padding: 11px 19px 9px 13px !important;

    &:hover {
        background-color: #e6e7f5 !important;
    }

    span {
        font-size: 14px;
        font-weight: 300;
        line-height: 24px;
        color: $blueText;

        &.combobox-item-position {
            font-size: 12px;
            line-height: 20px;
            width: 242px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            @media all and (max-width: $small) {
                width: 150px;
            }
        }
    }
}

.combo-box-extra-data {
    margin-top: 30px;
    width: 473px;

    @media all and (max-width: $small) {
        width: 100%;
    }

    .extra-item {
        border-bottom: 2px solid $darkGrey;
        padding-bottom: 5px;
        color: $darkGrey;
        font-size: 14px;
        line-height: 24px;

        &:nth-of-type(1) {
            flex-grow: 3;
            @include prefixer(flex-positive, 3);
        }

        &:nth-of-type(2) {
            margin-left: 32px;
            flex-grow: 1;
            @include prefixer(flex-positive, 1);
        }

        h6 {
            color: $darkGrey;
            font-size: 12px;
            font-weight: 300;
            line-height: 18px;
            margin-bottom: 7px;

            span {
                color: $darkGrey;
            }
        }
    }
}

.input {
    padding: 0 0 12px 0;
    font-size: 18px;
    font-weight: 300;

    &:before {
        border-bottom-width: 2px;
    }
}

.input-top-container {
    > div {
        &:before {
            border-bottom: 2px solid rgba(0, 0, 0, 0.42);
        }

        &:after {
            border-bottom: 2px solid rgba(0, 0, 0, 1);
        }
    }
}
