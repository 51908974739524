.pre-initiate-move-container {
    .people-leader-container {
        h1 {
            margin-top: 30px;
            letter-spacing: -0.5px;
            line-height: 48px;
            font-size: 44px;
	        font-weight: 900;
        }
        .CardSection {
            margin-top: 50px;
        }
        .optionSelectionCard {
            border: 1px solid #D8D8D8;
        }
    }
}

@media screen and (max-width: 768px) {
    .pre-initiate-move-container {
        .people-leader-container {
            h1 {
                font-size: 25px;
                line-height: 30px;
            }
            .optionSelectionCard {
                border: 1px solid #D8D8D8;
            }
        }
    }
}
