@import "~main.scss";

.TaskCard {
    background-color: #f4f4f4;
    max-width: 33.3%;
    margin: 1rem;
    padding: 1.5rem;
    cursor: pointer;
    
    &:hover {
        box-shadow: 1px 2px 4px rgba(0,0,0,.25);
    }

    .task-card-content {
      > h1 {
          margin-bottom: 5px;
          font-weight: bold;
          color: #000099;
          font-size: $mediumSize;
      }
      > span {
          font-weight: bold;
          color: #888b8d;
      }
    }
}