@import '~main.scss';

.ArticleDetail {
  .article-content {
    margin: 50px 0;
    line-height: 1.5;

    .link {
      color: $blueText;
    }

    >.description-with-text {
      font-weight: 300;
      padding: 0 150px;
      .article-description {
        margin-right: 50px;
        font-size: $subTitleSize;
      }
      .quote {
        border-bottom: 2px solid $borderColor;
        padding-bottom: 30px;
        width: 250px;
        * {
          color: $buttonsColor;
        }
        .text {
          font-size: $subTitleSize;
        }
        .apostrophe {
          font-size: 80px;
          line-height: 20px;
          margin-top: 30px;
        }
      }
    }

    .article-info-block {
      flex-wrap: wrap;
      .article-info {
        margin-right: 50px;
        padding-left: 150px;
        flex: 1;
        order: 1;
        .article-info-item {
          margin-top: 40px;
          .title {
            color: $blueText;
            font-size: $subTitleSize;
            font-weight: 900;
            margin-bottom: 15px;
          }
          .description {
            font-weight: 300;
          }
          .list-with-title {
            margin-top: 20px;
            .list-title {
              margin-bottom: 10px;
            }
            .list {
              .list-item {
                display: flex;
                font-weight: 300;
                line-height: 1.8;
                &:not(last-child) {
                  margin-bottom: 15px;
                }
                &:before {
                  content: "•";
                  display: block;
                  color: $buttonsColor;
                  margin-right: 10px;
                }
              }
            }
          }
        }
      }
      .recommendations {
        width: 250px;
        margin-right: 150px;
        box-sizing: border-box;
        order: 2;

        >.title {
          margin-top: 40px;
          color: $subtitleColor;
          font-size: $largeSize;
          font-weight: bold;
        }

        .rec-articles {
          .rec-article-item {
            cursor: pointer;
            width: 100%;
            margin-top: 20px;
            &:first-child {
              flex-direction: column;
              .article-image {
                max-width: 100%;
                margin-right: 0;
              }
              .article-name {
                font-size: $mediumSize;
              }
            }

            .article-name {
              font-size: $smallSize;
              font-weight: 900;
            }

            .article-image {
              max-width: 40%;
              margin-right: 15px;
              img {
                width: 100%;
                height: auto;
              }
            }
          }
        }
      }
      .pagination {
        padding: 30px 150px;
        margin-top: 50px;
        background-color: $backgroundColor;
        width: 100%;
        order: 3;

        .text-right {
          text-align: right;
        }
        .text-left {
          text-align: left;
        }

        .step {
          width: 40%;
          cursor: pointer;

          p { line-height: 1.4 };

          .shadow {
            border-radius: 100%;
            box-shadow: 1px 1px 10px silver;
          }

          .step-name {
            .step-type {
              font-weight: bold;
              color: $subtitleColor;
            }
            .article-name {
              font-weight: 900;
            }
          }

          .Icon {
            width: 50px;
            height: 50px;
            background-color: white;
            border-radius: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          &.prev {
            .shadow {
              margin-right: 25px;
            }
            .Icon {
              transform: rotate(90deg);
            }
          }

          &.next {
            .shadow {
              margin-left: 25px;
            }
            .Icon {
              transform: rotate(-90deg);
            }
          }
        }
        .divider {
          height: 80%;
          width: 2px;
          background-color: $borderColor;
        }

      }
    }
  }
}

@media screen and (max-width: $breakPoint-lg) {
  .ArticleDetail {
    .article-content {
      >.description-with-text {
        flex-direction: column;
        padding: 0 25px;

        .article-description {
          font-size: $mediumSize;
          padding-bottom: 30px;
          margin-right: 0;
          border-bottom: 2px solid $borderColor;
        }
        .quote {
          padding:30px 0;
          width: 100%;
        }
      }

      .article-info-block {
        .article-info {
          margin-right: 0;
          padding: 25px;
          order: 1;
        }
        .recommendations {
          width: 100%;
          margin-right: 0;
          padding:0 25px;
          order: 3;

          .rec-articles {
            .rec-article-item {
              &:first-child {
                flex-direction: row;
                .article-image {
                  max-width: 40%;
                  margin-right: 15px;
                }
                .article-name {
                  font-size: $smallSize;
                }
              }
            }
          }
        }
        .pagination {
          padding: 0 25px;
          height: 90px;
          order: 2;

          .divider {
            height: 50%;
          }

          .step {
            .article-name {
              display: none;
            }
          }
        }
      }
    }
  }
}
