@import "~main.scss";

.ManagerCard {
    padding: 3.5rem 2.5rem;
    max-width: 33.3%;
    background-color: blue !important;
    height: 250;
    width: 33.3%
  }

.IdentificationPage {
  .identification-content {
    flex-wrap: wrap;
    padding-top: 60px;
    padding-bottom: 60px;
    .InfoCard {
      max-width: 573px;
      height: 45vw;
      width: 45vw;
      max-height: 574px;
      box-sizing: border-box;
      display: flex;
      padding: 6.3vw 4.7vw;

      .info-card-block {
        justify-content: center;

        h2 {
          font-weight: 900;
          letter-spacing: -0.5px;
          line-height: 3.75vw;
          font-size: 3.45vw;
        }
        p.truncate{
          font-size: 1.5625vw;
        }
        .arrowRight {
          span {
            margin-bottom: 0px !important;
          }
        }
      }

      .InfoCardHeader {
        color: white;
        display: flex;
        flex-direction: row;
        align-items: flex-end;

        .arrowRight {
          border-radius: 100%;
          margin-left: 1.172vw;
          margin-bottom: .547vw;
          transform: rotate(-90deg);
          display: inline-flex;
          align-items: center;
          justify-content: center;
          height: 2.11vw;
          width: 2.11vw;
          background-color: white;
          &>.Icon>svg{
            width:0.9375vw;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1281px) {
  .IdentificationPage {
    .identification-content {
      .InfoCard {
        height: 574px;
        width: 573px;
        padding: 80px 60px;

        .info-card-block {

          h2 {
            line-height: 48px;
            font-size: 44px;
          }
          p.truncate{
            font-size: 20px;
          }
        }

        .InfoCardHeader {


          .arrowRight {
            margin-left: 15px;
            margin-bottom: 7px;
            height: 27px;
            width: 27px;
            &>.Icon>svg{
              width:12px;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: $breakPoint-sm) {
  .IdentificationPage {
    .identification-content {
      .InfoCard {
        height: 325px;
        width: 325px;
        padding: 83px 43px;
        .info-card-block {

          h2 {
            font-size: 30px;	font-weight: 900;	letter-spacing: -0.5px;	line-height: 34px;
          }
          p.truncate{
            font-size: 16px;	font-weight: 300;	line-height: 26px;
            margin-bottom: 0;
          }
        }
        .InfoCardHeader {


          .arrowRight {
            margin-left: 8px;
            margin-bottom: 4px;
            height: 21px;
            width: 21px;
            &>.Icon>svg{
              width:9px;
            }
          }
        }
      }

    }
  }
}
//@media screen and (max-width: $breakPoint-lg){
//  .IdentificationPage {
//    .identification-content {
//      padding: 20px 15px;
//      .InfoCard {
//        height: 325px;
//        width: 325px;
//        max-width:325px;
//      }
//    }
//  }
//}

.ie {
  #root {
    .IdentificationPage {
      min-height: calc(100vh - 281px);
      .InfoCard {
        .info-card-block {
          width: 100%;
          max-width: 100%;
        }
        p {
          width: 100%;
          max-width: 100%;
        }
      }
    }
  }
}
