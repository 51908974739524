@import "~main.scss";

.ExpenseReport {
  padding: 72px 150px;
  flex-grow: 1;
  .HelpComponent {
    .info-block{
      .text-block {
        > h2 {
          color: $subtitleColor;
        }
      }
    }
    .email-block {
      justify-content: center;
      a {
        margin-bottom: 15px;
        color: $blueText;
        span {
          color: $blueText;
        }
      }
    }
  }
  .Table {
    tr {
      td {
        .date {
          color: $darkGrey;
          font-size: $mediumSize;
          margin-top: 5px;
        }
        .Icon {
          margin: 0 6px
        }
      }
    }
  }
  > h1 {
    color: $blueText;
    font-size: 44px;
    margin-bottom: 40px;
  }
  .description-expense {
    font-size: $subTitleSize;
    margin-bottom: 50px;
    line-height: 30px;
  }
  .expense-report-body {
    align-items: flex-start;
    .DropDown {
      display: none;
    }
    .Button {
      margin-left: 10px;
    }
  }
  .expense-reports-table {
    margin-top: 30px;

    .expense-report-name {
      max-width: 350px;
      font-size: 24px;
      color: $blueText;
      a {
        color: $blueText;
      }
    }
    .submitted_amount, .approved_amount {
      font-size: $subTitleSize;
    }
    .status, .payment_status {
      font-size: $smallSize;
      color: $subtitleColor;
    }
  }
  .move-content {
    border-bottom: 1px solid $borderColor;
    margin-bottom: 25px;
    padding-bottom: 20px;
    .remove-move {
      b {
        font-size: $mediumSize;
        cursor: pointer;
        &:after {
          content: '>';
          width: 21px;
          height: 21px;
          display: flex;
          background-color: $buttonsColor;
          border-radius: 100%;
          justify-content: center;
          align-items: center;
          color: $white;
          margin-left: 10px;
        }
      }
    }
    .country {
      .image-block {
        img {
          width: 40px;
          min-width: 40px;
          height: 40px;
          border-radius: 100%;
        }
      }
      .text {
        margin-left: 12px;
        text {
          font-weight: 100;
          font-size: $subTitleSize;
        }
        span {
          font-weight: 100;
          font-size: $smallSize;
          color: $darkGrey;
        }
      }
    }
  }
}

.expense-report-pagination {
  height: 88px;
  padding: 0 15px;
  display: none;
  .pagination-item-info {

  }
  .pagination-counts {
    display: flex;
      span {
        margin-left: 15px;
        font-weight: bold;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        &.active {
          color: $blueText;
          &:after {
            content: '';
            height: 2px;
            width: 100%;
            background-color: $blueText;
          }
        }
      }
  }
}

.expense-report-table-mobile {
  padding: 15px;
  background-color: $mainBackgroundColor;
  height: 70px;
  box-sizing: border-box;
  display: none;
  & ~ .expense-report-mobile {
    height: 88px;
    box-sizing: border-box;
    padding: 13px 0;
    margin: 0 15px;
    border-bottom: 2px solid $borderColor;
    display: none;
    .info {
      max-width: 50%;
    }
    .price {
      max-width: 50%;
    }
    .key {
      text-transform: capitalize;
      color: $subtitleColor;
      font-size: 14px;
      margin-bottom: 8px;
    }
    .name {
      font-size: 18px;
      color: $blueText;
    }
    .Icon {
      margin-left: 1rem;
    }
  }
}

.Popup {
  max-width: 740px;
  padding: 3rem;
  .title {
    max-width: 80%;
  }
}

.ie {
  #root {
    .ExpenseReport {
      min-height: calc(100vh - 281px);
    }
  }
}

@media only screen and (max-width: 1024px) {
  .ExpenseReport {
    padding: 72px 40px;
    .expense-report-body {
      flex-flow: row wrap;
      justify-content: center;
      > div:first-child {
        margin-bottom: 1rem;
      }
    }
    .move-content {
      .country {
        padding-right: 1rem;
        .text {
          text {
            display: flex;
            flex-flow: row wrap;
            b {
              margin-right: 5px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 780px) {
  .expense-report-mobile-select {
    width: calc(100vw - 130px);
    left: 65px;
    box-shadow: $material-header-shadow;
    ul {
      li {
        max-width: 100% !important;
      }
    }
  }
  .ExpenseReport {
    .HelpComponent {
      min-width: inherit;
      .help-component-block {
        flex-direction: column;
        .info-block {
          padding-bottom: 20px;
          .image-block {
            min-width: 100px;
            width: 100px;
            height: 100px;
          }
          .text-block {
            padding-top: 25px;
            > h2 {
              margin-bottom: 0;
              font-size: 16px;
            }
            .name {
              font-size: 20px;
            }
          }
          .contact-us-description {
            position: relative;
            left: -115px;
            top: 20px;
            width: calc(100% + 132px);
          }
        }
        .email-block {
          border: none;
          padding-left: 0;
          margin-top: 0;
          a {
            margin-bottom: 15px;
          }
        }
      }
    }
    .move-content {
      flex-direction: column;
      align-items: flex-start;
      .remove-move {
        margin-top: 1.5rem;
        width: 100%;
        display: flex;
      }
    }
    .expense-report-pagination {
      display: flex;
    }
    .Table, .Tabs {
      display: none;
    }
    .expense-report-body {
      flex-direction: column;
      align-items: center;
      box-sizing: border-box;
      .create-edit-buttons {
        justify-content: center;
        flex-flow: row wrap;
        .Button {
          flex-grow: 1;
          margin-bottom: 12px;
        }
      }
      > div {
        width: 100%;
        box-sizing: border-box;
        .DropDown {
          display: flex !important;
          width: calc(100% - 30px);
          border-bottom: 2px solid $borderColor;
          padding: 10px 0;
          margin: 15px;
        }
      }
    }

    .expense-report-table-mobile {
      display: flex;
      & ~ .expense-report-mobile {
        display: flex;
      }
    }
  }
}

@media only screen and (max-width: 425px) {
  .ExpenseReport {
    padding: 25px;
    > h1 {
      color: $blueText;
      font-size: 30px;
      margin-bottom: 19px;
      font-weight: 900;
      letter-spacing: -0.5px;
      line-height: 34px;
    }
    .description-expense {
      margin-bottom: 58px;
      font-size: 16px;
      font-weight: 300;
      line-height: 26px;
    }

    .move-content {

      .relocation-name{
        display: block;
        font-size: 20px;
        font-weight: 900;
        line-height: 25px;
      }
    }
  }
}


