@import "~main.scss";

.Input {
  height: 55px;
  width: 100%;
  display: inline-flex;
  flex-direction: column-reverse;
  position: relative;
  .placeholder {
    color: $placeHolderColor;
    font-size: $largeSize;
    transition: all 0.25s linear;
    font-weight: 100;
    position: absolute;
    left: 0;
    top: 16px;
    &:before {
      // content: 'Enter ';
      color: $placeHolderColor;
      font-size: $largeSize;
      font-weight: 100;
    }
    &:after {
      content: ' *';
      color: $placeHolderColor;
      font-size: $largeSize;
      font-weight: 100;
    }
  }
  input {
    outline: none;
    border: none;
    border-bottom: 2px solid $borderColor;
    height: 45px;
    font-size: $largeSize;
    font-weight: 100;
    position: relative;
    background-color: transparent;
    z-index: 1;
    &:focus, &:valid {
      &~span {
        &:before, &:after {
          display: none;
        }
        top: 0px;
        font-size: 13px;
      }
    }

  }
}