@import "~main.scss";

.RecruitedSelectorContainer {
    .finace-admin-body-container{
      margin: 0 auto;
      width: 84%;
      .finance-admin-header-container {
        margin: 35px 0px;
      }
      .contact-finance-link {
        display: block;
      }
      
      .finance-admin-details-container {
        width: 100%;
        float: left;
        padding-top: 10px;
        border-bottom: 1px solid #e3e3e3;
  
        .left-container {
          float: left;
          width: 50%;
          @media screen and (max-width: 667px) {
            width: 100%;
          }
          .funding-finance-partner {
            margin-bottom: 50px;
            span {
              &.questen {
                font-size: 18px;
                line-height: 42px;
                letter-spacing: -0.25px;
              }
              &.not-sure {
                color: #63666A;
                font-size: 14px;
                line-height: 24px;
                font-weight: 300;
              }
            }
            div {
              &.inputs {
                margin-bottom: 20px;
                .Input {
                  input {
                    width: 447px;
                    border-bottom: 1px solid #212121;
                    height: 20px;
                    padding-bottom: 5px;
                    color: #212121;	
                    font-size: 14px;	
                    font-weight: 300;	
                    line-height: 24px;
                    @media screen and (max-width: 1024px) {
                      width: 100%;
                    }
                  }
                  .autosuggest-container {
                    z-index: 9;
                    @media screen and (max-width: 1024px) {
                      width: 100%;
                    }
                    .combo-box-container {
                      @media screen and (max-width: 1024px) {
                        width: 100%;
                      }
                    }
                  }
                }
              }
            }
          }
          .human-resources {
            margin-bottom: 50px;
            span {
              &.questen {
                font-size: 18px;
                line-height: 42px;
                letter-spacing: -0.25px;
              }
            }
            div {
              &.inputs {
                margin-bottom: 20px;
                .Input {
                  input {
                    width: 447px;
                    border-bottom: 1px solid #D8D8D8;
                    height: 20px;
                    padding-bottom: 5px;
                    color: #212121;	
                    font-size: 14px;	
                    font-weight: 300;	
                    line-height: 24px;
                    @media screen and (max-width: 1024px) {
                      width: 100%;
                    }
                  }
                  .autosuggest-container {
                    z-index: 8;
                    @media screen and (max-width: 1024px) {
                      width: 100%;
                    }
                    .combo-box-container {
                      @media screen and (max-width: 1024px) {
                        width: 100%;
                      }
                    }
                  }
                }
              }
            }
          }
          .approver-1, .approver-2 {
            margin-bottom: 50px;
            .autosuggest-container {
              @media screen and (max-width: 1024px) {
                width: 100%;
              }
              .Icon.close-icon {
                top: 2px;
              }
              .combo-box-container {
                @media screen and (max-width: 1024px) {
                  width: 100%;
                }
                ul {
                  li {
                    .MuiButtonBase-root {
                      span {
                        font-size: 14px;
                        line-height: 30px;
                      }
                    }
                  }
                }
              }
            }
            span {
              font-size: 18px;
              line-height: 42px;
              letter-spacing: -0.25px;
            }
            div {
              &.inputs {
                margin-bottom: 20px;
                .Input {
                  input {
                    width: 447px;
                    border-bottom: 2px solid #D8D8D8;
                    height: 20px;
                    padding-bottom: 5px;
                    @media screen and (max-width: 1024px) {
                      width: 100%;
                    }
                  }
                }
                .MuiInputBase-input {
                  color: #212121;	
                  font-size: 14px;	
                  font-weight: 300;	
                  line-height: 24px;
                }
              }
            }
          }
          .approver-1 {
            .autosuggest-container {
              z-index: 7;
            }
          }
        }
        .right-container {
          float: left;
          width: 49%;
          @media screen and (max-width: 667px) {
            width: 100%;
          }
          .finance, .mobility-service {
            width: 57%;
            margin-bottom: 50px;
            padding: 8% 10%;
            float: left;
            box-shadow: 1px 2px 18px 0 rgba(0,0,0,0.36);
            border-radius: 10px;
            margin-left: 60px;
            .left {
              float: left;
              width: 20%;
            }
            .right {
              float: left;
              width: 80%;
              span {
                font-size: 14px;
                line-height: 24px;
                font-weight: 300;
                .Link {
                  span {
                    color:#212121;
                    font-size: 16px;
                    font-weight: bold;
                    line-height: 18px;
                    margin-top: 10px;
                  }
                }
              }
              .LinkIcon {
                margin-left: 5px;
                position: absolute;
              }
            }
            @media screen and (max-width: 667px) {
              width: 80%;
              margin-left: 0;
            }
            @media only screen and (min-width : 768px)and (max-width: 1024px) {
              width: 70%;
            }
          }
          .finance {
            margin-bottom: 150px;
            @media screen and (max-width: 667px) {
              margin-bottom: 50px;
            }
          }
        }
      }
      .button-section {
        width: 100%;
        float: left;
        padding: 40px 0;
        @media only screen and (min-width : 768px)and (max-width: 1024px) { 
          margin-bottom: 40px;
        }
        .left-section {
          float: left;
          width: 50%;
          @media screen and (max-width: 667px) {
            width: 100%;
          }
          @media only screen and (min-width : 768px)and (max-width: 1024px) {
            width: 35%;
          }
        }
        .right-section {
          float: right;
          width: 50%;
          @media only screen and (min-width : 768px)and (max-width: 1024px) { 
            width: 65%;
          }
          .Button {
            &.save-button {
              margin-left: 30px;
              @media only screen and (min-width : 768px)and (max-width: 1024px) {
                width: 164px !important;
                margin-left: 0;
              }
            }
            &.continue-button {
                float: right;
                background-color: #ca001b;
                box-shadow: none;
                width: 150px;
                color: #fff;
                font-size: 16px;
                font-weight: bold;
                border: 0;
                cursor: pointer;
                &:disabled {
                    background-color: #D8D8D8;
                } 
                .buttonLabel{
                    height: 20px;
                    width: 144px;
                    color: #FFFFFF;
                    font-size: 16px;
                    font-weight: bold;
                    line-height: 20px;
                    text-align: center;
                }
                @media screen and (min-width: 768px)and (max-width: 1024px) {
                  width: 145px !important;
                }
            }
          }
          @media screen and (max-width: 667px) {
            width: 100%;
          }
        }
        .Button {
          @media screen and (max-width: 667px) {
            width: 214px !important;
            margin-bottom: 20px;
            float: left !important;
            margin-left: 7% !important;
          }
        }
      }
    }
  }

