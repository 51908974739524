@import "~main.scss";

.InfoCard {
  padding:80px 50px;
  max-width: 50%;
  margin: 16px;
  cursor: pointer;
  .info-card-block {
    > h2 {
      color: white;
      margin-bottom: 0.5rem;
      letter-spacing: -0.5px;
        font-weight: 600;
    }
    > p {
      margin-bottom: 1.5rem;
      color: white;
      font-size: 20px;
      font-weight: 100;
      line-height: 30px;
    }
    span {
      color: white;
      font-size: 18px;
      font-weight: bold;
      line-height: 26px;
      margin-bottom: 4px;
    }
  }
}

@media screen and (max-width: $breakPoint-sm) {
  .InfoCard {
    width: 100%;
    max-width: 100%;
    min-width: inherit;
    padding: 25px;
    box-sizing: border-box;
    h2 {
      font-size: 26px !important;
    }
    p {
      font-size: 14px !important;
    }
    span {
      font-size: 14px !important;
    }
  }
}
