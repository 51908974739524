@import "~main.scss";

.policy-selection {
    width: 100%;
    .options {
        display: flex;
        flex-direction: column;
        .option {
            display: flex;
            margin-bottom: 15px;
            .Icon {
                margin-top: 6px;
                margin-right: 14px;
                margin-left: 5px;
            }
            .radio-button-root {
                .radio-button {
                    svg {
                        fill: #d8d8d8 !important;
                    }
                }
            }

            .radio-button-root {
                .Mui-checked {
                    svg {
                        fill: #ca001b !important;

                        &:last-child {
                            transform: scale(2);
                        }
                    }
                }
            }
            .titles {
                .title {
                    color: #000099;
                    font-size: 16px;
                    font-weight: 900;
                    line-height: 36px !important;
                    // @media screen and (max-width: 767px) {
                    //     line-height: 26px !important;
                    // }
                }
                .sub-title {
                    color: #212121;
                    font-size: 14px;
                    font-weight: 300;
                    line-height: 24px;
                }
            }
        }
    }
    .messages {
        margin-top: 26px;
        width: 473px;

        @media all and (max-width: $small) {
            width: 100%;
        }

        .message {
            border-radius: 9px;
            background-color: $disabledColor;
            padding: 21px 17px 20px 27px;
            font-size: 16px;
            font-weight: 300;
            line-height: 24px;

            &:not(:last-of-type) {
                margin-bottom: 18px;
            }
        }
    }
    .submit-button {
        padding: 12px 67px;
        margin-top: 28px;

        &.disabled {
            background-color: $disabledColor;
        }
        span {
            font-size: 16px;
            font-weight: bold;
        }
    }
    .divider {
        margin-top: 60px;
        height: 2px;
        background-color: #e3e3e3;
        width: 100%;

        @media screen and (min-width: $large) {
            width: 776px;
        }
    }
    .global-vs-domestic {
        > .title {
            margin-top: 35px !important;
            color: #212121;
            font-size: 18px;
            font-weight: 900;
            line-height: 36px;
            @media screen and (max-width: 767px) {
                line-height: 32px !important;
            }
        }
        .sub-title {
            margin-bottom: 15px;
        }
        .options {
            .option {
                .icon-block {
                    display: flex;
                    align-items: center;
                }
            }
        }
    }
    .primary-reason-global {
        .top {
            margin-top: 25px;
            .title {
                color: #212121;
                font-size: 18px;
                font-weight: 900;
                line-height: 36px;
                @media screen and (max-width: 767px) {
                    line-height: 32px !important;
                }
            }
            .sub-title {
                color: #212121;
                font-size: 14px;
                font-weight: 300;
                line-height: 24px;
                margin-bottom: 25px;
            }
        }
        .accelerated-development {
            .home-country {
                margin-top: 45px;
                .title {
                    color: #212121;
                    font-size: 18px;
                    font-weight: 900;
                    line-height: 36px;
                }
            }
            .pay-grade {
                margin-top: 30px;
                .title {
                    color: #212121;
                    font-size: 18px;
                    font-weight: 900;
                    line-height: 36px;
                }
            }
            .vertical-options {
                margin-top: 20px;
                flex-direction: row;
                .option {
                    align-items: center;
                    margin-right: 40px;
                    .title {
                        color: #212121;
                        font-size: 14px;
                        font-weight: 300;
                        line-height: 18px;
                    }
                }
            }
        }
        .business-critical-need {
            .pay-grade {
                margin-top: 30px;
                .title {
                    color: #212121;
                    font-size: 18px;
                    font-weight: 900;
                    line-height: 36px;
                }
                .horizontal-options {
                    margin-top: 20px;
                    flex-direction: row;
                    .option {
                        align-items: center;
                        margin-right: 40px;
                        .title {
                            color: #212121;
                            font-size: 14px;
                            font-weight: 300;
                            line-height: 18px;
                        }
                    }
                }
            }
        }
        .skill-need {
            margin-top: 45px;

            .title {
                color: #212121;
                font-size: 18px;
                font-weight: 900;
                line-height: 36px;
            }
            .pay-grade {
                margin-top: 30px;
                .title {
                    color: #212121;
                    font-size: 18px;
                    font-weight: 900;
                    line-height: 36px;
                }
            }
            .vertical-options {
                margin-top: 20px;
                flex-direction: row;
                .option {
                    align-items: center;
                    margin-right: 40px;
                    .title {
                        color: #212121;
                        font-size: 14px;
                        font-weight: 300;
                        line-height: 18px;
                    }
                }
            }
        }
    }
    .domestic-category {
        .top {
            margin-top: 25px;
            .title {
                color: #212121;
                font-size: 18px;
                font-weight: 900;
                line-height: 36px;
            }
        }
        .ldp {
            .options {
                .option {
                    align-items: center;
                    p.title {
                        color: #212121;
                        font-size: 14px;
                        font-weight: 300;
                        line-height: 18px;
                    }
                }
            }
        }
        .homeowner {
            .pay-grade {
                margin-top: 30px;
                .title {
                    color: #212121;
                    font-size: 14px;
                    font-weight: 300;
                    line-height: 18px;
                }
            }
            .vertical-options {
                margin-top: 20px;
                .option {
                    align-items: center;
                    margin-right: 40px;

                    .title {
                        color: #212121;
                        font-size: 14px;
                        font-weight: 300;
                        line-height: 18px;
                    }
                }
            }
        }
        .renter {
            .pay-grade {
                margin-top: 30px;
                .title {
                    color: #212121;
                    font-size: 18px;
                    font-weight: 900;
                    line-height: 36px;
                }
                .new-hire {
                    margin-top: 30px;
                    .title {
                        color: #212121;
                        font-size: 18px;
                        font-weight: 900;
                        line-height: 36px;
                    }
                    .options {
                        display: flex;
                        flex-direction: row;
                    }
                }
            }
            .vertical-options {
                margin-top: 20px;
                .option {
                    align-items: center;
                    margin-right: 40px;
                    .title {
                        color: #212121;
                        font-size: 14px;
                        font-weight: 300;
                        line-height: 18px;
                    }
                }
            }
        }
    }
}
