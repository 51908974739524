@import "~main.scss";

.move-stepper-web {
    display: none;

    @media screen and (min-width: $breakPoint-lg){
        display: block;
    }
}

.move-stepper-mobile {
    display: none;
    padding: 11px 32px 11px 30px;
    border-bottom: 1px solid $whisper;

    @media screen and (max-width: $breakPoint-lg) {
        display: flex;
        -webkit-flexbox: flex;
    }

    &.move-step {
        .move-step-title {
            margin: 0;
            font-weight: 900;
        }

        &:after {
            display: none;
        }

        .move-step-id {
            &:not(:last-of-type) {
                margin-right: 5px;
            }
        }
    }
}

.move-stepper {
    padding: 12px 60px;
    border-bottom: 1px solid $whisper;
    
    .move-step {
        img {
            width: 20px;
            height: 20px;
        }
    }
}

.move-step {
    position: relative;

    &:not(:last-of-type) {
        &:after {
            content: " ";
            border-right: 1px solid $disabledColor;
            border-top: 1px solid $disabledColor;
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
            position: absolute;
            right: 0;
            top: -5px;
            height: 31px;
            width: 31px;
        }
    }

    &.selected {
        .move-step-title {
            font-weight: 900;
        }
    }

    .move-step-id {
        font-size: 12px;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        border: 1px solid $disabledColor;
        font-weight: 300;
        color: $darkGrey;

        &.selected {
            font-weight: 900;
            color: $white;
            background-color: $blueText;
            border: 1px solid $blueText;
        }
    }

    .move-step-title {
        margin-left: 9px;
        color: $black;
        font-size: 14px;
        font-weight: 300;
    }
}
