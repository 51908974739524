@import "~main.scss";

.Header {
    height: 76px;

    .DropDown .label {
        font-weight: normal;
    }

    .navbar {
        cursor: pointer;
        display: none;

        &.hide {
            display: none;
        }

        &.close {
            .line {
                &:nth-child(2) {
                    display: none;
                }

                &:first-child {
                    transform: rotate(45deg) translateX(5px);
                }

                &:last-child {
                    transform: rotate(-45deg) translateX(6px);
                }
            }
        }

        .line {
            width: 30px;
            height: 2px;
            border-radius: 2px;
            background-color: $black;
            margin-bottom: 6px;
            transition: all 0.2s linear;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    > section {
        height: 44px;
        width: calc(100% - 100px);
        position: fixed;
        background-color: $white;
        padding: 1rem 50px;
        z-index: 100;
        border-bottom: 1px solid $borderColor;
    }

    nav {
        .view_selector {
            display: none;
        }

        ul {
            li {
                margin-left: 20px;

                &:first-child {
                    margin-left: 15px;
                }

                .active {
                    position: relative;
                    color: $blueText;

                    &:after {
                        content: '';
                        position: absolute;
                        width: 100%;
                        border-bottom: 2px solid $blueText;
                        left: 0;
                        bottom: -30px
                    }
                }

                .DropDown {
                    justify-content: center;
                    margin-right: 0;
                    margin-left: 0;
                    width: 100%;
                }

                &.log_out {
                    // border-left: 1px solid $borderColor;
                    height: 25px;
                    padding-left: 20px;
                }

                span, a {
                    font-size: 16px;
                    cursor: pointer;

                    &:hover {
                        color: $blueText;
                    }
                }
            }
        }
    }

    aside {
        > a {
            display: flex;

            span {
                color: $headerGrow;
                font-weight: 100;
                font-size: 12px;
                letter-spacing: 1px;
                margin-left: 16px;
                margin-top: 6px;
            }
        }

        .DropDown {
            margin: 0;
            width: 180px;
            justify-content: flex-start;
            @include prefixer(flex-pack, flex-start);

            .label {
                color: $headerGrow;
                font-size: 16px;
                font-weight: 500;
                line-height: 20px;
                margin-left: 10px;
                margin-top: -5px;

                &.isOpen {
                    color: $blueText
                }
            }

            .Icon {
                svg {
                    polyline {
                        stroke: $headerGrow;
                    }
                }
            }
        }
    }
}

.Options.moves, .Options.view_toggle {
    width: 100%;
    max-height: 100%;
    box-shadow: none;
    display: flex;
    justify-content: center;

    .option {
        font-size: $largeSize;
        padding: 15px 10px;
    }
}

.Options.view_toggle {
    justify-content: flex-start;
    padding-left: 342px !important;
    box-sizing: border-box;
    height: 190px;

    .closeButton {
        top: 10px;
        right: 42px;
        font-size: 60px;
    }

    ul {
        .option {
            padding-left: 0;
            padding-right: 0;
            width: auto;
            border: 2px solid transparent;

            &.selected, &:hover {
                background-color: #FFFFFF;
                color: $textColor;
                border-bottom: 2px solid $blueText;
            }

            &:first-child {
                width: 128px;
            }
        }
    }
}

.competetiveContainer {
    margin-left: 37.4px;

    @media screen and (max-width: $breakPoint-lg){
        margin-left: 0;
    }


    .competetiveItem {
        font-weight: 300;

        span {
            font-weight: bold;

            &:hover {
                color: $black;
                cursor: default;
            }
        }
    }

    li {
        margin-left: 0;
    }

    .competetiveBack {
        position: relative;
        font-weight: 300;

        &:before {
            content: '';
            display: none;
            position: absolute;
            left: -24px;
            width: 2px;
            height: 25px;
            background-color: $disabledColor;
        }
    }

    @media screen and (min-width: $breakPoint-lg){
        ul {
            justify-content: space-between;
            flex-grow: 1;
            @include prefixer(flex-pack, space-between);
            @include prefixer(flex-positive, 1);
        }

        .competetiveBack {
            &:before {
                display: block;
            }
        }
    }
}

@media screen and (min-width: 1280px) {
    #modals-root {
        width: 100%;

        > div {
            display: flex;
            justify-content: center;

            .Options {
                max-width: 1280px;
                margin: 0 auto;
                //left: inherit !important;
                padding-left: 0;
                padding-right: 0;
                border-radius: 0;
            }
        }
    }
}

@media screen and (max-width: $breakPoint-lg) {
    .Options.moves, .Options.view_toggle {
        height: 100%;
        padding: 15px !important;
        flex-direction: column;
        justify-content: flex-start !important;
        align-items: flex-start;

        .closeButton {
            position: relative;
            display: flex;
            flex-shrink: 0;
            top: 0;
            left: 0;
            font-size: $mediumSize;
            width: auto;
            padding: 15px 10px;

            &:before {
                display: flex;
                justify-content: center;
                align-items: center;
                content: '‹';
                width: 21px;
                height: 21px;
                border-radius: 15px;
                background-color: $buttonsColor;
                color: white;
                font-size: 25px;
                margin-right: 10px;
            }

            &:after {
                display: block;
                color: $textColor;
                content: 'Back';
            }
        }

        &.view_toggle {
            ul {
                margin-top: 20px;
            }
        }
    }
    .Header {
        aside {
            .DropDown {
                display: none;
            }
        }

        .DropDown {
            margin: 0;
            width: calc(100% - 46px);

            .arrow:after {
                transform: none;
            }
        }

        .navbar {
            display: flex;
        }

        nav {
            .DropDown .label, & > ul li a, & > ul li span {
                font-size: $largeSize;
            }

            .view_selector {
                display: block;
                width: 100%;

                hr {
                    height: 2px;
                    border: none;
                    background-color: $borderColor;
                    margin-bottom: 23px;
                }

                .label {
                    font-size: 16px;
                    color: $placeHolderColor;
                    font-weight: 500;
                }
            }

            ul {
                li {
                    margin: 0 !important;
                    padding: 23px;
                    box-sizing: border-box;

                    .DropDown {
                        .Icon {
                            transform: rotate(-90deg);
                        }
                    }

                    div {
                        justify-content: space-between !important;
                    }
                }
            }

            &.close {
                display: none;
            }

            top: 77px;
            position: fixed;
            width: 100%;
            left: 0;
            height: 100%;
            background-color: #fff;

            ul {
                flex-direction: column !important;
                align-items: flex-start !important;

                li {
                    &:first-child {
                        width: 100%;
                    }

                    margin: 23px;

                    &.log_out {
                        padding-left: 0;
                        border: none;
                        margin-left: 23px !important;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: $breakPoint-sm) {
    .Header {
        > section {
            padding: 1rem 20px;
            height: calc(44px + 2rem);
            width: 100%;
            box-sizing: border-box;
        }
    }
}
