@import '../../main';

.login {
  display: flex;
  justify-content: space-around;
  padding:84px 0;
  flex-grow: 1;

  &--container {
    width: 75vw;
  }

  &--headline {

      color: $blueText;
      font-size: 44px;
      font-weight: 900;
      letter-spacing: -.5px;
      line-height: 55px;
  }

  &--sub-header{
      color: $black;
      font-size: 20px;
      font-family: "J&J Circular";
      font-weight: 200;
      line-height: 30px;
  }


  &--options {
    margin-top:33px;
  }
  &--option-item {
    &::after{

      align-self: center;
      color: $buttonsColor;
      content: '>';
      height: 100%;
      transform: scale(.8 , 1.4);
    }


    display: flex;
    justify-content: space-between;
    padding: 38px 0 34px;
    box-sizing: border-box;
    border:0;
    outline:none;
    width: 100%;
    cursor: pointer;
    border-bottom: 1px solid $disabledColor;
    background: transparent;
  }

  &--option-label {
      color: $black;
      font-size: 30px;
      font-weight: 900;
      letter-spacing: -.5px;
      line-height: 38px;
  }
}
