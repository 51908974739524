@import "~main.scss";

.pre-initiate-move-container {
    padding: 85px 150px;

    .required-symbol {
        color: #ca001b;
    }

    .line {
        margin-top: 70px;
        color: #D8D8D8;
    }

    .submit-button {
        background-color: #ca001b;
        box-shadow: none;
        padding: 15px 30px;
        margin-top: 20px;
        color: #fff;
        font-size: 16px;
        font-weight: bold;
        border: 0;
        cursor: pointer;
        &:disabled {
            background-color: #D8D8D8;
        }
    }
    .section-title {
        margin: 40px 0 40px 0;
        font-size: 45px;
        &.assignment-question-h1 {
            margin: 40px 0 15px 0;
        }
    }
    .assignment-question-txt-dsc {
        padding-bottom: 20px;
    }
    .CardSection {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
    }
    .input-select-container {
        .autosuggest-wrapper-container {
            position: relative;
            .autosuggest-container {
                .Input {
                    height: 40px;
                    .MuiInputBase-root {
                        input {
                            height: 20px;
                            padding-bottom: 5px;
                        }
                        &.Mui-focused {
                            &::after {
                                transform: scaleX(0);
                            } 
                        }
                      &::before {
                          display: none;
                      }   
                    }
                }
                .Icon {
                    &.close-icon {
                        right: 75px;
                    }
                }
                .select-field-spinner {
                    right: 100px;
                }
            }
            .placeholder {
                position: absolute;
                top: 10px;
                color: #212121;
                font-size: 14px;
                font-weight: 300;
                line-height: 24px;
            }
        }
    }
    .pre-initiation-intro-screen {
        .top-content {
            .left-info {
                p {
                    &.select {
                        font-weight: 900;
                    }
                }
                .options {
                    .option {
                        display: flex;
                        .radio-button-root {
                            .radio-button {
                                svg {
                                    fill: #d8d8d8!important;
                                }
                            }
                            .Mui-checked {
                                svg {
                                    fill: #ca001b!important;
                                    &:last-child {
                                        -webkit-transform: scale(2);
                                        transform: scale(2);
                                    }
                                }
                            }
                        }
                        .titles {
                            margin-top: 4px;
                            p {
                                &.title {
                                    color: #009;
                                    font-size: 16px;
                                    font-weight: 900;
                                    line-height: 32px;

                                }
                            }
                        }
                    }
                }
            }
        }
        h1 {
            font-size: 60px;
            font-weight: 900;
            letter-spacing: -0.68px;
            line-height: 48px;

            @media screen and (max-width: 767px) {
                font-size: 30px;
                line-height: 32px;
                letter-spacing: -0.5px;
            }
            @media screen and (max-width: 1024px) {
                font-size: 35px;
                line-height: 30px;
                letter-spacing: -0.5px;
            }
        }

        .line {
            width: 90%;
            text-align: left;
            @media screen and (max-width: 667px) {
                float: left;
                margin: 20px 0 15px 0;
                width: 100%;
            }
        }

        .Input {
            input {
                width: 372px;
                border-bottom: 2px solid #212121;
                @media screen and (max-width: 414px) {
                    width: 300px;
                }
            }
        }

        .top-content {
            display: flex;
            margin-top: 40px;

            @media screen and (max-width: 600px) {
                display: block;
            }

            .left-info {
                width: 65%;
                padding-right: 60px;

                @media screen and (max-width: 600px) {
                    float: left;
                    width: 100%;
                    padding-right: 0;
                }

                p {
                    color: #212121;
                    font-size: 20px;
                    font-weight: 300;
                    line-height: 30px;
                    margin-bottom: 25px;
                }

                .Input {
                    span.placeholder {
                        color: #212121;
                        font-size: 14px;
                        font-weight: 300;
                        line-height: 24px;
                        padding-top: 10px;
                    }
                    &.focused {
                        input {
                            height: 30px;
                        }
                        span.placeholder {
                            padding-top: 0;
                            height: 18px;
                            width: 180.7px;
                            color: #63666A;
                            font-size: 12px;
                            font-weight: 300;
                            line-height: 18px;
                        }
                    }
                }

                .Input .placeholder:after {
                    visibility: hidden;
                }

                .general-info {
                    margin-top: 35px;
                    font-size: 16px;
                    font-weight: 300;
                    line-height: 26px;
                    width: 90%;
                }

                .buttons {
                    display: flex;

                    .submit-button {
                        float: left;
                        margin-top: 0;
                        height: 20px;
                        width: 70px;
                    }
                }
            }

            .right-info {
                width: 32%;

                @media screen and (max-width: 600px) {
                    float: left;
                    width: 100%;
                    margin-top: 30px;
                    padding-bottom: 30px;
                }

                div {
                    span.text {
                        color: #CC0099;
                        margin-left: 10px;
                        font-weight: bold;
                        line-height: 20px;
                        font-size: 16px;
                    }
                }

                p {
                    color: #888B8D;
                    margin-top: 20px;
                    font-size: 16px;
                    font-weight: 300;
                    line-height: 20px;
                    margin-left: 26px;
                    width: 80%;
                    @media screen and (max-width: 600px) {
                        margin-left: 0;
                        width: 100%;
                    }
                    @media screen and (max-width: 1024px) {
                        margin-left: 0;
                    }
                }
                span.Icon {
                    position: relative;
                    svg {
                        position: absolute;
                        width: 26px;
                        height: 26px;
                        left: -5px;
                        top: -5px;
                    }
                }
                span.text {
                    margin-left: 26px !important;
                }
            }
        }
        .contactmobility-wrapper {
            @media screen and (max-width: 767px) {
                float: left;
            }
            .contact-mobility-container-layout {
                p {
                    &.label {
                        @media screen and (max-width: 600px) {
                           width: 100%;
                        }
                    }
                    &.main-text {
                        @media screen and (max-width: 600px) {
                            width: 70%;
                            line-height: 20px;
                        }
                    }
                }
            }
            .details-section {
                .img-information {
                    @media screen and (max-width: 600px) {
                        position: relative;
                        left: 0;
                        margin: 0;
                    }
                }
            }
        }
    }
}
p.modalDesctextForPreInitMoveNonJnJEmpp {
    color: #212121;
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 26px;
    a {
        color: #000099;
    }
}
@media screen and (max-width: $breakPoint-lg) {
    .pre-initiate-move-container {
        padding: 85px 30px;

        .section-title {
            font-size: 25px;
        }
    }
}

@media screen and (max-width: 768px) {
    .pre-initiate-move-container {
        .CardSection {
            flex-direction: column;

            .optionSelectionCard {
                margin-bottom: 20px;
            }
        }
    }
}
