@import "~main.scss";

.Resources {
  padding: 72px 150px;
  flex-grow: 1;
  h1 {
    font-size: 44px;
    font-weight: 900;
    letter-spacing: -0.5px;
    line-height: 48px;
  }

  h2 {
    font-size: 36px;
    font-weight: 900;
    letter-spacing: -0.5px;
    line-height: 42px;
    color: $blueText;
  }

  .category-header{
    padding-top: 3rem;
    img{
      padding-right: 30px;
      padding-left: 10px;
    }

  }
  .List {
    padding-right: 0px;
    &:nth-child(2) {
      padding-left: 100px;
    }
    .ListItem {
      .list-item-right-buttons {
        flex-basis: 7%;
          padding-right: 10px;
          .chevronRed{
             svg {
                 transform: rotate(-90deg);
             }
          }
      }
      .document-category {
        flex-basis: 10%;
      }
      .document-name-section {
        flex-basis: 100%;
        .document-category {
          font-weight: bold;
          font-size: $mediumSize;
          margin-bottom: 12px
        }
        .document-name {
          font-size: 28px;
          color: $blueText;
        }

      }
    }

      .Accordion {
          .icons-block {
              padding-right: 10px;
          }
      }
  }

  .TabItem{
    padding: 0;
    height: 25px;
    font-weight: 900;
  }


  .manager-toolkit-body {
    margin-top: 10px;
    .ColumnComponent{
      margin:0px;
      padding:0px;
      .column-component-body{
        padding:0px;
        span {
          margin-top: 0px;
        }
        .card{
          height: 387px;
          width: 306px;
          margin-right: 30px;
          margin-top: 30px;
          padding-right: 39px;
          .title {
            color: $subtitleColor;
            font-size: $mediumSize;
            font-weight: bold;
            line-height: 20px;
          }
          .subtitle {
            color:$black;
            font-size: 24px;
            font-weight: 900;
            letter-spacing: -0.5px;
            line-height: 28px;
            padding-top: 20px;
            padding-bottom: 20px;
          }
          .text {
            color: $black;
            font-size: $mediumSize;
            font-weight: 300;
            line-height: 22px;
          }
          .Link {
            color: $blueText;
            font-size: $mediumSize;
            font-weight: 300;
            line-height: 18px;
            .Icon {
              margin-top: 2px;
              line-height: 18px;
              margin-bottom: 2px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: $breakPoint-md) {
  .Resources {
    padding: 72px 40px;
    .header-text {
      font-size: 36px;
    }
    .Tabs {
      flex-flow: row wrap;
       justify-content: space-around;
      .TabItem {
        margin: 1rem;
      }
    }
    .category-header {
      h2 {
        font-size: 36px;
      }
      .ListItem {
        .document-name {
          font-size: 20px;
        }
      }
    }
    .manager-toolkit-body {
      .column-component-body{
        display: block;
        padding-left: 20px;
      }
    }
  }
}

@media screen and (max-width: $breakPoint-sm) {
  .Resources {
    padding: 42px 20px;
    .category-header {
      h2 {
        font-size: 30px;
      }
      img {
        width: 45px;
      }
    }

    .List {
      padding-left: 0px !important;
      .ListItem {
        height: 90px !important;
        .list-item-right-buttons {
          flex-basis: 7%;
        }
        .document-category {
          flex-basis: 10%;
        }
        .document-name-section {
          flex-basis: 100%;
          .document-category {
            font-weight: bold;
            font-size: $mediumSize;
            margin-bottom: 12px
          }
          .document-name {
            font-size: 18px;
            color: $blueText;
          }

        }
      }
    }
  }
}
