@import "~main.scss";

.Table {
  > table {
    width: 100%;
    border-collapse:collapse;

    tr {
      border-bottom: 1px solid $borderColor;
      position: relative;
      &:not(.headers) {
        cursor: pointer;
      }
      td {
        &:first-child {
          max-width: 350px;
        }
        max-width: 120px;
        box-sizing: border-box;
      }
      &.selected {
        background-color: $tableSelected;
      }
    }

    th, td {
      padding: 10px;
      box-sizing: border-box;
      z-index: 1;
    }
    td {
      min-height: 40px;
    }
  }
  width: 100%;
  .headers {
    background-color: $mainBackgroundColor;
    th {
      height: 70px;
      text-align: left;
      font-size: 14px;
      line-height: 22px;
    }
  }
}
