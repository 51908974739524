@import "~main.scss";



.illustration-bg{
    background-image: url('../../../components/common/Images/illustration.svg');
    background-repeat: no-repeat;
}
.MidSectionComponent{

  

    width: calc(100% - 160px);
    background-color: $blueText;
    padding: 80px;
    color: $white;
    margin-bottom: 5rem;
    h2 {
        color: $white;
        margin-bottom: 2.5rem;
        font-size: $bigTitleSize;	
        font-weight: 900;	
        letter-spacing: -0.5px;	
        line-height: 42px;
    }

    .mid-section-body {
        h3{
            color: $white;
            margin-bottom: 2rem;
            font-size: 20px;
            font-weight: 900;
            line-height: 22px;
        }
        div {
            width: 100%;
            div {
                h3{
                    margin-bottom: 1rem;
                    letter-spacing: -0.5px;	
                    line-height: 26px;
                }
            }
        }
        div {
            width: 100%;
        }
        p, span, ul, li {
      
            color: $white;  
            font-size: 16px;	
            font-weight: 100;	
            line-height: 26px;  
            margin-bottom: 1rem;
        }
        ul{ 
            list-style: none;
            display: table;
        }
        ul > li::before{
            content: "•";
            color: $buttonsColor;
            font-size: $smallSize;
            font-weight: 300;	
            line-height: 26px;
            display: table-cell;
            text-align: right;
            padding-right: 10px;
        }
        ul > li {
            display: table-row;
        }

        span {
            margin-top: 2rem;
        }
        .divider{
            box-sizing: border-box;	
            border: 1px solid #FFFFFF;	
            opacity: 0.5;
        }

        .pad-left{
            padding-left: 40px;
        }

        .pad-right{
            padding-right: 40px;
        }
    }

}