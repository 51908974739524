@import "~main.scss";

.Footer {
  background-color: $mainBackgroundColor;
  height: 60px;
  width: calc(100% - 4rem);
  padding: 0 2rem;
  border-top: 1px solid $borderColor;
  color: #63666A;
    position: absolute;
    bottom: 0;
  * {
    color: #63666A;
  }
  > section {
    height: 100%;
  }
  .assignee {
    height: 60px;
    padding: 0 40px;
    border-left: 1px solid $borderColor;
  }
  .Tabs {
    align-self: center;
    margin-right: 40px;
  }
  .TabItem {
    &:not(.active):hover {
      background-color: transparent;
    }
    &.activeLink {
      background-color: rgba(0, 0, 0, 0);
      color: blue;
    }
  }

  .currentRole {
    padding-left: 5px;
    background-color: transparent;
      color: blue;
      cursor: pointer;
  }
}

@media screen and (max-width: $breakPoint-lg) {
  .Footer {
    height: auto;
    > section {
        box-sizing: border-box;
      flex-flow: row wrap-reverse;
      padding-bottom: 15px;
      // justify-content: center !important;
      > div {
        // padding-bottom: 10px;
        flex-flow: row wrap;
      }
    }
    .Tabs {
      flex-flow: row wrap;
      justify-content: space-around !important;
      .TabItem {
        white-space: nowrap;
      }
    }
    .assignee {
      border: 0;
      margin: 0 auto;
    }
  }
}

@media screen and (max-width: $breakPoint-sm) {
  .Footer {
    .Tabs {
      margin-right: 0;
      .TabItem {
        &:after {
          display: none;
        }
      }
    }
  }
  .FeedbackButton {
    bottom: 17px !important;
  }
}
