@import "~main.scss";
$error-color: #f35351;


.ValidatableForm {
  &_content {
    .formElement_errorText {
      margin-top: 5px;
      font-size: $smallSize;
      color: $error-color;
    }
    .input-label {
      color: $placeHolderColor;
    }
    .label-asterisk {
      margin: 2px;
      color: $error-color;
      font-weight: bold;
    }
  }
  &_footer {
    margin-top: 25px;
    display: flex;
    justify-content: flex-end;
    > button:last-child {
      margin-left: 2rem;
    }
  }
}
