@import "~main.scss";

.MoveDetailContainer {
    .ComponentsContainer {
        .review-and-submit-form {
            .submitButton {
                background-color: #ca001b;
                box-shadow: none;
                padding: 13px 75px;
                color: #fff;
                font-size: 16px;
                font-weight: bold;
                border: 0;
                float: right;
                cursor: pointer;
                &:disabled {
                    background-color: #D8D8D8;
                }
            }
            h1 {
                color: #000099;
                font-size: 44px;
                font-weight: 900;
                line-height: 48px;
            }
            hr {
                &.line {
                    height: 2px;
                    background: #d8d8d8;
                    margin: 34px 0;
                    box-shadow: none;
                    width: 775px;
                }
            }
            .section-title {
                color: #212121;
                font-size: 26px;
                line-height: 42px;
                margin-bottom: 22px;
            }
            .detailsSection {
                display: flex;
                flex-direction: column;
                div {
                    flex-direction: row;
                    display: flex;
                    section {
                        margin: 0 0 30px 0;
                        width: 50%;
                        .move-details-titles {
                            color: #63666a;
                            font-size: 14px;
                            font-weight: 300;
                            line-height: 20px;
                            .edit-button {
                                cursor: pointer;
                                color: #000099;
                                font-size: 12px;
                                margin-left: 12px;
                            }
                        }
                        .move-details-heading {
                            color: #212121;
                            font-size: 16px;
                            font-weight: 900;
                            letter-spacing: -0.5px;
                            line-height: 25px;
                        }
                        .move-details-sub-heading {
                            color: #212121;
                            font-size: 14px;
                            font-weight: 300;
                            line-height: 24px;
                        }
                        //NEW HIRE TAG
                        .buttonSection {
                            .newHire {
                                height: 22px;
                                width: 81px;
                                background-color: #000099;
                                float: left;
                                outline: 0;
                                border: 0;
                                .buttonLabel {
                                    height: 15px;
                                    width: 57px;
                                    color: #ffffff;
                                    font-family: "J&J Circular";
                                    font-size: 12px;
                                    font-weight: bold;
                                    letter-spacing: -0.3px;
                                    line-height: 15px;
                                    text-align: center;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.review-and-submit-form.non-competitive {
        .move-title {
           display: flex;
           align-items: center;
           .edit-button {
               cursor: pointer;
               color: #000099;
               font-size: 12px;
               margin-left: 12px;
           }
       }
       
   }

@media screen and (max-width: 600px) {
    .review-and-submit-form.non-competitive { 
        .top-content {
            .empty-col {
                display: none;
            }
        }
        .button-section {
            .flexible {
                flex-direction: column;
                .Button:not(:first-of-type) {
                    margin-top: 20px;
                }
            }
        }
    }
}

@media screen and (max-width: $breakPoint-md) {
    .MoveDetailContainer {
        .ComponentsContainer {
            .review-and-submit-form {
                .submitButton {
                    margin-bottom: 80px;
                }
                hr {
                    &.line {
                        width: 650px;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 1080px) {
    .MoveDetailContainer {
        .ComponentsContainer {
            .review-and-submit-form {
                .submitButton {
                    margin-bottom: 80px;
                }
                hr {
                    &.line {
                        width: 720px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 790px) {
    .MoveDetailContainer {
        .ComponentsContainer {
            .review-and-submit-form {
                .submitButton {
                    margin-bottom: 80px;
                }
                h1 {
                    font-size: 28px;
                }
                hr {
                    &.line {
                        width: 100%;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: $breakPoint-sm) {
    .MoveDetailContainer {
        .ComponentsContainer {
            .review-and-submit-form {
                .submitButton {
                    margin-bottom: 80px;
                }
                .detailsSection {
                    div {
                        flex-direction: column;
                        display: flex;
                        section {
                            width: 100%;
                        }
                    }
                    h1 {
                        font-size: 18px;
                    }
                    hr {
                        &.line {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}
