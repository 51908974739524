@import "~main.scss";

.FeedbackButton {
    position: fixed;
    bottom: 36px;
    left: 0;
    right: 0;
    z-index: 50;
    pointer-events: none;
    &-inside {
        box-sizing: border-box;
        padding: 25px;
        display: flex;
        justify-content: flex-end;
        margin: 0 auto;
        max-width: 1280px;
    }
    &-button {
        pointer-events: all;
        box-shadow: 0 1px 1px 0 rgba(136,139,141,0.25);
    }
}

@media screen and (max-width: $breakPoint-md){
    .FeedbackButton {
        &-inside {
            padding: 10px;
        }
    }
}
