.pre-initiate-move-container {
    .talent-mobility-exec {
        h1 {
            margin-top: 30px;
        }
        .add-info-list {
            margin-top: 26px;
            p.title {
                color: #212121;
                font-size: 20px;
                line-height: 30px;
                margin-bottom: 26px;
                a {
                    color: #000099;
                }
            }
            ul {
                li {
                    list-style: none;
                    color: #212121;
                    margin-bottom: 25px;
                    &:before {
                        content: "•";
                        margin-right: 10px;
                        color: red;
                    }
                }
            }
            .label {	
                height: 42px;	
                width: 412px;	
                color: #212121;	
                font-size: 26px;	
                font-weight: 900;	
                letter-spacing: -0.36px;	
                line-height: 42px;
            }
        }
        .move-type-puncts {
            p.title {
                color: #212121;
                font-weight: 900;
                font-size: 18px;
                margin-top: 50px;
                line-height: 24px;
            }
            .boxes {
                margin-top: 20px;
                display: flex;
                justify-content: space-around;
                .box {
                    width: 33%;
                    display: flex;
                    margin-right: 45px;
                    &:last-child {
                        margin-right: 0;
                    }
                    .left {
                        color: #ca001b;
                        font-size: 14px;
                        font-weight: 900;
                        margin-right: 20px;
                        line-height: 18px;
                        padding-top: 5px;
                    }
                    .right {
                        .top-text {
                            color: #212121;
                            font-size: 16px;
                            font-weight: 600;
                            line-height: 24px;
                        }
                        .mid-content {
                            color: #212121;
                            font-size: 16px;
                            font-weight: 300;
                            line-height: 24px;
                            margin-top: 3px;
                        }
                    }
                }
            }
        }
        .contact-mobility {
            margin-top: 35px;
            .title {
                color: #212121;
                font-size: 26px;
                font-weight: 900;
                line-height: 42px;
                margin-bottom: 15px;
            }
            .middle-text {
                color: #212121;
                font-size: 16px;
                font-weight: 300;
                width: 80%;
                line-height: 26px;
                margin-bottom: 35px;
            }
            .email-block {
                display: flex;
                align-items: center;
                a {
                    color: #000099;
                    margin-left: 11px;
                }
            }
        }
        .line {
            margin-top: 50px;
        }
        .textFont{
            width: 100%;	
            color: #212121;	
            font-family: "J&J Circular";	
            font-size: 16px;	
            font-weight: 300;	
            line-height: 26px;
            margin: 14px 0 0 0;
        }
        .submit-button {
            background-color: #ca001b;
            box-shadow: none;
            padding: 13px 30px;
            margin-top: 30px;
            color: #fff;
            font-size: 16px;
            font-weight: bold;
            border: 0;
            float: right;
            cursor: pointer;
        }
    }
}

@media screen and (max-width: 768px) {
    .pre-initiate-move-container {
        .talent-mobility-exec {
            h1 {
                font-size: 25px;
                line-height: 30px;
            }
            .contact-mobility {
                .middle-text {
                    width: 100%;
                }
            }
            .move-type-puncts {
                .boxes {
                    display: block;
                    .box {
                        width: 100%;
                        margin-bottom: 15px;
                    }
                }
            }
        }
    }
}
