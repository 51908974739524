@import "~main.scss";

$circleWidth: 15px;
$circleHeight: 15px;
$borderColor: #d8d8d8;

.StepComponent {
  min-width: 120px;
  flex: 1;
  position: relative;
  cursor: pointer;
  font-size: $largeSize;

  .circle {
    box-sizing: border-box;
    height: $circleHeight;
    width: $circleWidth;
    border: 3px solid $borderColor;
    border-radius: 10px;
    margin-bottom: 15px;
    position: relative;
  }

  &:not(.last):after {
    display: block;
    background: $borderColor;
    content: ' ';
    height: 2px;
    width: calc(100% - #{$circleWidth});
    left: calc(50% + #{$circleHeight/2});
    top: calc(#{$circleWidth}/2 - 1px);
    position: absolute;
  }
  &:not(.active):hover {
    .step-label {
      color: $blueText;
    }
    .circle {
      border: 4px solid $blueText;
      background-color: #FFFFFF;
    }
  }

  .step-label{
    color: $darkGrey;
    font-weight: 900;
    text-align: center;
  }

  &.active{
    .step-label {
      color: $blueText;
    }
    .circle {
      background-color: $blueText;
      border: 0;
    }
  }
}
