@import "~main.scss";

.select-field {
  margin-top: 20px;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
}

.SelectField {
  margin: 10px 0 !important;
  width: 100%;
  label {
    font-size: 14px;
    top: 16px;
    left: 12px;
    transform: none;
    color: $night-rider !important;
    outline: none;
  }
  p {
    padding-left: 12px;
    line-height: 16px;
  }
  .select-field-body {
    &.disabled {
      opacity: 0.9;
     > div {
       border-style: dotted;
     }
    }
    > div {
      background-color: $white;
      width: calc(100% - 4px);
      &:after, &:before {
        display: none;
      }
      div {
        height: 100% !important;
        line-height: 45px;
        padding: 0 12px;
        font-size: 14px;
        &:focus {
          background: none;
        }
      }
      svg {
        top: 10px;
        right: 4px;
      }
    }
  }
}

.simple {
  > div {
    border: 2px solid $white-smoke;
    height: 45px;
    &:after, &:before {
      display: none;
    }
    > div {
      > div {
        line-height: 45px;
        padding-left: 12px;
        font-size: 14px;
      }
    }
     div {
       height: 100%;
       width: 100%;
       padding: 0;
       &:focus {
         background: none;
       }
     }
    svg {
      top: 8px;
      right: 4px;
    }
  }
}

.selectField-filled {
  > div {
    border-color: $light-sea-green;
  }
}

#menu- {
  > div {
    ul {
      padding: 0;
      li {
        &:hover {
          background-color: $white-smoke;
        }
        h3 {
          font-size: 14px;
          font-family: 'Intro', sans-serif;
        }
      }
    }
  }
}

.menu-item-checkbox {
  display: flex;
  align-items: center;
  > div {
    padding-left: 0;
  }
  > span {
    color: $wat-asphalt;
  }
}
