@import "~main.scss";

#main-content {
  padding: 72px 150px 10px 150px;
  flex-grow: 1;
  a {
      text-decoration: underline;
 }
  h1 {
      color: #000099;
      font-family: "J&J Circular";
      font-weight: 700;
      font-size: 44px;
      line-height: 40px;
      margin-bottom: 40px;
 }
  p {
      padding-bottom: 20px;
      width: 100%;
      color: #212121;
      font-family: "J&J Circular";
      font-size: 20px;
      font-weight: 300;
      line-height: 30px;
 }
  .altText {
      padding-bottom: 20px;
      width: 100%;
      color: #212121;
      font-family: "J&J Circular";
      font-size: 12px;
      font-weight: normal;
 }
  ul,ol {
      padding-bottom: 20px;
      list-style: disc;
      margin: 20px 20px 20px 20px;
      width: 100%;
      color: #212121;
      font-family: "J&J Circular";
      font-size: 20px;
      font-weight: 300;
      line-height: 30px;
      li {
          list-style: decimal;
     }
     ;
 }
  h2 {
      color: #000099;
      font-family: "J&J Circular";
      font-size: 20px;
      font-weight: 900;
      letter-spacing: -0.5px;
      line-height: 26px;
 }
  h3 {
      color: #CC0099;
      font-family: "J&J Circular";
      font-size: 16px;
      font-weight: bold;
      line-height: 30px;
 }
  table {
      border-collapse: collapse;
 }
  table, th, td {
      border: 1px solid black;
 }
}
