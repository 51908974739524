@import '~main.scss';

.TaxMilestones {
    flex: 1 1 auto;
    position: relative;

    .inner {
        padding: 72px 150px;

        @media screen and (max-width: $breakPoint-md) {
            padding: 30px;
        }
    }
}
