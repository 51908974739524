.TextSection {
    .back-icon {
        margin: 40px 0 25px 10%;
    }

    .section-title {
        margin: 20px 0 40px 10%;
        font-size: 25px;
    }
}

@media screen and (max-width: 600px) {
    .RecruitedSelectorContainer {
        .RecruitedSelector {
            &.eligible-container {
                .TextSection {
                    .section-title {
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }
}
