@import "~main.scss";

.TabItem {
  padding: 0 10px;
  height: 40px;
  cursor: pointer;

  &:not(.active):hover {
    background-color: silver;
  }

  &.active {
    color: white;
    background-color: $blueText;
  }
}