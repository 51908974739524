@import "~main.scss";

.move-info {
    .question-part {
        padding: 0 !important;
    }

    &__description {
        margin: 0 100px;

        &-container {
            margin-bottom: 50px;

            .QuestionListCard {
                margin-bottom: 0;
            }

            .text-block {
                padding-bottom: 0 !important;
            }
        }

        .question-part {
            margin-bottom: 18px;
        }
    }

    &__accordion {
        display: flex;
        padding: 0 50px 0 105px;

        .accordion-item {
            border-bottom: 1px solid $headerGrow;

            &_last {
                border-bottom: 0;
            }

            &.open {
                .accordion-header {
                    border: 0;
                }
            }
        }

        .Accordion {
            width: 280%;
            margin-right: 30px;

            .title {
                display: flex;
                align-items: center;
                color: $textColor !important;
                font-size: 16px !important;
                font-weight: bold !important;

                .Icon {
                    margin-right: 15px;
                }
            }

            .accordion-body {
                padding-top: 0;
                font-weight: 100;

                .icon-block {
                    display: none;
                    margin-left: 0;
                }

                .text-block {
                    margin-left: 0;
                }

                .QuestionListCard {
                    margin-top: 15px !important;
                    margin-bottom: 0;
                }
            }
        }
    }

    &__contacts {
        &-header {
            padding: 25px 30px;
            color: $blueText;
            font-size: 20px;
            border-bottom: 1px solid $headerGrow;
            background-color: $white-smoke;
        }

        &-content {
            padding: 25px 30px;
            background-color: $white-smoke;

            p {
                font-weight: 100;
                margin-bottom: 10px;
            }
        }
    }

    .questionListCard {
        margin-top: 30px;
    }

    .question-card {
        padding: 0 !important;
    }

    .question-part {
        &.small {
            margin-top: 40px;

            h2 {
                margin-top: 40px;
                font-size: $subTitleSize;
            }
        }

        .questions-list-card__text {
            font-size: 19px !important;
        }

        &__list {
            &-container {
                width: 130%;
                margin: 74px 0 0 50px !important;

                strong {
                    font-size: 14px;
                }
            }
        }
    }

    .question-parts {
        margin-top: 25px;

        &__information {
            display: flex;
            padding: 0 100px 0 0 !important;
        }

        &__list {
            li::before {
                display: initial !important;
            }
        }

        &__link {
            color: $blueText;
            cursor: pointer;
        }
    }

    .questions-list-card__description {
        margin: 20px 0;

        &_secondary {
            margin: 25px 0;
            font-size: 16px;
        }
    }

    .info-block {
        margin-top: 10px;
        display: flex;
        flex-direction: column;

        .subtitle {
            color: $subtitleColor;
            font-size: 16px;
            margin-bottom: 10px;
        }

        .body-phone {
            border-right: 0 !important;

            svg {
                margin-top: 4px;
            }
        }

        .ColumnComponent{
            margin:0px;
            padding:0px;
            .column-component-body{
                padding:0px;
            }
            .card{
                height:240px;
                width:200px;
            }
        }
        .body-contacts {
            padding-right: 10px;
            border-right: 1px solid $borderColor;
        }
        .Icon {
            margin-right: 10px;
        }
        .body-phone {
            padding: 0 10px;
            border-right: 1px solid $borderColor;
            ul {
                margin-bottom: 1rem;
            }
        }
        .body-email {
            padding-left: 10px;
            color: $blueText;
            a {
                color: $blueText;
            }
        }
    }
}

.initial-move-service.Button {
    background-color: $buttonsColor;
    padding: 20px 30px;
    margin: 22px 0 15px 0 !important;
    z-index: 1;
    span {
        font-size: 16px;
    }
}

.sirva-info {
    font-size: 14px;
    font-weight: 300;

    strong {
        line-height: 26px;
    }
    hr {
        margin: 20px 0;
        width: 40px;
    }

    &-link {
        color: $blueText;
        cursor: pointer;
    }

    .estimate {
        hr {
            margin: 20px 0;
        }

        .time-label {
            margin-left: 8px;
            line-height: 18px;
            font-weight: bold;
        }

        .time-desc {
            font-weight: 300;
            font-size: 14px;
            line-height: 18px;
        }
    }

    ul.question-parts__list {
        list-style: none;
        display: table;
        & > li {
            display: table-row;
            line-height: 20px;
            &::before {
                content: "\2022";
                color: $buttonsColor;
                font-weight: 300;
                line-height: 26px;
                display: table-cell;
                text-align: right;
                padding-right: 10px;
            }
        }
    }
}
