@import "~main.scss";

.your-talent-move {
    border-bottom: 1px solid $disabledColor;
    padding: 20px 0;
    min-height: 100px;
    box-sizing: border-box;

    .Icon {
        svg {
            polyline {
                stroke: $black;
            }
        }
    }

    .title-container {
        margin-left: 15px;
        width: 331px;
    }

    h3 {
        font-size: 24px;
        line-height: 30px;
        color: $black;
        max-width: 300px;
        font-weight: bold;
    }

    .new-hire {
        background-color: $blueText;
        color: $white;
        font-size: 10px;
        font-weight: bold;
        letter-spacing: -0.25px;
        line-height: 13px;
        padding: 4px 11px 3px;
        text-transform: uppercase;
    }

    .notice {
        color: $buttonsColor;
        font-size: 14px;
        font-weight: 300;
        line-height: 24px;
    }

    .services {
        display: flex;
        width: 160px;
        margin-left: 5px;
    }

    .moves {
        width: 160px;
        margin-left: 20px;
    }

    .move-status {
        width: 160px;
        margin-left: 18px;
    }

    .move-id {
        margin-left: 33px;
    }

    .delete-move {
        margin-right: 20px;
        padding: .72rem 1.73rem;
    }

    .continue-move {
        padding: .813rem 2rem;
    }

    .label {
        font-size: 14px;
        color: $darkGrey;
        font-weight: 300;
        line-height: 18px;
        margin-bottom: 5px;
    }

    .info-title {
        font-size: 16px;
        color: #000000;
        font-weight: 300;
        line-height: 20px;

        &.bold {
            font-weight: 900;
        }
    }

    .add-move-details {
        padding: .813rem 1.45rem;
    }

    .more-details {
        margin-top: 34px;
        .flag {
            height: 51px;
            margin-left: 80px;
        }
        .countryFlag-img {
            height: 51px;
            margin-left: 80px;
        }
        .detail-titles {
            margin-left: 30px;

            span {
                font-size: 14px;
                font-weight: 300;
                line-height: 20px;
                color: $placeHolderColor;

                &:not(:last-of-type) {
                    margin-bottom: 10px;
                }
            }
        }

        .detail-values {
            margin-left: 35px;

            span {
                font-size: 16px;
                font-weight: 300;
                line-height: 20px;
                color: $black;

                &:not(:last-of-type) {
                    margin-bottom: 9px;
                }
            }
        }
    }

    .additional-info {
        background-color: $backgroundColor;
        margin-top: 29px;
        padding: 9px 9.5px 9px 37px;
        width: 100%;

        &.accepted {
            .notice {
                color: $blueText;
            }

            .new-role-accepted {
                background-color: $white;
                padding: 13px 26px;

                span {
                    color: $blueText;
                    font-weight: 500;
                }
            }
        }
    }
}
@media screen and (max-width: 600px) {
    .your-talent-move {
        .more-details {
            margin-top: 20px;
            .countryFlag-img {
                height: 51px;
                margin-left: 10px;
            }
            .detail-titles {
                margin-left: 15px;
            }
            .detail-values{
                margin-left: 10px;
            }
        }
        .move-id {
            margin-left: 10px;
        }
    }
}
