@import "~main.scss";

.hr-IntInitMoveStep {
    .question-part {
        padding-top: 70px !important;
        p {
            font-size: $subTitleSize;
            font-weight: 300;
            line-height: 30px;
        }
    }

.expl_hr_acc_item_part_1 {
    color:$black;
    font-size: $subTitleSize;
    font-weight: 300;
    line-height: 30px;
}
.expl_hr_acc_item_part_2 {
    color:$black;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: $mediumSize;
    font-weight: 300;
    line-height: 26px;
}
.expl_hr_acc_item_part_3 {
    padding-top: 8px;
    padding-bottom: 10px;
    font-size: $smallSize;
}

.pt0{
    padding-top: 0;
}
.ColumnComponent  {
    background-color: $mainBackgroundColor;
    padding: 20px 80px 50px 80px;
    margin-left: -70px;
    margin-bottom: 0px;
    h2 {
        font-size: 20px;
        font-weight: 900;
        letter-spacing: -0.5px;
        line-height: 26px;
    }
    .slick-list {
        padding-bottom: 40px;
        margin: 0;
        .slick-track {
            width: max-content !important;
        }
    }

    .column-component-body {
        padding-top: 0px;
    }
    .card {
        border: 0px;
        max-width: 316px;
    }
    .estimate {
        position: inherit;
    }
}
.dom-expl-first-step {
    .Accordion > .accordion-item {
        .item-header {
            .ListItem {
                min-height: 110px;
            &.opened {
                border-color: $blueText;
            }
            .title-with-content .title {
                .index {
                min-width: 45px;
                height: 45px;
                border: 2px solid $subtitleColor;
                border-radius: 100%;
                margin-right: 25px;
                font-size: 22px;
                font-weight: 900;
                color: $blueText
                }
                .title-text {
                font-size: $subTitleSize;
                font-weight: 900;
                color: $blueText;
                }
            }
            }
        }
        .item-content {
            padding: 0 70px;
            &.opened {
                padding: 20px 10px 20px 70px;
            }
            .flexible.pad-right {
                width: 100%;
                padding-right: 20px;
            }
            .flexible.pad-left {
                width: 40%;
                font-size: 14px;
                line-height: 20px;
                border-left: 1px solid $disabledColor;
                padding-left: 20px;
            }
            .list-title {
                font-weight: 300;
                margin-bottom: 25px;
            }
            .list {
                > li {
                display: flex;
                font-weight: 300;
                line-height: 1.8;
                &:last-child {
                    margin-bottom: 15px;
                }
                &::before {
                    content: "•";
                    display: block;
                    color: $buttonsColor;
                    margin-right: 10px;
                }
                }
            }
            .Link {
                margin-top: 40px;
                margin-right: 20px;
                font-family: "J&J Circular";
                font-size: 16px;
                font-weight: bold;
                line-height: 18px;
                }
            .Button {
                background-color: $buttonsColor;
                padding: 20px 30px;
                margin: 22px 0 15px 0 !important;
                z-index: 1;
            }
            a {
                color: $blueText;
                padding-bottom: 10px;
              }
            .estimate{
                bottom: 0;

                hr {
                    width: 66px;
                    margin-bottom: 30px;
                    border: 1px solid $disabledColor;
                }
                .Icon {
                    padding-right: 5px;
                }
                .time-label{
                    font-weight: bold;
                }
            }
        }
    }
}
.dom-expl-questions {
    width: 65%;
    .dom-expl-first-step-questions {
        .title-with-content  {
            padding-bottom: 10px;
        }
     img {
        width: 50px;
        height: 50px;
        padding-right: 19px;
     }
     .list {
        > li {
        display: flex;
        font-weight: 300;
        line-height: 1.8;
        &:not(last-child) {
            margin-bottom: 15px;
        }
        &::before {
            content: "•";
            display: block;
            color: $buttonsColor;
            margin-right: 10px;
        }
        }
    }
    .Link {
        margin-top: 40px;
        margin-right: 20px;
        font-family: "J&J Circular";
        font-size: 16px;
        font-weight: bold;
        line-height: 18px;
        }
    }
    }

.dom-expl-mobility{
    width: 30%;
}
}

@media screen and (max-width: $breakPoint-lg) {
.hr-IntInitMoveStep {
    .dom-expl-first-step {
      .Accordion > .accordion-item {
        .item-content {
            &.opened {
                padding: 20px 10px 20px 20px;
            }
            .dom-expl-first-step-info-content {
                padding: 0 15px;
            }
            .expl_hr_acc_item_buttons {
                display: block;
            }
            div.flexible{
                display: block;
            }
            div.flexible.pad-left {
                width: 100%;
                padding-top: 20px;
                border-left: 0px !important;
            }
        }
      }
    }

    .ColumnComponent  {
        padding: 20px 50px 20px 100px;
    }

    .illustration-bg .mid-section-body{
        padding-left: 20px;
        padding-top: 160px;
        p {
            margin-bottom:0px;
        }
    }

    .dom-expl-question-part {
        display: block;
        .dom-expl-questions {
            width: 100%;
        }
        .dom-expl-mobility{
            width: 100%;
        }
    }
}
}
