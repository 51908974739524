@import '~main.scss';

.approvalsContainer{
  width: 100%;
  .partnersOne {
    .autosuggest-container {
      z-index: 9;
    }
  }
  @media screen and (min-width: $large) {
    min-width: 800px;
  }

  .messages{ 
    margin: 30px 0;

    .message {
      border-radius: 9px;
      max-width: 100%;
      background-color: $disabledColor;
      padding: 21px 17px 20px 27px;
      font-size: 16px;
      font-weight: 300;
      line-height: 24px;
  
      &:not(:last-of-type) {
          margin-bottom: 18px;
      }
    }

    @media screen and (min-width: $large) {
      .message {
        max-width: 420px;
      }
    }
  }

  .formContainer{
    margin: 30px 0;
  }

  .line {
    display: block;
    box-sizing: border-box;
    height: 2px;
    width: 773px;
    border: 1px solid #E3E3E3;
    margin: 55px 0 0 0;
  }
  .buttonSection{
    width: 60%;
    float: right;
    margin: 30px 0;

    .getStarted{
        float: right;
        background-color: #ca001b;
        box-shadow: none;
        padding: 15px 30px;
        width: 202px;
        color: #fff;
        font-size: 16px;
        font-weight: bold;
        border: 0;
        cursor: pointer;
        &:disabled {
            background-color: #D8D8D8;
        } 
        .buttonLabel{
            height: 20px;
            width: 144px;
            color: #FFFFFF;
            font-size: 16px;
            font-weight: bold;
            line-height: 20px;
            text-align: center;
        }
    }

  }
}


@media only screen and (min-device-width : 768px) and (max-device-width : 1024px)  {
  .approvalsContainer {
    .buttonSection {
      .getStarted {
          margin-bottom: 35px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .approvalsContainer {
    .buttonSection {
      .getStarted {
        width: 100%;
      }
    }
  }
}    