@import '~main.scss';

.section-heading {
    .section-title {
        margin: 0;
    }
    &-description{
        margin: 15px 0 0;
        color: $black;
        font-size: $subTitleSize;
        font-weight: 300;
        line-height: 30px;
    }    
}
%eyebrow {
    color: $subtitleColor;
    margin: 0 0 20px;
    font-weight: bold;
    display: block;
}

.section-eyebrow{
    @extend %eyebrow;
    font-size: 16px;
}

.aspots-eyebrow{
    @extend %eyebrow;
    font-size: 20px;
}