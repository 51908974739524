@import "~main.scss";

.HelpComponent.profile{
  padding-bottom: 0;
}
.HelpComponent {
  width: 100%;
  min-width: 372px;
  box-sizing: border-box;
  padding: 2rem;
  background-color: $mainBackgroundColor;
  .help-component-block {
    .email-block.contact-us{
      width: 50%;
      white-space: nowrap;
      line-height: 2.3em;
      padding-top: 40px;
      border-top: 0px;
      border-left: 1px solid $borderColor;
      padding-left: 40px;

    }
    .email-block {
      border-top: 1px solid $borderColor;
      margin-top: 23px;
      padding: 16px 0;
      font-size: 16px;
      font-weight: 100;

      hr {
        border: none;
        width: 1px;
        background-color: $borderColor;
      }
      .email, .chat {
        color: $blueText;
      }
    }
    .info-block {
      .image-block {
        min-width: 100px;
        max-width: 100px;
        height: 100px;
        border-radius: 100%;
        background-size: cover;
        background-position: 50% 25%;
      }
      .image-block.contact-us {
        width: 120px;
        height: 120px;
        border-radius: 100%;
        background-size: cover;
        background-position: 50% 25%;
      }
      .text-block.contact-us{
        width: 70%;
      }
      .text-block {
        margin-left: 1rem;
        max-width: calc(100% - 100px);
        h2 {
          margin-bottom: .6rem;
          color: $subtitleColor;
          font-size: $subTitleSize;
          font-weight: 900;
          letter-spacing: -0.5px;
        }
        h2.contact-us {
          color: $subtitleColor;
          margin-bottom: 0;
        }
        .intro{
          font-size: 14px;
          font-weight: 100;
          line-height: 18px;
        }
        span {
          margin-bottom: .2rem;
          word-break: break-all;
        }
        .position {
          color: $placeHolderColor;
          font-weight: 100;
        }
        .name {
          font-size: 18px;
          font-weight: 900;
        }
        .name.contact-us {
          font-size: 24px;
          font-weight: 900;
          line-height: 42px;
        }
        .contact-us-description{
          font-size: 16px;
          font-weight: 300;
          line-height: 26px;
        }
      }
    }
  }
}
