@import "~main.scss";

.InfoKnockOutCard {
  padding:30px 20px;
  max-width: 100%;
  margin: 16px;
  cursor: pointer;
  border-radius: 10px;
  .info-card-block {
    > h2 {
      color: white;
      margin-bottom: 0.5rem;
      letter-spacing: -0.5px;
      font-weight: 600;
      font-size: 30px;
    }
    > p {
      margin-bottom: 0.5rem;
      color: white;
      font-size: 18px;
      font-weight: 100;
      line-height: 30px;
    }
    span {
      color: white;
      font-size: 17px;
      font-weight: bold;
      line-height: 26px;
      margin-bottom: 4px;
    }
    .MuiListItemText-root{
        margin: 0px;
        padding-left: 15%;
        .MuiTypography-body1{
            font-weight: normal;
            line-height: 25px;
            &:before {
                content: "\2B24";
                color: white;
                padding-right: 10px;
                font-size: x-small;
            }
        }
    }
  }
}

@media screen and (max-width: $breakPoint-sm) {
  .InfoKnockOutCard {
    max-width: 100%;
    min-width: inherit;
    padding: 25px;
    box-sizing: border-box;
    h2 {
      font-size: 26px !important;
    }
    p {
      font-size: 14px !important;
    }
    span {
      font-size: 14px !important;
    }
  }
}
