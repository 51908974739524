.pre-initiate-move-container {
    .people-leader-container {
        .pay-grade {
            border: 1px solid #D8D8D8;
            &:hover {
                border-color: #D8D8D8;
            }
            h2 {
                color: #212121;
                font-size: 20px;
                font-weight: 300;
                line-height: 30px;
                margin-block-start: 1.5em;
                margin-block-end: 0;
            }
            p {
                color: #212121;
                font-size: 32px;
                font-weight: 900;
                line-height: 41px;
            }
            .CardSection {
                margin-top: 52px;
                box-sizing: border-box;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .pre-initiate-move-container {
        .people-leader-container {
            .pay-grade {
                border: 1px solid #D8D8D8;
                &:hover {
                    border-color: #D8D8D8;
                }
            }
        }
    }
}
