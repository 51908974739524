@import '~main.scss';

.MyDocuments {
  padding: 72px 150px;
  flex-grow: 1;
    .header-text {
        font-size: $pageTitleSize;
    }
    .TabItem{
    padding: 0px;
    height: 25px;
    font-weight: 900;
  }
  .empty-mobile {
    display: none;
  }
  .DropDown {
    display: none;
  }
  .document-name-section {
    max-width: 70%;
    width: 100%;

    .document-category {
      font-weight: bold;
      font-size: $mediumSize;
      margin-bottom: 12px
    }
    .document-name {
      cursor: pointer;
      font-size: 28px;
      color: $blueText;
    }
  }
}

@media screen and (max-width: $breakPoint-lg) {
  .MyDocuments {
    padding: 72px 40px;
  }
}

@media screen and (max-width: $breakPoint-md) {
  .my-documents-mobile-select{
    width: calc(100vw - 130px);
    left: 65px;
    box-shadow: $material-header-shadow;
    ul {
      li {
        max-width: 100% !important;
      }
    }
  }
  .MyDocuments {
    .header-text {
      font-size: 36px;
    }
    .Tabs, .not-mobile {
      display: none;
    }
    .empty-mobile {
      display: block;
    }
    .DropDown {
      display: flex !important;
      width: calc(100% - 30px);
      border-bottom: 2px solid $borderColor;
      padding: 10px 0;
      margin: 15px;
    }
    .Tabs {
      flex-flow: row wrap;
      justify-content: space-around;
      .TabItem {
        margin: 1rem;
      }
    }
  }
}

@media screen and (max-width: $breakPoint-sm) {
  .MyDocuments {
    padding: 42px 20px;
    .header-text {
      font-size: 30px;
    }
    .ListItem {
      height: 90px !important;
      .document-name-section {
        padding-right: 1rem;
      }
      .document-name {
        font-size: 16px;
        max-width: 170px;
      }
    }
    .DropDown {
      width: calc(100% - 30px);
    }
  }
}
