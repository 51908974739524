.package-selection-container {
    .eligible-container {
        .top-content {
            width: 58%;
        }
    }
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px)  {
    .package-selection-container {
        .eligible-container {
            .top-content {
                width: 75%;
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .package-selection-container {
        .eligible-container {
            .top-content {
                width: 100%;
            }
        }
    }
}    