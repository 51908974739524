@import '~main.scss';

.title-with-description {
    &.padding-left {
        padding-left: 80px;
        @media screen and (max-width: $breakPoint-sm) {
            padding: 30px;
        }
    }

    &.padding-right {
        padding-right: 80px;
        @media screen and (max-width: $breakPoint-sm) {
            padding: 30px;
        }
    }
}

.sirva-connect-button {
    margin-top: 15px;
    padding: 20px 30px;
    background: $buttonsColor;
}
