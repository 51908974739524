@import '~main.scss';

.Allowances {
  padding: 90px 150px;

  .allowance-category {
    margin-bottom: 70px;
    .allowances-list {
      margin-left: 100px;

      .home-sale-details {
        .details-row {
          &:nth-last-child(2) {
            .details-col {
              .text {
                font-size: 30px;
              }
            }
          }
          .details-col {
            &:not(:last-child) {
              margin-right: 10px;
            }
            min-width: 250px;
            margin-bottom: 25px;
            width: 33.3%;
          }

          &:not(:last-child)>p {
            margin-bottom: 25px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $breakPoint-lg) {
  .Allowances {
    padding: 40px 25px;

    .allowance-category {
      margin-bottom: 40px;
      .allowances-list {
        margin-left: 0px;

        .home-sale-details {
          .details-row {
            flex-direction: column;
            &:nth-last-child(2) {
              .details-col {
                .text {
                  font-size: $subTitleSize;
                }
              }
            }
            .details.col {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
