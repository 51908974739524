@import "~main.scss";

.option-screen-container {
    display: flex;
    flex-direction: column;

    .line{
        display: block;
        box-sizing: border-box;
        height: 2px;
        width: 100%;
        border: 1px solid #E3E3E3;
    }

    .title-section{
        width: 100%;

        .section-title{
            height: 48px;
            width: 100%;	
            color: #000099;	
            font-size: 44px;	
            font-weight: 900;	
            letter-spacing: -0.5px;	
            line-height: 38px;
        }
        .for-repatriation-ex{
            height: 140px;	
            width: 100%;	
            color: #212121;		
            font-size: 20px;	
            font-weight: 300;	
            line-height: 30px;
            margin-top: 65px;
            a {
                color: #000099;
            }
        }
    }
    .contact-mobility-container {
        display: flex;
        flex-direction: column;
        margin: 50px 0 -50px 0;

        .label {
            height: 42px;
            width: 412px;
            color: #212121;
            font-size: 26px;
            font-weight: 900;
            letter-spacing: -0.36px;
            line-height: 31px;
        }
        .main-text {	
            height: 39px;	
            width: 731.44px;	
            color: #212121;	
            font-size: 16px;	
            font-weight: 300;	
            line-height: 54px;
            margin-bottom:25px;
        }

        .details-section{
            display: flex;

            .horizontal{
                display: flex;
                flex-direction: column;

                //PHONE
                .contact-by-phone{
                    position: relative;
                    margin: 0 0 60px 0;

                    img {
                        position: absolute;
                        left: 0;
                        top: 54px;
                    }
                    .boxes {
                        .box-title {
                            height: 20px;	
                            width: 143px;	
                            color: #CC0099;		
                            font-size: 16px;	
                            font-weight: bold;	
                            line-height: 20px;
                        }
                        .text{
                            height: 20px;	
                            width: 184px;	
                            color: #212121;		
                            margin: 0 0 0 0;
                            font-size: 14px;	
                            font-style: italic;	
                            font-weight: 300;	
                            line-height: 20px;
                        }
                        .box {
                            margin: 14px 0 10px 30px;
                            line-height: 20px;
                            p {
                                font-weight: bold;
                                font-style: 20px;
                            }
                            span {
                                font-size: 16px;
                                padding-top: 3px;
                                display: block;
                                font-weight: 100;
                                a {
                                    display: inline-block;
                                    margin-left: 5px;
                                }
                            }
                        }
                    }

                }

                //MAIL
                .mail-information {

                    .box-title {
                        height: 20px;	
                        width: 143px;	
                        color: #CC0099;	
                        font-family: "J&J Circular";	
                        font-size: 16px;	
                        font-weight: bold;	
                        line-height: 20px;
                        margin: 0 0 10px 0;
                    }
                    img {
                        margin-right: 15px;
                    }
                    a {
                        height: 18px;	
                        width: 271px;	
                        color: #000099;	
                        font-family: "J&J Circular";	
                        font-size: 16px;	
                        font-weight: 300;	
                        line-height: 18px;
                    }
                }
            }

            .img-information{
            }
        }
    }

    .button-section{
        margin-top: 16px;

        .submit-button {
            background-color: #ca001b;
            height: 44px;	
            width: 175px;
            box-shadow: none;
            padding: 13px 30px;
            color: #fff;
            font-size: 15px;
            font-weight: bold;
            border: 0;
            float: right;
            cursor: pointer;
        }
    }
        
        
}

@media screen and (min-width: 1024px) {
    .option-screen-container {
        display: flex;
        flex-direction: column;
    
        .line{
            display: block;
            box-sizing: border-box;
            height: 2px;
            width: 100%;
            border: 1px solid #E3E3E3;
        }
    
        .title-section{
    
            .for-repatriation-ex{
                height: 83px;	
                width: 900px;	
                color: #212121;		
                font-size: 20px;	
                font-weight: 300;	
                line-height: 30px;
                margin-top: -18px;
            }
        }
        .contact-mobility-container {
            display: flex;
            flex-direction: column;
            margin: 50px 0 -50px 0;
    
            .label {
                height: 42px;
                width: 412px;
                color: #212121;
                font-size: 26px;
                font-weight: 900;
                letter-spacing: -0.36px;
                line-height: 31px;
            }
            .main-text {	
                height: 39px;	
                width: 731.44px;	
                color: #212121;	
                font-size: 16px;	
                font-weight: 300;	
                line-height: 48px;
                margin-bottom:25px;
            }
    
            .details-section{
                display: flex;
    
                .horizontal{
                    display: flex;
                    flex-direction: row;
    
                    //PHONE
                    .contact-by-phone{
                        position: relative;
                        img {
                            position: absolute;
                            left: 0;
                            top: 54px;
                        }
                        .boxes {
                            .box-title {
                                height: 20px;	
                                width: 143px;	
                                color: #CC0099;	
                                font-family: "J&J Circular";	
                                font-size: 16px;	
                                font-weight: bold;	
                                line-height: 20px;
                            }
                            .text{
                                height: 20px;	
                                width: 184px;	
                                color: #212121;	
                                font-family: "J&J Circular";	
                                margin: 0 0 0 0;
                                font-size: 14px;	
                                font-style: italic;	
                                font-weight: 300;	
                                line-height: 20px;
                            }
                            .box {
                                margin: 14px 0 10px 30px;
                                line-height: 22px;
                                p {
                                    font-weight: bold;
                                    font-style: 20px;
                                }
                                span {
                                    font-size: 16px;
                                    padding-top: 3px;
                                    display: block;
                                    font-weight: 100;
                                    a {
                                        display: inline-block;
                                        margin-left: 5px;
                                    }
                                }
                            }
                        }
    
                    }
    
                    //MAIL
                    .mail-information {
                        margin-left: 64px;
    
                        .box-title {
                            height: 20px;	
                            width: 143px;	
                            color: #CC0099;	
                            font-family: "J&J Circular";	
                            font-size: 16px;	
                            font-weight: bold;	
                            line-height: 20px;
                            margin: 0 0 10px 0;
                        }
                        img {
                            margin-right: 15px;
                        }
                        a {
                            height: 18px;	
                            width: 271px;	
                            color: #000099;	
                            font-family: "J&J Circular";	
                            font-size: 16px;	
                            font-weight: 300;	
                            line-height: 18px;
                        }
                    }
                }
    
                .img-information{
                    position: absolute;
                    left: 987px;
                    top: 493px;
                }
            }
        }
    
        .button-section{
            margin-top: 30px;
    
            .submit-button {
                background-color: #ca001b;
                height: 44px;	
                width: 175px;
                box-shadow: none;
                padding: 13px 30px;
                color: #fff;
                font-size: 15px;
                font-weight: bold;
                border: 0;
                float: right;
                cursor: pointer;
            }
        }
            
            
    }
}