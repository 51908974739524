@import '~main.scss';

.radio-button-root {
    .radio-button {
        width: 20px;
        height: 20px;

        svg {
            fill: $blueText;
        }
    }
}
