@import '~main.scss';

.pre-initiate-move-container {
    .people-leader-container {
        .global-vs-domestic {
            h2 {
                font-size: 24px;	
                font-weight: 900;	
                line-height: 32px;
            }
            p {
                color: #212121;
                font-size: 14px;
                font-weight: 300;
                line-height: 20px;
            }
        }
        .global-vs-domestic:first-child{
            .icon-block{
                img{
                    padding-bottom: 30px;
                }
            }
        }
    }
}
.InitiateMoveContainer{
    height: 100%;
    margin-top: -7px;
}

.PackageSeclectionContainer{
    margin: 85px 0 0 12%;
}

@media screen and (max-width: 375px) {
    .PackageSeclectionContainer{
        margin: 30px 0 0 10%;
    }
}

@media screen and (max-width: 1024px) {
    .PackageSeclectionContainer{
        margin: 30px 0 0 10%;
    }
}

.BackButtonSection {
    cursor: pointer;
    display: inline-flex;
    margin-bottom: 30px;
}

@media screen and (max-width: 375px) {
    .Component1Container{
        .QuestionSection {
            margin: 0 25px 40px 0;

            .question-text{
                font-size: 20px;
                font-weight: 1000;
                color: $blueText;
                line-height: 30px;
            }
        }
        .BackButtonSection{
            .backButtonClass{
                color: $black;
                margin-left: 10px;
            }
            .icon-block{
                margin: 0 12px 0 0;
                img{
                    width: 20px;
                }
            }
        }

        .two-cards{
            display: flex;
            flex-direction: column;
            height: auto;

            .SelectionCard{
                padding:41px 23px 47px 30px;
                margin: 0 30px 25px 0;
                max-width: 260px;
                height: 160px;
            }
            .selection-card-block {
                height: 50%;
                > h2 {
                    color: rgb(29, 29, 131);
                    margin-bottom: 0.5rem;
                    letter-spacing: -0.5px;
                    font-weight: bold;
                    font-size: 25px;
                    font-weight: 500;
                    line-height: 26px;
                }
                > p {
                    margin-bottom: 1.5rem;
                    color: #212121;
                    font-size: 14px;
                    font-weight: 100;
                    line-height: 23px;
                    word-spacing: 1px;
                    margin: 0px 15px 0px 0px;
                }
            }
        }

    }
}

@media screen and (min-width: 376px) {
    .Component1Container{
        .QuestionSection {
            margin: 0 25px 40px 0;

            .question-text{
                font-size: 20px;
                font-weight: 1000;
                color: $blueText;
                line-height: 30px;
            }
        }
        .BackButtonSection{
            .backButtonClass{
                color: $black;
                margin-left: 10px;
            }
            .icon-block{
                margin: 0 12px 0 0;
                img{
                    width: 20px;
                }
            }
        }

        .two-cards{
            display: flex;
            flex-direction: column;
            height: auto;

            .SelectionCard{
                padding:41px 23px 47px 30px;
                margin: 0 30px 25px 0;
                max-width: 260px;
                height: 160px;
            }
            .selection-card-block {
                height: 50%;
                > h2 {
                    color: rgb(29, 29, 131);
                    margin-bottom: 0.5rem;
                    letter-spacing: -0.5px;
                    font-weight: bold;
                    font-size: 25px;
                    font-weight: 500;
                    line-height: 26px;
                }
                > p {
                    margin-bottom: 1.5rem;
                    color: #212121;
                    font-size: 14px;
                    font-weight: 100;
                    line-height: 23px;
                    word-spacing: 1px;
                    margin: 0px 15px 0px 0px;
                }
            }
        }

    }
}

@media screen and (min-width: 768px) {
    .Component1Container{
        .QuestionSection {
            margin: 0 25px 40px 0;

            .question-text{
                font-size: 50px;
                font-weight: 1000;
                color: $blueText;
                line-height: 60px;
            }
        }
        .BackButtonSection{
            .backButtonClass{
                color: $black;
                margin-left: 10px;
            }
            .icon-block{
                margin: 0 12px 0 0;
                img{
                    width: 20px;
                }
            }
        }
        .two-cards{
            display: flex;
            height: auto;
            flex-direction: row;
            margin: 0 0 55px 0;

            .SelectionCard{
                padding:41px 23px 47px 30px;
                margin: 0 30px 25px 0;
                max-width: 260px;
                height: 160px;
            }
            .selection-card-block {
                height: 50%;
                > h2 {
                    color: rgb(29, 29, 131);
                    margin-bottom: 0.5rem;
                    letter-spacing: -0.5px;
                    font-weight: bold;
                    font-size: 25px;
                    font-weight: 500;
                    line-height: 26px;
                }
                > p {
                    margin-bottom: 1.5rem;
                    color: #212121;
                    font-size: 14px;
                    font-weight: 100;
                    line-height: 23px;
                    word-spacing: 1px;
                    margin: 0px 15px 0px 0px;
                }
            }
        }

    }
}

@media screen and (min-width: 1024px) {
    .Component1Container{
        width: 100%;

        .BackButtonSection{
            .backButtonClass{
                color: $black;
                margin-left: 10px;
            }
            .icon-block{
                margin: 0 12px 0 0;
                img{
                    width: 20px;
                }
            }
        }

        .QuestionSection {
            max-width: 860px;
            margin: 0 0 40px 0;
            .question-text{
                font-size: 43px;
                font-weight: 900;
                color: $blueText;
                line-height: 50px;
            }
        }
        .two-cards{
            display: flex;
            color: #000099;
            font-size: 24px;
            font-weight: 900;
            line-height: 32px;
            height: 246px;
            .SelectionCard{
                cursor: pointer;
                padding:41px 23px 47px 30px;
                margin: 0 30px 0 0;
                max-width: 260px;
                height: 160px;
                cursor: pointer;
                border: 2px solid rgb(230, 230, 230);
                &:hover {
                    border-color: #000099;
                }
            }
            .selection-card-block {
                height: 50%;
                > h2 {
                    color: rgb(29, 29, 131);
                    margin-bottom: 0.5rem;
                    letter-spacing: -0.5px;
                    font-weight: bold;
                    font-size: 25px;
                    font-weight: 500;
                    line-height: 26px;
                }
                > p {
                    margin-bottom: 1.5rem;
                    color: #212121;
                    font-size: 14px;
                    font-weight: 100;
                    line-height: 23px;
                    word-spacing: 1px;
                    margin: 0px 15px 0px 0px;
                }
            }
        }
    }
}

