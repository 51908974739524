@import "~main.scss";
$error-color: #f35351;

.CreateExpenseReport {
  padding: 72px 150px;
  flex-grow: 1;
  > h1 {
    color: $blueText;
    font-size: 44px;
    margin-bottom: 27px;
    > span {
      color: $blueText;
      font-size: $bigTitleSize;
      margin-bottom: 27px;
      text-transform: capitalize;
    }
  }
  > p {
    font-size: $subTitleSize;
    margin-bottom: 50px;
    line-height: 30px;
  }
  .line-item--table,.line-item--form{
    .Spinner{
      width: 20px;
      height: 20px;
      margin: 0;
    }
  }
  .create-expense-report-body {
        .body-content {
            label {
                color: $placeHolderColor;
            }
        }
    .Receipts {
      .validation-message {
        font-size: $smallSize;
        color: $buttonsColor;
        margin-top: -10px;
        font-weight: bold;
      }
      .value {
        display: inline-flex;
        .receiptName {
          cursor: pointer;
        }
        .file-name {
          padding: 8px 0;
          display: inline-flex;
          max-width: 200px;
          > a {
            color: $blueText;
            font-size: $mediumSize;
          }
          .download-link {
            color: $blueText;
            font-size: $mediumSize;
            cursor: pointer;
          }
          .Icon {
            margin-left: 10px;
            cursor: pointer;
          }
        }
      }
    }

    .body-content {
      margin-bottom: 65px;
      flex-flow: row wrap;

      .DropDown.incurred-currency {
        margin-top: 0;
        width: 200px;
        .label {
          max-width: 175px;
          font-weight: normal;
        }
      }

      .Input {
        max-width: 372px;
        width: 100%;
        margin-bottom: 20px;
      }
      > div:not(.Input) {
        min-width: 150px;
        margin-bottom: 20px;
        > span {
          font-size: 13px;
          color: $placeHolderColor;
          font-weight: 100;
        }
        > text {
          font-size: $largeSize;
        }
        &.status {
          text {
            color: $subtitleColor;
          }
        }
      }
    }
    > aside {
      margin-bottom: 50px;
      .validation-form {
        max-width: 800px;
        flex-grow: 1;
        align-self: center;
        overflow: hidden;
          .ValidatableForm_content {
              label {
                  color: $black;
              }
          }
        .spinner-wrapper {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          background: rgba(255, 255, 255, .4);
        }
        .start-period, .name {
          flex-basis: 50%;
          padding-right: 20px;
        }
        .end-period, .amount {
          flex-basis: 50%;
          padding-left: 20px;
        }
        .exchange-type {
          font-size: $largeSize;
          height: 68px;
        }
        .convertedRate {
          margin-top: 20px;
          font-size: 24px;
          cursor: pointer;
          .coversion-currency{
            margin-right: 5px;
          }
        }

        .ValidatableForm_footer {
          .submit {
            margin-left: 10px;
            outline: none;
          }
        }
      }
      .Table {
        margin-bottom: 20px;
        tr {
          td {
            &:first-child {
              div {
                color: $blueText;
              }
            }
            > div {
              color: $grey
            }
            .amount {
              font-size: $subTitleSize;
            }
          }
        }
      }
      .total {
        font-weight: 100;
        font-size: $smallSize;
        > b {
          font-size: $bigTitleSize;
          margin-left: 12px;
        }
      }
      .payments-details {
        padding: 35px;
        background-color: $mainBackgroundColor;

        .currency{
          border-top: 1px solid $darkGrey;
          margin-top:24px;
          padding-top: 5px;
          margin-right: 50px;
            label {
                color: $placeHolderColor;
            }
          &--item{
            margin: 20px;
            margin-left:0;
          }
          &--label{
            color: $placeHolderColor;
            font-size: 13px;
            font-weight: 300;
            letter-spacing: -0.24px;
            line-height: 26px;
          }
          &--value{
            color: $black;
            font-size: 18px;
            font-weight: 300;
            letter-spacing: -0.34px;
            line-height: 26px;
          }
        }

        &--inner-wrapper{
          flex-grow:2;
            .text {
                .select-field-body {
                    > p {
                        color: $placeHolderColor;
                    }
                }
            }
        }
        .Button {
          padding: .7rem 3.5rem;
        }
        .payments {
          margin-top: 20px;
          .payment-accounts-info {
            margin-top: 5px;
          }
          .text {
            margin-right: 30px;
            &:last-child {
              margin-right: 0;
            }
            > span {
              font-size: $smallSize;
              color: $placeHolderColor;
              margin-bottom: 5px;
            }
            font-size: $subTitleSize;
            font-weight: bold;
          }
        }
      }
      h3 {
        color: $blueText;
        font-size: $subTitleSize;
        margin-bottom: 15px;
        font-weight: bold;
      }
    }
  }
  .create-expense-report-footer {
    margin-top: 150px;
    .buttons-block {
      .Icon {
        margin-right: 10px;
      }
      > div {
        font-weight: 100;
        font-size: $mediumSize;
        color: $blueText;
        cursor: pointer;
        margin-right: 20px;
        padding-right: 20px;
        border-right: 1px solid $borderColor;
        &:first-child {
          > div {
            font-weight: 100;
            font-size: $mediumSize;
            color: $blueText;
            cursor: pointer;
            margin-right: 20px;
            padding-right: 20px;
            border-right: 1px solid $borderColor;
            &:last-child {
              border: none;
              margin-right: 0;
              padding-right: 0;
            }
          }

        }
        &:last-child {
          border: none;
          margin-right: 0;
          padding-right: 0;
        }
      }
    }
    .submitting {
      .expense-report--submit {
        background-color: $buttonsColor;
        margin-left: 16px;
      }
    }
  }
  .payment-details__spinner{
    display: inline-block;
    height: 5px;
    width: 5px;
    .Spinner{
      height: 5px;
      width: 5px;
      margin: -4px 0 0 5px;
    }
  }
}
.lineItems-mobile-data {
  display: none;
  padding-bottom: 25px;
  .lineItem-mobile-header {
    height: 70px;
    background-color: $mainBackgroundColor;
    box-sizing: border-box;
    padding: 0 10px;
  }
  .lineITem-mobile {
    height: 108px;
    padding: 16px 10px 26px 10px;
    box-sizing: border-box;
    border-bottom: 1px solid $borderColor;
    .name-date {
      max-width: calc(100% - 150px);
      span {
        font-size: 14px;
        font-weight: 100;
        &:first-child {
          color: $blueText;
        }
        &:last-child {
          color: $placeHolderColor;
        }
      }
    }
    .ammount {
      span {
        color: #63666A;
        font-size: 20px;
      }
    }
  }
}

.conversion_popup_body {
  padding: 25px;
  .exchange_rate_title, .exchange_date_title {
    font-size: $largeSize;
    margin-bottom: 5px;
  }
  .conversion_popup_footer {
    margin-top: 20px;
  }
  .exchange-type {
    font-size: $largeSize;
    margin-left: 10px;
  }
}

@media screen and (max-width: $breakPoint-lg){
  .CreateExpenseReport {
    padding: 60px;
    .create-expense-report-body {
      .body-content {
        .Input {
          max-width: inherit;
        }
      }
    }
    .lineItems-mobile-data {
      display: flex;
    }
    .Table {
      display: none;
    }
    .payments-details {
      flex-direction: column;
      .payments-details--inner-wrapper {
        width: 100%;
        .currency {
          margin-right: 0 !important;
        }
      }
    }
  }
}

@media screen and (max-width: $breakPoint-md){
  .CreateExpenseReport {
    padding: 25px;
    .header-text {
      font-size: 30px;
    }
    > p {
      font-size: 18px;
      margin-bottom: 25px;
    }
    .create-expense-report-body {
      .body-content {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 0;
        > div:first-child {
          width: 100%;
          margin-bottom: 30px;
        }
      }
      > aside {
        flex-direction: column;
        display: flex;
      }
    }
    .create-expense-report-footer {
      flex-flow: row wrap;
      justify-content: space-around;
      margin-top: 50px;
      .cancel {
        margin-bottom: 20px;
      }
    }
    .payments-details {
      .Button {
        width: 100%;
        box-sizing: border-box;
      }
      .payments-details--inner-wrapper {
        .payments {
          flex-direction: column;
          .text {
            margin-right: 0 !important;
            margin-bottom: 20px;
          }
        }
        .currency {
          border: none !important;
          margin-top: 0 !important;
          flex-direction: column;
        }
      }
    }
    .create-expense-report-footer {
      .buttons-block {
        margin-bottom: 20px;
        width: 100%;
        justify-content: space-between;
        > div {
          border: none;
          padding: 0;
          margin: 10px !important;
        }
      }
      .submitting {
        width: 100%;
        .Button {
          width: 100%;
          margin-left: 0;
        }
      }
    }
  }
}



.formElement_errorText {
  margin-top: 5px;
  font-size: $smallSize;
  color: $error-color;
}
.label-asterisk {
  margin: 2px;
  color: $error-color;
  font-weight: bold;
}

.form-level--error{
  text-align: right;
  margin-bottom: 5px;
}

.ie {
  .CreateExpenseReport{
    .validation-form {
      max-width: inherit !important;
    }
  }
}
.delete-popup.Popup{
  padding: 40px;
  .popup-header span.Icon{
    position: absolute;
    right: 15px;
    top: 15px;
  }
}
