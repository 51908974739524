@import "~main.scss";

$borderColor: #d8d8d8;
$employeeHeroTitleSize: 28px;

.EmployeeHero {
  position: relative;
  .image-block {
    width: 100%;
    height: 430px;
    background-position: 50% 25%;
    background-size: cover;
  }
  .hero-text-section{
    position: absolute;
    top: 0;
    height: 100%;
    max-width: 540px;
    padding: 0 80px;
    background: linear-gradient(to right, rgba(255,255,255, .9), rgba(255,255,255, .8), rgba(255,255,255, .6), transparent);

    .user-name {
      font-size: 20px;
      font-weight: bold;
      color: $subtitleColor;
      margin-bottom: 10px;
    }

    .hero-text {
      max-width: 540px;
      font-size: 44px;
      font-weight: 900;
      color: $blueText;
    }

    .Link {
      margin-top: 2.5rem;
      cursor: pointer;
      color: #212121;
      font-size: 18px;
    }
  }
  .hero-tabs {
    position: absolute;
    bottom: 0;
    left: calc(50% - 250px);
    font-weight: 900;
    .hero-tab {
      font-size: $largeSize;
      width: 250px;
      height: 70px;
      background-color: rgba(0, 0, 189, .9);
      color: white;
    }
    .active-hero-tab {
      background-color: white;
      color: $blueText;
    }
  }
}

@media screen and (max-width: $breakPoint-lg) {
  .EmployeeHero {
    width: 100%;
    .image-block {
      width: 100%;
      height: 250px;
      img { height: 100%;}
      padding-bottom: 20px;
    }
    .hero-text-section{
      height: 220px;
        padding: 25px;
        height: 100%;
      .hero-text {
        font-family: "J&J Circular";
        font-size: 30px;	font-weight: 900;
      }
    }
    .hero-tabs {
      left: auto;
      position: relative;
      display: flex;
      padding: 0 20px;
      flex-grow: 1;
      border-bottom: 1px solid $borderColor;
      .Tabs {
        flex-grow: 1;
        .hero-tab {
          flex-grow: 1;
          text-align: center;
          width: auto;
          border: 1px solid $borderColor;
        }
      }
    }
  }
}

@media screen and (max-width: $breakPoint-sm) {
    .EmployeeHero {
        .hero-text-section {
            .hero-text {
                font-size: $employeeHeroTitleSize;
            }
        }
    }
}
