@font-face {
    font-family: "J&J Circular";
    font-style: normal;
    font-weight: bold;
    src: url('./Fonts/CircularStd-Bold.woff') format('WOFF');
}

@font-face {
    font-family: "J&J Circular";
    font-style: italic;
    font-weight: bold;
    src: url('./Fonts/CircularStd-BoldItalic.woff') format('WOFF');
}

@font-face {
    font-family: "J&J Circular";
    font-style: normal;
    font-weight: 400;
    src: url('./Fonts/CircularStd-Medium.woff') format('WOFF');
}

@font-face {
    font-family: "J&J Circular";
    font-style: italic;
    font-weight: 400;
    src: url('./Fonts/CircularStd-MediumItalic.woff') format('WOFF');
}

@font-face {
    font-family: "J&J Circular";
    font-style: normal;
    font-weight: 900;
    src: url('./Fonts/CircularStd-Black.woff') format('WOFF');
}

@font-face {
    font-family: "J&J Circular";
    font-style: italic;
    font-weight: 900;
    src: url('./Fonts/CircularStd-BlackItalic.woff') format('WOFF');
}

@font-face {
    font-family: "J&J Circular";
    font-style: normal;
    font-weight: 100; //@todo replace to 300
    src: url('./Fonts/CircularStd-Book.woff') format('WOFF');
}

@font-face {
    font-family: "J&J Circular";
    font-style: italic;
    font-weight: 100; //@todo replace to 300
    src: url('./Fonts/CircularStd-BookItalic.woff') format('WOFF');
}

@font-face {
    font-family: "J&J Circular";
    font-style: normal;
    font-weight: 300; //@todo replace to 300
    src: url('./Fonts/CircularStd-Book.woff') format('WOFF');
}

@font-face {
    font-family: "J&J Circular";
    font-style: italic;
    font-weight: 300; //@todo replace to 300
    src: url('./Fonts/CircularStd-BookItalic.woff') format('WOFF');
}
