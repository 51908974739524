.eligible-container {
    .move-title {
        margin: 25px 0;
        p {
            font-size: 26px;
            font-weight: 900;
            color: #212121;
        }
        span {
            color: #cf009e;
            margin-left: 7px;
        }
    }
    .top-content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 50px;
        .col {
            display: flex;
            flex-direction: column;
            width: 25%;
            p {
                color: #63666a;
                font-size: 12px;
                font-weight: 300;
            }
            span {
                color: #212121;
                font-size: 16px;
                font-weight: 500;
                margin-top: 10px;
            }
        }
    }
    .middle {
        .middle-bottom-text {
            text-align: right;
            font-size: 14px;
            color: #63666a;
            margin-top: 23px;
            display: block;
        }
        .middle-top {
            .question-text {
                font-size: 26px;
                font-weight: 900;
                color: #212121;
                line-height: 42px;
            }
            p {
                font-weight: 300;
                line-height: 36px;
                font-size: 18px;
            }
        }
        .middle-table {
            .eligible-table {
                margin-top: 20px;
                .headers {
                    background-color: #000099;
                    th {
                        font-size: 18px;
                        color: #fff;
                        height: 70px;
                        padding-left: 30px;
                        font-weight: 900;
                        position: relative;
                        &:not(:first-child) {
                            &:before {
                                display: block;
                                content: "";
                                height: 45px;
                                top: 10px;
                                left: -7px;
                                width: 1px;
                                position: absolute;
                                background: #f4f4f4;
                            }
                        }
                    }
                }
                table {
                    tr {
                        &:nth-child(2n) {
                            background-color: #c8d7ff;
                            max-height: 80px !important;
                        }
                    }
                    td {
                        &:not(.headers) {
                            cursor: default;
                            padding-left: 30px;
                        }
                        font-weight: 300;
                        .firstElem {
                            font-size: 14px;
                            font-weight: 900;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .eligible-container {
        .move-title {
            margin: 15px 0;
            p {
                font-size: 18px;
                line-height: 23px;
                span {
                    display: block;
                    margin-left: 0px;
                }
            }
        }
        .top-content {
            display: inline-block;
            flex-direction: inherit;
            margin-bottom: 25px;
            .col {
                display: inline-block;
                width: 50%;
                float: left;
                margin-bottom: 16px;
            }
        }
        .middle {
            .middle-top {
                .question-text {
                    font-size: 20px;
                    font-weight: 900;
                    color: #212121;
                    line-height: 30px;
                }
                p {
                    font-weight: 300;
                    line-height: 24px;
                    font-size: 14px;
                    margin-bottom: 10px;
                }
            }
            .middle-table {
                border-bottom: 1px solid #d8d8d8;
            }
            .middle-bottom-text {
                text-align: right;
                font-size: 12px;
                color: #63666a;
                margin-top: 23px;
                display: block;
            }
        }
    }
}
