@import "~main.scss";

.TextWithImage {
  margin: 50px 80px;
  section {
    .image-block {
      width: 50%;
      height: 350px;
      background-position: 50% 25%;
      background-size: cover;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .text-block {
      width: 50%;
      padding-left: 50px;
      box-sizing: border-box;
      h2 {
        font-size: $bigTitleSize;
        color: $blueText;
        margin-bottom: 15px;
      }
      h4 {
        font-size: $mediumSize;
        color: $subtitleColor;
        margin-bottom: 18px;
      }
      p {
        font-size: $subTitleSize;
        color: $black;
        line-height: 30px;
      }
    }
  }
}

@media screen and (max-width: $breakPoint-lg) {
  .TextWithImage {
    margin: 50px 30px;
    section {
      flex-direction: column-reverse;
      .image-block {
        width: 100%;
      }
      .text-block {
        width: 100%;
        margin-bottom: 20px;
        padding-left: 0;
      }
    }
  }
}

//crosbrowser styles

.ie {
  #root {
    .TextWithImage {
      h2, h4, p {
        max-width: 100%;
      }
    }
  }
}
